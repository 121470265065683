import { combineReducers } from 'redux'

import fromUser, { user } from 'reducers/user'
import fromEvent, { event } from 'reducers/event'

import fromPage, { page } from 'reducers/page'
import fromHome, { home } from 'reducers/home'
import fromB2bOffers, { b2bOffers } from 'reducers/b2bOffers'
import fromProduct, { productDetails } from 'reducers/home/product'

import fromAccount, { account } from 'reducers/account'

import fromOrderRating, { orderRating } from 'reducers/orderRating'

export const makeRootReducer = () => {
  return combineReducers({
    user,
    page,
    home,
    b2bOffers,
    productDetails,
    account,
    orderRating,
    event
  })
}

// Global event selectors
export const getEventsAreLoading = state => fromEvent.getEventsAreLoading(state.event)
export const getActiveEvents = state => fromEvent.getActiveEvents(state.event)
export const getEventError = state => fromEvent.getEventError(state.event)
export const getSaleIsBlocked = state => fromEvent.getSaleIsBlocked(state.event)

// USER selectors
export const getAuthTokenUser = (state, ...args) => fromUser.getAuthTokenUser(state.user, ...args)
export const isUserSignedIn = (state, ...args) => fromUser.getIsLoggedIn(state.user, ...args)
export const getUserErrors = (state, ...args) => fromUser.getErrors(state.user, ...args)
export const getSecurityIsLoading = (state, ...args) => fromUser.getIsLoading(state.user, ...args)
export const getAmILoading = (state, ...args) => fromUser.getAmILoading(state.user, ...args)
export const getMe = (state, ...args) => fromUser.getMe(state.user, ...args)
export const getLostPasswordMessage = (state, ...args) => fromUser.getLostPasswordMessage(state.user, ...args)
export const getNewsletterSubscription = (state, ...args) => fromUser.getNewsletterSubscription(state.user, ...args)
export const getPendingOrder = (state, ...args) => fromUser.getPendingOrder(state.user, ...args)
export const getCurrentCredit = (state, ...args) => fromUser.getCurrentCredit(state.user, ...args)
export const getReferralCode = (state, ...args) => fromUser.getReferralCode(state.user, ...args)
export const getReferralProgram = (state, ...args) => fromUser.getReferralProgram(state.user, ...args)
export const getUserLastAddress = (state, ...args) => fromUser.getUserLastAddress(state.user, ...args)
export const getUserCompany = (state, ...args) => fromUser.getUserCompany(state.user, ...args)
export const getUserCompanyOffices = (state, ...args) => fromUser.getUserCompanyOffices(state.user, ...args)
export const getSoHappyUser = (state, ...args) => fromUser.getSoHappyUser(state.user, ...args)
export const getSoHappyAddresses = (state, ...args) => fromUser.getSoHappyAddresses(state.user, ...args)
export const isSoHappyNewUser = (state, ...args) => fromUser.isSoHappyNewUser(state.user, ...args)

// PAGE selectors
export const getCurrentHash = (state, ...args) => fromPage.getCurrentHash(state.page, ...args)
export const getHashHistory = (state, ...args) => fromPage.getHashHistory(state.page, ...args)
export const getSnackbarQueue = (state, ...args) => fromPage.getSnackbarQueue(state.page, ...args)
export const getGlobalError = (state, ...args) => fromPage.getGlobalError(state.page, ...args)
export const getDevice = (state, ...args) => fromPage.getDevice(state.page, ...args)
export const getQuerystringParams = (state, ...args) => fromPage.getQuerystringParams(state.page, ...args)
export const isInsideWebapp = (state, ...args) => fromPage.isInsideWebapp(state.page, ...args)
export const getWebappDevice = (state, ...args) => fromPage.getWebappDevice(state.page, ...args)
export const getCampaign = (state, ...args) => fromPage.getCampaign(state.page, ...args)
export const getCampaignId = (state, ...args) => fromPage.getCampaignId(state.page, ...args)
export const getIsPageLoading = (state, ...args) => fromPage.getIsPageLoading(state.page, ...args)

// HOME selectors
export const getDropOff = (state, ...args) => fromHome.getDropOff(state.home, ...args)

// CART selectors
export const isCartLoading = (state, ...args) => fromHome.isCartLoading(state.home, ...args)
export const getIsCartOutdated = (state, ...args) => fromHome.getIsCartOutdated(state.home, ...args)
export const getCartUUID = (state, ...args) => fromHome.getCartUUID(state.home, ...args)
export const getCartStatus = (state, ...args) => fromHome.getCartStatus(state.home, ...args)
export const getTotalCartQuantity = (state, ...args) => fromHome.getTotalCartQuantity(state.home, ...args)
export const getCartProducts = (state, ...args) => fromHome.getCartProducts(state.home, ...args)
export const getCartProductsWithoutCutlery = (state, ...args) => fromHome.getCartProductsWithoutCutlery(state.home, ...args)
export const getCartProduct = (state, ...args) => fromHome.getCartProduct(state.home, ...args)
export const getCartProductQuantity = (state, ...args) => fromHome.getCartProductQuantity(state.home, ...args)
export const getCartDelivery = (state, ...args) => fromHome.getCartDelivery(state.home, ...args)
export const getFormattedCartAddress = (state, ...args) => fromHome.getFormattedCartAddress(state.home, ...args)

// LAYOUT selectors
export const isLayoutLoading = (state, ...args) => fromHome.isLayoutLoading(state.home, ...args)
export const getLayoutHeaders = (state, ...args) => fromHome.getLayoutHeaders(state.home, ...args)

// DEMO selectors
export const isDemoModeEnabled = (state, ...args) => fromHome.isDemoModeEnabled(state.home, ...args)

// MENU selectors
export const getAllPublications = (state, ...args) => (
  fromHome.getAllPublications(state.home, ...args)
)
export const getPublicationById = (state, ...args) => (
  fromHome.getPublicationById(state.home, ...args)
)
export const getPublicationsBySectionId = (state, ...args) => (
  fromHome.getPublicationsBySectionId(state.home, ...args)
)
export const getAllSections = (state, ...args) => (
  fromHome.getAllSections(state.home, ...args)
)
export const getNonEmptySections = (state, ...args) => (
  fromHome.getNonEmptySections(state.home, ...args)
)
export const getSectionById = (state, ...args) => (
  fromHome.getSectionById(state.home, ...args)
)
export const getPublicationByProductId = (state, ...args) => (
  fromHome.getPublicationByProductId(state.home, ...args)
)
export const getAllFilters = (state, ...args) => (
  fromHome.getAllFilters(state.home, ...args)
)
export const hasAppliedFilters = (state, ...args) => (
  fromHome.hasAppliedFilters(state.home, ...args)
)

export const getAddressIsLoading = (state, ...args) => (
  fromHome.getAddressIsLoading(state.home, ...args)
)
export const getIsAddressTooltipDisplayed = (state, ...args) => {
  return fromHome.getIsAddressTooltipDisplayed(state.home, ...args)
}
export const getAddressSuggestions = (state, ...args) => (
  fromHome.getAddressSuggestions(state.home, ...args)
)
export const getAddressIsOutOfBound = (state, ...args) => (
  fromHome.getAddressIsOutOfBound(state.home, ...args)
)
export const getTimeslotIsLoading = (state, ...args) => (
  fromHome.getTimeslotIsLoading(state.home, ...args)
)
export const getIsTimeslotTooltipDisplayed = (state, ...args) => (
  fromHome.getIsTimeslotTooltipDisplayed(state.home, ...args)
)
export const getForcedMealSetting = (state, ...args) => (
  fromHome.getForcedMealSetting(state.home, ...args)
)
export const getAvailableDays = (state, ...args) => (
  fromHome.getAvailableDays(state.home, ...args)
)
export const getNextAvailableDay = (state, ...args) => (
  fromHome.getNextAvailableDay(state.home, ...args)
)
export const getAvailableShifts = (state, ...args) => (
  fromHome.getAvailableShifts(state.home, ...args)
)
export const getAvailableShiftsByActiveDay = (state, ...args) => (
  fromHome.getAvailableShiftsByActiveDay(state.home, ...args)
)
export const getSelectedDay = (state, ...args) => (
  fromHome.getSelectedDay(state.home, ...args)
)
export const getSelectedShiftId = (state, ...args) => (
  fromHome.getSelectedShiftId(state.home, ...args)
)
export const getShiftById = (state, ...args) => (
  fromHome.getShiftById(state.home, ...args)
)
export const getSelectedShift = (state, ...args) => (
  fromHome.getSelectedShift(state.home, ...args)
)
export const getSelectedTimeslot = (state, ...args) => (
  fromHome.getSelectedTimeslot(state.home, ...args)
)
export const getSelectedTimeslotId = (state, ...args) => (
  fromHome.getSelectedTimeslotId(state.home, ...args)
)
export const getIsUnavailableTimeslotModalDisplayed = (state, ...args) => (
  fromHome.getIsUnavailableTimeslotModalDisplayed(state.home, ...args)
)
export const getIsNoShiftTodayModalDisplayed = (state, ...args) => (
  fromHome.getIsNoShiftTodayModalDisplayed(state.home, ...args)
)
export const getCartCutlery = (state, ...args) => (
  fromHome.getCartCutlery(state.home, ...args)
)

// B2B OFFERS selectors
export const getB2bActiveOffer = (state, ...args) => (
  fromB2bOffers.getActiveB2bOffer(state.b2bOffers, ...args)
)
export const getB2bOffersOffice = (state, ...args) => (
  fromB2bOffers.getB2bOffersOffice(state.b2bOffers, ...args)
)
export const getB2bOffersDiscount = (state, ...args) => (
  fromB2bOffers.getB2bOffersDiscount(state.b2bOffers, ...args)
)
export const getB2bOffers = (state, ...args) => (
  fromB2bOffers.getB2bOffers(state.b2bOffers, ...args)
)
export const getAvailableCompanies = (state, ...args) => (
  fromB2bOffers.getAvailableCompanies(state.b2bOffers, ...args)
)

// PRODUCT selectors
export const getAllProductDetails = (state, ...args) => fromProduct.getAll(state.productDetails, ...args)
export const getProductDetailsById = (state, ...args) => fromProduct.getById(state.productDetails, ...args)
export const getProductDetailsIsLoading = (state, ...args) => fromProduct.getIsLoading(state.productDetails, ...args)
export const getProductHasError = (state, ...args) => fromProduct.getProductHasError(state.productDetails, ...args)

// CHECKOUT selectors
export const getMayPay = (state, ...args) => fromHome.getMayPay(state.home, ...args)
export const getCheckoutIsLoading = (state, ...args) => fromHome.getCheckoutIsLoading(state.home, ...args)
export const getUnavailableProducts = (state, ...args) => fromHome.getUnavailableProducts(state.home, ...args)
export const getCheckoutCouponIsLoading = (state, ...args) => fromHome.getCheckoutCouponIsLoading(state.home, ...args)
export const getCheckoutError = (state, ...args) => fromHome.getCheckoutError(state.home, ...args)
export const getCheckoutOrderHash = (state, ...args) => fromHome.getCheckoutOrderHash(state.home, ...args)
export const getPaymentAuthorizationUrl = (state, ...args) => fromHome.getPaymentAuthorizationUrl(state.home, ...args)
export const getCheckoutCompanySetting = (state, ...args) => fromHome.getCheckoutCompanySetting(state.home, ...args)
export const getCheckoutAmounts = (state, ...args) => fromHome.getCheckoutAmounts(state.home, ...args)
export const getCheckoutPaymentWaitingAuthorization = (state, ...args) => fromHome.getPaymentWaitingAuthorization(state.home, ...args)
export const getDeliveryFeeAmount = (state, ...args) => fromHome.getDeliveryFeeAmount(state.home, ...args)
export const getCustomerCreditAmount = (state, ...args) => fromHome.getCustomerCreditAmount(state.home, ...args)
export const getCouponAmount = (state, ...args) => fromHome.getCouponAmount(state.home, ...args)
export const getCompanyDiscountAmount = (state, ...args) => fromHome.getCompanyDiscountAmount(state.home, ...args)
export const getDiscountAmount = (state, ...args) => fromHome.getDiscountAmount(state.home, ...args)
export const getMealVoucherAmount = (state, ...args) => fromHome.getMealVoucherAmount(state.home, ...args)
export const getMealVoucherMaxAmount = (state, ...args) => fromHome.getMealVoucherMaxAmount(state.home, ...args)
export const getMealVoucherHasError = (state, ...args) => fromHome.getMealVoucherHasError(state.home, ...args)
export const getTotalAmount = (state, ...args) => fromHome.getTotalAmount(state.home, ...args)
export const getTotalDueAmount = (state, ...args) => fromHome.getTotalDueAmount(state.home, ...args)
export const getPayableAmount = (state, ...args) => fromHome.getPayableAmount(state.home, ...args)
export const getPayableAmountWithoutCompanyDiscount = (state, ...args) => fromHome.getPayableAmountWithoutCompanyDiscount(state.home, ...args)
export const getPayableAmountAfterMealVoucher = (state, ...args) => fromHome.getPayableAmountAfterMealVoucher(state.home, ...args)
export const getCheckoutPaymentMethods = (state, ...args) => fromHome.getCheckoutPaymentMethods(state.home, ...args)
export const getPaymentMethodDefaults = (state, ...args) => fromHome.getPaymentMethodDefaults(state.home, ...args)
export const getSelectedPaymentMethods = (state, ...args) => fromHome.getSelectedPaymentMethods(state.home, ...args)
export const getSelectedPaymentProvider = (state, ...args) => fromHome.getSelectedPaymentProvider(state.home, ...args)
export const getPaymentMethod = (state, ...args) => fromHome.getPaymentMethod(state.home, ...args)
export const getActiveCoupon = (state, ...args) => fromHome.getActiveCoupon(state.home, ...args)
export const getActiveCouponCode = (state, ...args) => fromHome.getActiveCouponCode(state.home, ...args)
export const getHasSodexo = (state, ...args) => fromHome.getHasSodexo(state.home, ...args)
export const getHasMonthlyBilling = (state, ...args) => fromHome.getHasMonthlyBilling(state.home, ...args)

// ----------------- //
// ---- ACCOUNT ---- //
// ----------------- //

// CREDIT selectors
export const getCredits = (state, ...args) => fromAccount.getCredits(state.account, ...args)
export const getCurrentCreditPage = (state, ...args) => fromAccount.getCurrentCreditPage(state.account, ...args)
export const getCreditPageCount = (state, ...args) => fromAccount.getCreditPageCount(state.account, ...args)
export const getCreditsByPage = (state, ...args) => fromAccount.getCreditsByPage(state.account, ...args)
export const getIsCreditsLoading = (state, ...args) => fromAccount.getIsCreditsLoading(state.account, ...args)

// ORDER selectors
export const getOrders = (state, ...args) => fromAccount.getOrders(state.account, ...args)
export const getCurrentOrderPage = (state, ...args) => fromAccount.getCurrentOrderPage(state.account, ...args)
export const getOrderPageCount = (state, ...args) => fromAccount.getOrderPageCount(state.account, ...args)
export const getOrdersByPage = (state, ...args) => fromAccount.getOrdersByPage(state.account, ...args)
export const getIsOrdersLoading = (state, ...args) => fromAccount.getIsOrdersLoading(state.account, ...args)

// LOYALTY selectors
export const getLoyaltyCoupons = (state, ...args) => fromAccount.getLoyaltyCoupons(state.account, ...args)
export const getLoyaltyLevel = (state, ...args) => fromAccount.getLoyaltyLevel(state.account, ...args)

// REFERRAL selectors
export const getReferralInvitation = (state, ...args) => fromAccount.getReferralInvitation(state.account, ...args)

// PAYMENT METHODS selectors
export const getProviderMethods = (state, ...args) => fromAccount.getProviderMethods(state.account, ...args)
export const hasPaymentMethod = (state, ...args) => fromAccount.hasPaymentMethod(state.account, ...args)
export const getPaymentLoading = (state, ...args) => fromAccount.getPaymentLoading(state.account, ...args)

// ---------------------- //
// ---- ORDER RATING ---- //
// ---------------------- //

export const getOrderRatingIsLoading = (state, ...args) => (
  fromOrderRating.getOrderRatingIsLoading(state.orderRating, ...args)
)
export const getOrderRatingError = (state, ...args) => (
  fromOrderRating.getOrderRatingError(state.orderRating, ...args)
)
export const getOrderRatingData = (state, ...args) => (
  fromOrderRating.getOrderRatingData(state.orderRating, ...args)
)

export default makeRootReducer
