import { combineReducers } from 'redux'

import {
  CLEAR_PAYMENT_AUTHORIZATION_URL_ACTION,
  SET_CHEKOUT_LOADING_ACTION,
  PAYMENT_PROCESSING_ACTION,
  CLEAR_CHECKOUT_ORDERHASH_ACTION,
  SET_CHECKOUT_ERROR_ACTION,
  PAYMENT_WAITING_ACTION, SET_UNAVAILABLE_PRODUCTS
} from 'actions/home/checkout'

import {
  UPDATE_CART_DISCOUNTS_ACTION,
  UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION,
  RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_DISCOUNTS_BLOCKING_ERROR_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
  UPDATE_CART_DISCOUNTS_SUCCESS_ACTION,
  CLEAR_CART_ACTION
} from 'actions/home/cart.actionTypes'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

import { bindSelectorsToState } from 'utils/data'

import fromAmounts, { amounts } from './amounts'
import fromPaymentMethods, { paymentMethods } from './paymentMethods'

export const couponIsLoading = (state = false, action) => {
  switch (action.type) {
    case UPDATE_CART_DISCOUNTS_ACTION:
      return true
    case UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DISCOUNTS_BLOCKING_ERROR_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      return false
    default:
      return state
  }
}

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case SET_CHEKOUT_LOADING_ACTION:
      return !!action.isLoading
    case CLEAR_CART_ACTION:
      return false
    default:
      return state
  }
}

export const error = (state = '', action) => {
  switch (action.type) {
    case SET_CHECKOUT_ERROR_ACTION:
      return action.checkoutError || ''
    default:
      return state
  }
}

export const orderHash = (state = '', action) => {
  switch (action.type) {
    case PAYMENT_PROCESSING_ACTION:
      return action?.hashOrder || ''
    case CLEAR_CHECKOUT_ORDERHASH_ACTION:
      return ''
    default:
      return state
  }
}

export const paymentAuthorizationUrl = (state = '', action) => {
  switch (action.type) {
    case PAYMENT_PROCESSING_ACTION:
      return action.redirectUrl || ''
    case CLEAR_PAYMENT_AUTHORIZATION_URL_ACTION:
    case CLEAR_CART_ACTION:
      return ''
    default:
      return state
  }
}

export const paymentWaitingAuthorization = (state = false, action) => {
  switch (action.type) {
    case PAYMENT_WAITING_ACTION:
      return action.data
    default:
      return state
  }
}

export const companySetting = (state = '', action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return typeof action?.cart?.companySetting === 'string'
        ? action?.cart?.companySetting
        : state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return ''
    default:
      return state
  }
}

export const unavailableProducts = (state = [], action) => {
  switch (action.type) {
    case SET_UNAVAILABLE_PRODUCTS:
      return action.products
    default:
      return state
  }
}

export const checkout = combineReducers({
  isLoading,
  unavailableProducts,
  couponIsLoading,
  error,
  orderHash,
  paymentAuthorizationUrl,
  paymentWaitingAuthorization,
  companySetting,
  amounts,
  paymentMethods
})

const getMayPay = (state, props) => {
  const payableAmount = fromAmounts.getPayableAmountAfterMealVoucher(state.amounts, props)
  const hasMealVoucherError = fromAmounts.getMealVoucherHasError(state.amounts, props)
  if (hasMealVoucherError) {
    return false
  }
  if (payableAmount <= 0) {
    return true
  }
  const paymentProvider = fromPaymentMethods.getSelectedPaymentProvider(state.paymentMethods, props)

  return !!paymentProvider
}

export default {
  getCheckoutIsLoading: state => state.isLoading,
  getUnavailableProducts: state => state.unavailableProducts,
  getCheckoutCouponIsLoading: state => state.couponIsLoading,
  getCheckoutError: state => state.error,
  getCheckoutOrderHash: state => state.orderHash,
  getPaymentAuthorizationUrl: state => state.paymentAuthorizationUrl,
  getCheckoutCompanySetting: state => state.companySetting,
  getCheckoutAmounts: state => state.amounts,
  getPaymentWaitingAuthorization: state => state.paymentWaitingAuthorization,
  getCheckoutPaymentMethods: state => state.paymentMethods,
  getMayPay,
  ...bindSelectorsToState(fromAmounts, 'amounts'),
  ...bindSelectorsToState(fromPaymentMethods, 'paymentMethods')
}
