import {
  RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
  UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_DISCOUNTS_SUCCESS_ACTION,
  CLEAR_CART_DISCOUNTS
} from 'actions/home/cart.actionTypes'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

export const coupon = (state = null, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      return action?.discount || null
    case CLEAR_CART_DISCOUNTS:
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return null
    default:
      return state
  }
}
