import numeral from 'numeral'

const defaultCurrency = '€'

if (numeral.locales.fr === undefined) {
  numeral.register('locale', 'fr', {
    delimiters: {
      thousands: ' ',
      decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    ordinal: number => number === 1 ? 'er' : 'e',
    currency: { symbol: defaultCurrency }
  })
}

numeral.locale('fr')

export const eur = (value, hasDecimalZeros = false) => (
  numeral(value).format(hasDecimalZeros ? '0,0.00 $' : '0,0[.]00 $')
)

export const amountDecimalParts = (value, hasDecimalZeros = false) => {
  const euroValue = formattedAmount({ value, hasDecimalZeros })
  return euroValue.split(',')
}

export const pureAmount = (value, hasDecimalZeros = false) => {
  return numeral(value).format(hasDecimalZeros ? '0,0.00' : '0,0[.]00')
}

export const formattedAmount = ({ value, withSpace = false, hasDecimalZeros = false, delimiter, currency }) => {
  let amount = pureAmount(value, hasDecimalZeros)
  const symbol = !currency ? defaultCurrency : currency
  if (delimiter) {
    amount = amount.replace(',', delimiter)
  }
  return withSpace ? amount + ' ' + symbol : amount + symbol
}

export const percentage = value => (
  numeral(value).format('0.0%').replace(',0', '')
)

export const time = value => {
  if (typeof value !== 'string') {
    return value
  }

  const parsedValue = value.match(/^(\d{2}):{1}(\d{2}):{1}/)

  if (!parsedValue || parsedValue.length < 3) {
    return value
  }

  return `${parsedValue[1]}h${parsedValue[2]}`
}

export const roundCurrency = amount => {
  if (!amount) {
    return 0
  }
  amount = parseFloat(amount)
  if (isNaN(amount)) {
    return 0
  }
  return Math.round(amount * 100) / 100
}
