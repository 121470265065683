import {
  GET_ORDER_TO_RATE_ACTION,
  SET_ORDER_TO_RATE_ACTION,
  RATE_ORDER_ACTION,
  ORDER_RATED_ACTION
} from 'actions/orderRating'

import { combineReducers } from 'redux'

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case GET_ORDER_TO_RATE_ACTION:
    case RATE_ORDER_ACTION:
      return true
    case SET_ORDER_TO_RATE_ACTION:
    case ORDER_RATED_ACTION:
      return false
    default:
      return state
  }
}

export const error = (state = null, action) => {
  switch (action.type) {
    case GET_ORDER_TO_RATE_ACTION:
    case RATE_ORDER_ACTION:
      return null
    case SET_ORDER_TO_RATE_ACTION:
    case ORDER_RATED_ACTION:
      return action.error || null
    default:
      return state
  }
}

export const order = (state = null, action) => {
  switch (action.type) {
    case GET_ORDER_TO_RATE_ACTION:
      return null
    case SET_ORDER_TO_RATE_ACTION:
    case ORDER_RATED_ACTION:
      return action.order || null
    default:
      return state
  }
}

export const orderRating = combineReducers({
  isLoading,
  error,
  order
})

export default {
  getOrderRatingIsLoading: (state, props) => (
    state.isLoading
  ),
  getOrderRatingError: (state, props) => (
    state.error
  ),
  getOrderRatingData: (state, props) => (
    state.order
  )
}
