import * as cookie from 'utils/cookies'
import qs from 'qs'

import URLS from 'api/url.json'

const defaultHeaders = {
  Accept: 'application/json, application/xml, text/plain, text/html, *.*',
  'Content-Type': 'application/json; charset=utf-8',
  'x-fc-api-key': process.env.FC_API_KEY
}

export const getRequestHeaders = (authenticated = true) => {
  const token = authenticated ? cookie.get(cookie.KEY.security) : null
  const headers = {
    ...defaultHeaders,
    'x-session-id': cookie.getSessionId(),
    'x-timestamp': Date.now(),
    'x-campaign-id': cookie.getCampaignIdFromCookie()
  }

  if (token) {
    headers.Authorization = 'Token ' + token
  }

  return headers
}

export const getRequestHeadersFromServer = (cookiesParams) => {
  const headers = {
    ...defaultHeaders,
    'x-timestamp': Date.now()
  }
  const token = cookiesParams[cookie.KEY.security] ? JSON.parse(cookiesParams[cookie.KEY.security]) : null
  const session = cookiesParams[cookie.KEY.session] || null
  const campaign = cookiesParams[cookie.KEY.campaign] || null
  if (token) headers.Authorization = 'Token ' + token
  if (session) headers['x-session-id'] = session
  if (campaign) headers['x-campaign-id'] = campaign

  return headers
}

export const getAbsoluteUrls = (host, urls) => {
  host = host || ''
  const absoluteUrls = {}
  Object
    .entries(urls)
    .forEach(([key, url]) => {
      if (url && typeof url === 'string') {
        url = `${url[0] === '/' ? '' : '/'}${url}`
        absoluteUrls[key] = `${host}${url}`
      } else if (typeof url === 'object' && !Array.isArray(url)) {
        absoluteUrls[key] = { ...getAbsoluteUrls(host, url) }
      }
    })

  return absoluteUrls
}

export const getUrlFromTemplate = (url = '', params = {}) => {
  Object
    .entries(params)
    .forEach(
      ([key, param]) => {
        url = url.replace(`{${key}}`, param || '')
      }
    )
  return url.replace((/\/$/), '')
}

export const absoluteApiUrls = getAbsoluteUrls(process.env.REACT_APP_API_HOST, URLS.api)

export const parseViolations = (violations) => violations.reduce(
  (errors, errorData) => {
    let { field, ...error } = errorData
    field = field.replace(/\[\d+\]$/, '')

    return {
      ...errors,
      [field]: error
    }
  },
  {}
)

export const handleError = (error, status) => {
  // Client-side form validation errors
  if (error && error.validationErrors) {
    return error.validationErrors
  }

  // Server-side form validation errors
  if (error && Array.isArray(error.violations)) {
    return parseViolations(error.violations)
  }

  // Server-side global error
  if (error && error.type) {
    return { global: error.type }
  }

  // Client-side generic errors
  if (error && error.message) {
    return { global: error.message }
  }

  // Allow sending hardcoded error strings
  if (error && typeof error === 'string') {
    return { global: error }
  }

  // Fallback to status code based errors
  switch (status) {
    case 404:
      return { global: 'not_found' }
    case 401:
      return { global: 'unauthorized_request' }
    case 403:
      return { global: 'forbidden_request' }
    case 400:
      return { global: 'bad_request' }
    case 500:
      return { global: 'server_error' }
    default:
      return { global: 'unknown_error' }
  }
}

export const getUrlWithQueryParams = (url = '', params = {}) => (
  `${
    url
  }${
    params && Object.keys(params).length > 0 ? '?' : ''
  }${
    qs.stringify(params)
  }`
)
