
import * as api from 'api/home/address'
import { v4 as uuidv4 } from 'uuid'

import * as deliveryActions from 'actions/home/delivery'

import { getAvailableShifts } from 'reducers'

import { get as getCookie, set as setCookie, KEY as cookieKeys } from 'utils/cookies'
import { getParamsFromURL } from 'utils/url'
import { getShiftFromMenuOrMeal, getFirstAvailableShift } from 'utils/delivery'
import { addressTypes } from 'utils/address'

import { DEFAULT_NB_AVAILABLE_DAYS } from 'config/delivery'

export const GET_ADDRESS_SUGGESTIONS_ACTION = 'get-address-suggestions'
export const GET_ADDRESS_SUGGESTIONS_RESULT_ACTION = 'get-address-suggestions-result'
export const SELECT_ADDRESS_ACTION = 'select-address'
export const DISPLAY_ADDRESS_TOOLTIP_ACTION = 'display-address-tooltip'
export const HIDE_ADDRESS_TOOLTIP_ACTION = 'hide-address-tooltip'
export const CLEAR_ADDRESS_SUGGESTIONS_ACTION = 'clear-address-suggestions'

const getAutoCompleteSessionToken = () => {
  let sessionToken = getCookie(cookieKeys.gpSession)
  if (!sessionToken) {
    sessionToken = setNewAutoCompleteSessionToken()
  }
  return sessionToken
}

// autocomplete cookie should only last no more than 3 minutes
// cf. https://developers.google.com/maps/documentation/places/android-sdk/usage-and-billing#ac-no-details-session
const setNewAutoCompleteSessionToken = () => {
  const sessionToken = uuidv4()
  setCookie(cookieKeys.gpSession, sessionToken, { expires: new Date(new Date().getTime() + 3 * 60 * 1000) })
  return sessionToken
}

export const getAddressSuggestions = query => {
  query = (query && query.trim()) || ''
  return async (dispatch, getState) => {
    // trigger ajax search
    dispatch({ type: GET_ADDRESS_SUGGESTIONS_ACTION })
    try {
      const autocompleteSessionToken = getAutoCompleteSessionToken()
      const suggestions = await api.searchAddress(query, autocompleteSessionToken)
      dispatch({
        type: GET_ADDRESS_SUGGESTIONS_RESULT_ACTION,
        suggestions: suggestions.data,
        error: suggestions.error
      })
    } catch (error) {
      dispatch({
        type: GET_ADDRESS_SUGGESTIONS_RESULT_ACTION,
        suggestions: [],
        error
      })
    }
  }
}

export const updateDeliveryFromAddress = address => async (dispatch, getState) => {
  const usedAddress = { ...address }
  if (address?.type === addressTypes.GOOGLE) {
    const autocompleteSessionToken = getAutoCompleteSessionToken()
    const { data } = await api.getAddressLocation(address, autocompleteSessionToken)
    setNewAutoCompleteSessionToken()
    if (data?.placeId === address.place_id) {
      usedAddress.latitude = data.latitude
      usedAddress.longitude = data.longitude
    }
  }
  await dispatch(deliveryActions.getSlots(usedAddress, DEFAULT_NB_AVAILABLE_DAYS))
  const availableShifts = getAvailableShifts(getState())
  const { menu, meal } = getParamsFromURL(['menu', 'meal'])
  const selectedShift = menu || meal
    ? getShiftFromMenuOrMeal(availableShifts, { menu, meal })
    : getFirstAvailableShift(availableShifts)
  if (selectedShift?.date) {
    dispatch(deliveryActions.updateDeliveryFromDay(selectedShift.date, usedAddress))
  }
}

export const clearAddressSuggestions = () => dispatch => {
  dispatch({ type: CLEAR_ADDRESS_SUGGESTIONS_ACTION })
}
