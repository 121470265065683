import { ENABLE_DEMO_MODE_ACTION, DISABLE_DEMO_MODE_ACTION } from 'actions/home/demo.actionTypes'

import { combineReducers } from 'redux'

export const isEnabled = (state = false, action) => {
  switch (action.type) {
    case ENABLE_DEMO_MODE_ACTION:
      return true
    case DISABLE_DEMO_MODE_ACTION:
      return false
    default:
      return state
  }
}

export const demo = combineReducers({
  isEnabled
})

const isDemoModeEnabled = state => state.isEnabled

const selectors = {
  isDemoModeEnabled
}

export default selectors
