export const MEAL_VOUCHER_ID = 'TR'
export const MEAL_VOUCHER_ONLY_ID = 'TRO'
export const COUPON_ID = 'CP'
export const CREDIT_CARD_ID = 'CB'
export const CUSTOMER_CREDIT_ID = 'CC'
export const BADGE_ID = 'SB'
export const LIMONETIK_ID = 'LK'
export const PAYPAL_ID = 'PP'
export const EDENRED_ID = 'ER'
export const GOOGLE_PAY_ID = 'GP'
export const APPLE_PAY_ID = 'AP'
export const MONTHLY_BILLING_ID = 'MB'
export const TRD_ID = 'TRD'
export const TRD_PASS_ID = 'TRD_PASS'
export const CBTRD_ID = 'CBTRD'
export const RESTOFLASH_ID = 'RESTOFLASH'
export const WEDOOFOOD_ID = 'WEDOOFOOD'
export const USER_CREDIT_ID = 'USER_CREDIT'
export const COMPANY_DISCOUNT_ID = 'COMPANY_DISCOUNT'

/**
 * EXCLUSIVE_METHODS - Payment methods that automatically exclude other payment methods, except Coupon and Customer credit
 */
export const EXCLUSIVE_METHODS = [
  LIMONETIK_ID,
  BADGE_ID,
  MEAL_VOUCHER_ONLY_ID,
  PAYPAL_ID,
  EDENRED_ID,
  GOOGLE_PAY_ID,
  APPLE_PAY_ID,
  MONTHLY_BILLING_ID,
  TRD_ID,
  TRD_PASS_ID,
  CBTRD_ID,
  RESTOFLASH_ID,
  WEDOOFOOD_ID
]

/**
 * TOTAL_METHODS - Payment methods that automatically exclude any other payment methods, including Coupon and Customer credit
 */
export const TOTAL_METHODS = [BADGE_ID]

export const PROVIDER_STRIPE = 'stripe'
export const PROVIDER_LIMONETIK = 'limonetik'
export const PROVIDER_BADGE = 'sodexo'
export const PROVIDER_PAYPAL = 'paypal'
export const PROVIDER_EDENRED = 'edenred'
export const PROVIDER_GOOGLE_PAY = 'googlepay'
export const PROVIDER_APPLE_PAY = 'applepay'
export const PROVIDER_MONTHLY_BILLING = 'monthlybilling'
export const PROVIDER_TRD = 'trd'
export const PROVIDER_CBTRD = 'cbtrd'
export const PROVIDER_RESTOFLASH = 'restoflash'
export const PROVIDER_WEDOOFOOD = 'wedoofood'
export const PROVIDER_PAYGREEN = 'paygreen'
export const PROVIDER_USER = 'user' // used while paying the whole price through the customer credit or a meal voucher

/**
 * PAYMENT_PROVIDERS - Definition of which payment provider handles which payment method
 */
export const PAYMENT_PROVIDERS = {
  default: PROVIDER_STRIPE,
  [BADGE_ID]: PROVIDER_BADGE,
  [CREDIT_CARD_ID]: PROVIDER_STRIPE,
  [LIMONETIK_ID]: PROVIDER_LIMONETIK,
  [PAYPAL_ID]: PROVIDER_PAYPAL,
  [EDENRED_ID]: PROVIDER_EDENRED,
  [GOOGLE_PAY_ID]: PROVIDER_GOOGLE_PAY,
  [APPLE_PAY_ID]: PROVIDER_APPLE_PAY,
  [MONTHLY_BILLING_ID]: PROVIDER_MONTHLY_BILLING,
  [TRD_ID]: PROVIDER_TRD,
  [TRD_PASS_ID]: PROVIDER_TRD,
  [CBTRD_ID]: PROVIDER_CBTRD,
  [RESTOFLASH_ID]: PROVIDER_RESTOFLASH,
  [WEDOOFOOD_ID]: PROVIDER_WEDOOFOOD,
  [MEAL_VOUCHER_ONLY_ID]: PROVIDER_USER,
  [USER_CREDIT_ID]: PROVIDER_USER,
  [COMPANY_DISCOUNT_ID]: PROVIDER_USER
}

export const PAYMENT_PROVIDERS_SUBMIT_PROPS = {
  [PROVIDER_LIMONETIK]: {
    label: 'Payer par Pass Restaurant Sodexo'
  },
  [PROVIDER_PAYPAL]: {
    alternativeButton: true
  },
  [PROVIDER_GOOGLE_PAY]: {
    alternativeButton: true
  }
}

export const PAYPAL_BUTTON_SETUP = {
  env: process.env.PAYPAL_ENV,
  style: {
    size: 'responsive',
    color: 'gold',
    shape: 'rect',
    tagline: false,
    label: 'paypal'
  }
}

export const PAYPAL_PAYMENT_SETUP = {
  intent: 'sale',
  flow: 'vault',
  currency: 'EUR',
  enableShippingAddress: false,
  billingAgreementDescription: ''
}

export const PAYPAL_BUTTON_CONTAINER_ID = 'paypal-button-container'
export const GOOGLE_PAY_BUTTON_CONTAINER_ID = 'googlepay-button-container'
export const PAY_BUTTON_CONTAINER_ID = 'pay-button-container'

export const PAYMENT_METHOD_AUTH_PROPS = {
  [PROVIDER_LIMONETIK]: {
    iframeProps: {
      id: 'LmkFrame',
      name: 'limonetik'
    },
    redirect: false
  },
  [PROVIDER_TRD]: {
    iframeProps: {
      id: 'TrdFrame',
      name: 'trd',
      style: {
        minHeight: 430,
        minWidth: 350
      }
    },
    redirect: false
  },
  [PROVIDER_RESTOFLASH]: {
    redirect: true
  },
  [PROVIDER_EDENRED]: {
    iframeProps: {
      id: 'EdenredFrame',
      name: 'edenred-connect'
    },
    redirect: false
  }
}

export const EDENRED_PAYMENT_LIMIT = 19
export const MAXIMUM_MEAL_VOUCHER_CREDIT = 19
