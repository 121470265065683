import moment from 'moment'

import * as api from 'api/home/cart'

import actionType from './cart.actionTypes'

import * as snackbarActions from 'actions/page/snackbar'
import { setGlobalErrorFromCode } from 'actions/page/globalError'
import { getProductPublications } from 'actions/home/publications'
import { SET_CHEKOUT_LOADING_ACTION } from 'actions/home/checkout'
import { getSlots, SHOW_UNAVAILABLE_TIMESLOT_MODAL } from 'actions/home/delivery'
import { DISPLAY_ADDRESS_TOOLTIP_ACTION, updateDeliveryFromAddress } from 'actions/home/address'
import { loadActiveEvents } from 'actions/event'

import * as cookie from 'utils/cookies'

import { CUTLERY_PUBLICATION_ID } from 'config/cart'
import {
  DEFAULT_ADDRESS, DEFAULT_NB_AVAILABLE_DAYS
} from 'config/delivery'

import {
  getSelectedTimeslotId,
  getCartUUID,
  getSelectedTimeslot,
  getSelectedShiftId,
  getShiftById,
  getDropOff,
  getUserLastAddress,
  getCartDelivery,
  getAvailableShifts,
  getFormattedCartAddress
} from 'reducers'

const MAX_CART_LIFETIME_SECONDS = 15 * 60

export const recoverCartFromCookie = () => async (dispatch, getState) => {
  const uuid = cookie.getCartUUID()
  if (!uuid) {
    return
  }

  dispatch({
    type: actionType.RELOAD_CART_ACTION
  })
  const { data, error } = await api.getCart(uuid)

  if (data?.cart?.updatedAt && moment(data?.cart?.updatedAt).unix() + MAX_CART_LIFETIME_SECONDS < moment().unix()) {
    dispatch({ type: actionType.SET_OUTDATED_CART_STATUS_ACTION })
    dispatch({
      type: actionType.RELOAD_CART_SUCCESS_ACTION,
      ...data
    })
  } else if (error) {
    dispatch({
      type: actionType.RELOAD_CART_BLOCKING_ERROR_ACTION,
      error
    })
    cookie.clearCartUUID()
    await dispatch(handleBlockingCartError(error))
  } else if (data?.status?.errors) {
    dispatch({
      type: actionType.RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
      ...data
    })
    await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
  } else {
    dispatch({
      type: actionType.RELOAD_CART_SUCCESS_ACTION,
      ...data
    })
  }
}

export const recoverCartFromCookieFromServer = (uuid, cookies) => async (dispatch, getState) => {
  if (!uuid) {
    return
  }
  dispatch({
    type: actionType.RELOAD_CART_ACTION
  })
  const { data, error } = await api.getCartFromServer(uuid, cookies, true)
  if (data?.cart?.updatedAt && !moment(data?.cart?.updatedAt).unix() + MAX_CART_LIFETIME_SECONDS < moment().unix() && !error && !data?.status?.errors) {
    dispatch({
      type: actionType.RELOAD_CART_SUCCESS_ACTION,
      ...data
    })
  }
}

export const reloadCart = ({ loadCartPayment = false, withPaymentMethods = false, onSuccess = null }) => async (dispatch, getState) => {
  if (loadCartPayment) {
    dispatch({
      type: SET_CHEKOUT_LOADING_ACTION,
      isLoading: true
    })
  }

  const uuid = cookie.getCartUUID()

  if (!uuid) {
    dispatch({
      type: SET_CHEKOUT_LOADING_ACTION,
      isLoading: false
    })
    return
  }

  dispatch({
    type: actionType.RELOAD_CART_ACTION
  })

  const { data, error } = await api.getCart(uuid, withPaymentMethods)
  if (error) {
    dispatch({
      type: actionType.RELOAD_CART_BLOCKING_ERROR_ACTION,
      error
    })
    cookie.clearCartUUID()
    await dispatch(handleBlockingCartError(error))
  } else if (data?.status?.errors) {
    dispatch({
      type: actionType.RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
      ...data
    })
    await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
  } else {
    dispatch({
      type: actionType.RELOAD_CART_SUCCESS_ACTION,
      ...data
    })
  }

  const timeslot = getSelectedTimeslot(getState())

  if (!timeslot || !timeslot.isOpen) {
    dispatch(initializeCart())
  }

  if (loadCartPayment) {
    dispatch({
      type: SET_CHEKOUT_LOADING_ACTION,
      isLoading: false
    })
    onSuccess && onSuccess()
  }
}

export const initializeCart = (idShiftTimeslot, idArea, selectedAddress) => async (dispatch, getState) => {
  cookie.clearCartUUID()

  const state = getState()
  idShiftTimeslot = idShiftTimeslot || getSelectedTimeslotId(state)
  idArea = idArea || getCartDelivery(state)?.idArea
  selectedAddress = selectedAddress || getFormattedCartAddress(state)
  const address = selectedAddress?.address
  const addressName = selectedAddress?.name
  const latitude = selectedAddress?.latitude
  const longitude = selectedAddress?.longitude
  const { dropOff = null, comment = null } = getDropOff(state) || {}

  if (!idShiftTimeslot || !idArea || !address || !latitude || !longitude) return

  await dispatch({
    type: actionType.INITIALIZE_CART_ACTION
  })

  const { data, error } = await api.initializeCart({
    idShiftTimeslot,
    idArea,
    address,
    latitude,
    longitude,
    dropOff,
    dropOffComment: comment,
    addressName
  })

  if (error) {
    dispatch({
      type: actionType.INITIALIZE_CART_BLOCKING_ERROR_ACTION,
      error
    })
    cookie.clearCartUUID()
    await dispatch(handleBlockingCartError(error))
  } else if (data?.status?.errors) {
    await dispatch({
      type: actionType.INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
      ...data
    })
    await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
  } else {
    await dispatch({
      type: actionType.INITIALIZE_CART_SUCCESS_ACTION,
      ...data
    })
  }

  data?.cart?.uuid && cookie.setCartUUID(data?.cart?.uuid)
}

export const updateCartDelivery = delivery => async (dispatch, getState) => {
  if (
    !delivery ||
    !delivery.idShiftTimeslot ||
    !delivery.idArea ||
    !delivery.address ||
    !delivery.latitude ||
    !delivery.longitude
  ) {
    return
  }

  const uuid = getCartUUID(getState())

  dispatch({
    type: actionType.UPDATE_CART_DELIVERY_ACTION
  })

  const { data, error } = await api.updateCartDelivery(uuid, delivery)
  if (error) {
    dispatch({
      type: actionType.UPDATE_CART_DELIVERY_BLOCKING_ERROR_ACTION,
      error
    })
    await dispatch(handleBlockingCartError(error))
  } else if (data?.status?.errors) {
    dispatch({
      type: actionType.UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION,
      ...data
    })
    await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
  } else {
    dispatch({
      type: actionType.UPDATE_CART_DELIVERY_SUCCESS_ACTION,
      ...data
    })
  }
}

export const updateCartProducts = (idProductPublication, quantity) => async (dispatch, getState) => {
  const cartUuid = getCartUUID(getState())
  if (!cartUuid) {
    dispatch({ type: DISPLAY_ADDRESS_TOOLTIP_ACTION })
  }
  if (!cartUuid || !idProductPublication || typeof quantity !== 'number') {
    return
  }

  await dispatch({
    type: actionType.UPDATE_CART_PRODUCTS_ACTION
  })

  const { data, error } = await api.updateCartProducts(cartUuid, idProductPublication, quantity)
  if (error) {
    dispatch({
      type: actionType.UPDATE_CART_PRODUCTS_BLOCKING_ERROR_ACTION,
      error
    })
    await dispatch(handleBlockingCartError(error))
  } else if (data?.status?.errors) {
    await dispatch({
      type: actionType.UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION,
      ...data
    })
    await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
  } else {
    dispatch({
      type: actionType.UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
      ...data
    })
  }
}

export const updateCartCutlery = quantity => dispatch => (
  dispatch(updateCartProducts(CUTLERY_PUBLICATION_ID, quantity))
)

export const dismissCutleryWarning = () => dispatch => {
  dispatch({
    type: actionType.DISSMISS_CUTLERY_WARNING_ACTION
  })
}

export const clearCart = (shouldReset = true) => dispatch => {
  dispatch({
    type: actionType.CLEAR_CART_ACTION
  })

  if (shouldReset === true) {
    dispatch(initializeCart())
  }
}

export const updateCartDiscounts = code => async (dispatch, getState) => {
  const uuid = getCartUUID(getState())

  dispatch({
    type: actionType.UPDATE_CART_DISCOUNTS_ACTION
  })

  const { data, error } = await api.updateCartDiscounts(uuid, code)
  if (error) {
    dispatch({
      type: actionType.UPDATE_CART_DISCOUNTS_BLOCKING_ERROR_ACTION,
      error
    })
    await dispatch(handleBlockingCartError(error))
  } else if (data?.status?.errors) {
    dispatch({
      type: actionType.UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION,
      ...data
    })
    await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
  } else {
    dispatch({
      type: actionType.UPDATE_CART_DISCOUNTS_SUCCESS_ACTION,
      ...data
    })
  }
}

export const clearCartDiscounts = () => dispatch => {
  dispatch(updateCartDiscounts(null))
}

const handleBlockingCartError = ({ data = {}, status = null }) => async (dispatch, getState) => {
  const address = getUserLastAddress(getState()) || DEFAULT_ADDRESS
  const shiftId = getSelectedShiftId(getState())
  const shift = getShiftById(getState(), { shiftId })
  const { type = null } = data || {}

  switch (type) {
    case 'cart_bad_customer':
      break
    case 'invalid_parameter':
    case 'shift_timeslot_bad_area':
    case 'area_not_found':
    case 'cart_not_found':
      dispatch(setGlobalErrorFromCode('popin.order.error.cart_generic'))
      break
    case 'invalid_slot':
      await dispatch(getSlots(address, DEFAULT_NB_AVAILABLE_DAYS))
      dispatch(setGlobalErrorFromCode('popin.order.error.timeslot_unavailable'))
      break
    case 'product_publication_not_found':
      dispatch(getProductPublications(shift))
      dispatch(setGlobalErrorFromCode('popin.order.error.product_unavailable'))
      break
    case 'shift_timeslot_unavailable': {
      const address = getFormattedCartAddress(getState())
      if (address) {
        await dispatch(getSlots(address, DEFAULT_NB_AVAILABLE_DAYS))
        const selectedShiftId = getSelectedShiftId(getState())
        const availableShifts = getAvailableShifts(getState())
        if (availableShifts.find(({ idShift, timeslots }) => idShift === selectedShiftId && timeslots.find(({ isOpen }) => isOpen === true))) {
          await dispatch({ type: SHOW_UNAVAILABLE_TIMESLOT_MODAL })
        } else {
          await dispatch(setGlobalErrorFromCode('popin.timeslot.error.unavailable_selected_shift'))
          await dispatch(updateDeliveryFromAddress(address))
        }
      }
      break
    }
    case 'shift_timeslot_not_found':
      break
    case 'invalid_discount_code':
      dispatch(snackbarActions.sendSnackbarNotification({
        message: 'Ce code n\'est pas valide',
        type: 'error'
      }))
      break
    case 'cart_invalid':
      break
    default:
      dispatch(snackbarActions.sendSnackbarNotification({
        message: 'Une erreur est survenue',
        type: 'error'
      }))
      break
  }
}

const handleNonBlockingCartErrors = (errors = [], isCartValid = true) => async (dispatch, getState) => {
  const shiftId = getSelectedShiftId(getState())
  const shift = getShiftById(getState(), { shiftId })

  for (const { code } of errors) {
    switch (code) {
      case 'incorrect_stock':
      case 'not_enough_stock':
      case 'publication_closed':
        if (!isCartValid) {
          await dispatch(initializeCart())
        }
        dispatch(getProductPublications(shift))
        break
      case 'publication_unavailable':
        if (!isCartValid) {
          await dispatch(initializeCart())
        }
        dispatch(getProductPublications(shift))
        dispatch(setGlobalErrorFromCode('popin.order.error.product_unavailable'))
        break
      case 'sales_blocked':
        dispatch(loadActiveEvents())
        break
      case 'invalid_shift_timeslot': {
        const address = getFormattedCartAddress(getState())
        if (address) {
          await dispatch(getSlots(address, DEFAULT_NB_AVAILABLE_DAYS))
          const cartDelivery = getCartDelivery(getState())
          const availableShifts = getAvailableShifts(getState())
          if (availableShifts.find(({ idShift }) => idShift === cartDelivery?.idShift)) {
            await dispatch({ type: SHOW_UNAVAILABLE_TIMESLOT_MODAL })
          } else {
            await dispatch(setGlobalErrorFromCode('popin.timeslot.error.unavailable_selected_shift'))
            await dispatch(updateDeliveryFromAddress(address))
          }
        }
        break
      }
      case 'code_invalid':
        break
      case 'invalid_quantity':
        break
      default:
    }
  }
}
