import * as orderRatingApi from 'api/orderRating'

export const GET_ORDER_TO_RATE_ACTION = 'get-order-to-rate'
export const SET_ORDER_TO_RATE_ACTION = 'set-order-to-rate'
export const RATE_ORDER_ACTION = 'rate-order'
export const ORDER_RATED_ACTION = 'order-rated'

export const getOrderToRate = hash => async dispatch => {
  dispatch({
    type: GET_ORDER_TO_RATE_ACTION
  })

  const result = {
    data: null,
    error: null
  }

  try {
    const response = await orderRatingApi.getOrderToRate(hash)
    if (response.error) {
      result.error = response.error
    } else {
      result.data = response.data
    }
  } catch (e) {
    result.error = e
  } finally {
    dispatch({
      type: SET_ORDER_TO_RATE_ACTION,
      order: result.data || null,
      error: result.error || null
    })
  }
}

export const rateOrder = (data, hash) => async dispatch => {
  dispatch({
    type: RATE_ORDER_ACTION
  })

  const result = {
    data: null,
    error: null
  }

  try {
    const response = await orderRatingApi.rateOrder(data, hash)
    if (response.error) {
      result.error = response.error
    } else {
      result.data = response
    }
  } catch (e) {
    result.error = e
  } finally {
    dispatch({
      type: ORDER_RATED_ACTION,
      order: result.data || null,
      error: result.error || null
    })
  }
}
