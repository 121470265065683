import { normalizeSections } from 'utils/publications'

import * as api from 'api/home/publications'

export const LOADING_PRODUCT_PUBLICATIONS_ACTION = 'loading-product-publications'
export const GET_PRODUCT_PUBLICATIONS_ACTION = 'get-product-publications'

export const getProductPublications = (selectedShift = {}) => {
  return async (dispatch) => {
    await dispatch({ type: LOADING_PRODUCT_PUBLICATIONS_ACTION })

    let sections = []
    let publications = []

    try {
      const { idArea, idShift } = selectedShift

      const response = await api.getProductPublications(idArea, idShift)
      if (Array.isArray(response.data)) {
        const data = normalizeSections(response.data)
        sections = data.sections
        publications = data.publications
      }

      await dispatch({
        type: GET_PRODUCT_PUBLICATIONS_ACTION,
        sections,
        publications,
        error: response.error
      })
    } catch (error) {
      await dispatch({
        type: GET_PRODUCT_PUBLICATIONS_ACTION,
        sections,
        publications,
        error
      })
    }
  }
}
