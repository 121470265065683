import { validate } from 'validate.js'
import { isValidPhoneNumber } from 'react-phone-number-input/mobile'

import signUpFormConfig from 'config/form/user/signUp'
import signInFormConfig from 'config/form/user/signIn'
import changePasswordFormConfig from 'config/form/user/changePassword'
import lostPasswordConfig from 'config/form/user/lostPassword'
import editUserFormConfig from 'config/form/user/editCustomer'
import newsletterSubscriptionFormConfig from 'config/form/user/newsletterSubscription'

const UNKNOWN_ERROR = 'unknown_error'

export const customValidators = {
  passwordSecurity: (value = '', { minimumCharacterTypes = 2, message = UNKNOWN_ERROR } = {}) => (
    (/[a-z]+/).test(value) + (/[A-Z]+/).test(value) + (/[0-9]+/).test(value) + (/[^a-zA-Z0-9]+/).test(value) < minimumCharacterTypes
      ? message
      : undefined
  ),
  mobilePhone: (value, { message = UNKNOWN_ERROR }) => value && isValidPhoneNumber(value) ? undefined : message
}

validate.validators = {
  ...validate.validators,
  ...customValidators
}

validate.formatters.cleanMessage = errors => {
  return errors
    .reverse()
    .reduce((obj, error) => ({
      ...obj,
      [error.attribute]: error && error.options && error.options.message
    }), {})
}

export const getErrorMessageFromConfig = formConfig => (field, errors) => {
  if (!formConfig || !errors || !field || !errors[field]) {
    return ''
  }

  const error = Array.isArray(errors[field])
    ? errors[field][0]
    : errors[field]

  const errorCode = error?.error || error

  let message = null

  if (formConfig.errors[field] && formConfig.errors[field][errorCode]) {
    // Get message specific to field AND error if defined in config.errors[field][errorCode]
    message = formConfig.errors[field][errorCode]
  } else if (formConfig.errors.generic && formConfig.errors.generic[errorCode]) {
    // Get default message specific to error ONLY if defined in config.errors.generic[errorCode]
    message = formConfig.errors.generic[errorCode]
  } else if (formConfig.errors[field] && formConfig.errors[field].default) {
    // Get default message specific to field ONLY if defined in config.errors[field].default
    message = formConfig.errors[field].default
  } else {
    // Get default error message if defined in config.errors.default
    message = formConfig.errors.default
  }

  if (typeof error === 'object' && Object.keys(error).length > 0) {
    Object.entries(error).forEach(
      ([k, v]) => { message = message.replace(`{${k}}`, v) }
    )
  }

  return message
}

export const validateSignUp = data => validate(data, signUpFormConfig.constraints, { format: 'cleanMessage' })
export const validateSignIn = data => validate(data, signInFormConfig.constraints, { format: 'cleanMessage' })
export const validateLostPassword = data => validate(data, lostPasswordConfig.constraints, { format: 'cleanMessage' })
export const validateChangePassword = data => validate(data, changePasswordFormConfig.constraints, { format: 'cleanMessage' })
export const validateEditUser = data => validate(data, editUserFormConfig.constraints, { format: 'cleanMessage' })
export const validateNewsletterSubscription = data => validate(data, newsletterSubscriptionFormConfig.constraints, { format: 'cleanMessage' })
