export const SET_DROPOFF_ACTION = 'set-drop-off'

export const setDropOff = (dropOff = {}, address = null) => {
  return dispatch => {
    // this is a temporary solution
    const localStoredDropoff = typeof window !== 'undefined' ? localStorage.getItem('userDefinedDropoff') : null
    const dropOffList = localStoredDropoff && Array.isArray(JSON.parse(localStoredDropoff)) ? JSON.parse(localStoredDropoff) : []

    const dropOffIndex = dropOffList.findIndex(dropOffItem => dropOffItem.address === address?.address)
    if (dropOffIndex >= 0) {
      dropOffList[dropOffIndex] = { ...dropOff, address: address.address }
    } else if (address) {
      dropOffList.push({ ...dropOff, address: address.address })
    }
    dropOffList.length && typeof window !== 'undefined' && localStorage.setItem('userDefinedDropoff', JSON.stringify(dropOffList))

    dispatch({
      type: SET_DROPOFF_ACTION,
      data: dropOff
    })
  }
}
