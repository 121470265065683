import { combineReducers } from 'redux'

import {
  B2B_OFFER_RESULT_ACTION,
  SET_SELECTED_B2B_OFFER_ACTION
} from 'actions/b2bOffers'
import { SELECTING_B2B_OFFERS_ACTION } from 'actions/b2b'

export const office = (state = null, action) => {
  const newState = state
  if (action.type === B2B_OFFER_RESULT_ACTION) {
    return action.data.office || []
  }
  return newState
}

export const activeOffer = (state = null, action) => {
  if (action.type === SET_SELECTED_B2B_OFFER_ACTION) {
    return action.offer || null
  }
  return state || null
}

export const offers = (state = null, action) => {
  if (action.type === SET_SELECTED_B2B_OFFER_ACTION) {
    return action.offer.offers || null
  }
  return state || null
}

export const discount = (state = 0, action) => {
  const newState = state
  if (action.type === SET_SELECTED_B2B_OFFER_ACTION) {
    return action.offer.discount || null
  }
  return newState
}

export const availableCompanies = (state = [], action) => {
  const newState = state
  if (action.type === SELECTING_B2B_OFFERS_ACTION) {
    return action.data.companies || null
  }
  return newState
}

export const b2bOffers = combineReducers({
  office,
  activeOffer,
  discount,
  offers,
  availableCompanies
})

export default {
  getB2bOffersOffice: (state, props) => (
    state.office
  ),
  getActiveB2bOffer: (state, props) => (
    state.activeOffer
  ),
  getB2bOffersDiscount: (state, props) => (
    state.discount
  ),
  getB2bOffers: (state, props) => (
    state.offers
  ),
  getAvailableCompanies: (state, props) => (
    state.availableCompanies
  )
}
