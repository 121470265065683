import {
  absoluteApiUrls,
  getRequestHeaders,
  handleError
} from 'utils/api'

import {
  DEFAULT_ADDRESS, DEFAULT_NB_AVAILABLE_DAYS
} from 'config/delivery'

export const getSlots = async (address = DEFAULT_ADDRESS, nbDays = DEFAULT_NB_AVAILABLE_DAYS) => {
  const result = {
    data: null,
    error: null
  }

  const method = 'POST'
  const url = absoluteApiUrls.delivery.getSlots
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    ...address,
    days: nbDays
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = Array.isArray(responseData) ? responseData : []
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
