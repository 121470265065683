import { combineReducers } from 'redux'

import {
  RELOAD_CART_ACTION,
  RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
  RELOAD_CART_BLOCKING_ERROR_ACTION,
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_ACTION,
  INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
  INITIALIZE_CART_BLOCKING_ERROR_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  UPDATE_CART_DELIVERY_ACTION,
  UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_DELIVERY_BLOCKING_ERROR_ACTION,
  UPDATE_CART_DELIVERY_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_ACTION,
  UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_BLOCKING_ERROR_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
  SET_OUTDATED_CART_STATUS_ACTION
} from 'actions/home/cart.actionTypes'

import { bindSelectorsToState } from 'utils/data'

import fromStatus, { status } from './status'
import fromDelivery, { delivery } from './delivery'
import fromProducts, { products } from './products'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

export const uuid = (state = null, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.cart?.uuid || state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return null
    default:
      return state
  }
}

export const totalQuantityWithoutCutlery = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.cart?.nbProduct || 0
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case RELOAD_CART_ACTION:
    case INITIALIZE_CART_ACTION:
    case UPDATE_CART_DELIVERY_ACTION:
    case UPDATE_CART_PRODUCTS_ACTION:
      return true
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_BLOCKING_ERROR_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_BLOCKING_ERROR_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DELIVERY_BLOCKING_ERROR_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_BLOCKING_ERROR_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return false
    default:
      return state
  }
}

export const isOutdated = (state = false, action) => {
  switch (action.type) {
    case SET_OUTDATED_CART_STATUS_ACTION:
      return true
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
      return false
    default:
      return state
  }
}

export const cart = combineReducers({
  uuid,
  totalQuantityWithoutCutlery,
  isLoading,
  isOutdated,
  status,
  delivery,
  products
})

const getCartUUID = state => state.uuid || null
const getTotalCartQuantity = (state, props) => state.totalQuantityWithoutCutlery
const isCartLoading = state => state.isLoading
const getIsCartOutdated = state => state.isOutdated
const getCartStatus = state => state.status

const selectors = {
  ...bindSelectorsToState(fromStatus, 'status'),
  ...bindSelectorsToState(fromDelivery, 'delivery'),
  ...bindSelectorsToState(fromProducts, 'products'),
  getCartUUID,
  getTotalCartQuantity,
  isCartLoading,
  getIsCartOutdated,
  getCartStatus
}

export default selectors
