import { combineReducers } from 'redux'

import {
  APPEND_PAYMENT_METHODS_ACTION,
  SELECT_PAYMENT_METHODS_ACTION
} from 'actions/home/checkout'

import {
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  UPDATE_CART_DELIVERY_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION
} from 'actions/home/cart.actionTypes'

import {
  MEAL_VOUCHER_ID,
  COUPON_ID,
  CREDIT_CARD_ID,
  CUSTOMER_CREDIT_ID,
  BADGE_ID,
  LIMONETIK_ID,
  PAYPAL_ID,
  EDENRED_ID,
  GOOGLE_PAY_ID,
  MONTHLY_BILLING_ID,
  TRD_ID,
  TRD_PASS_ID,
  CBTRD_ID,
  RESTOFLASH_ID,
  WEDOOFOOD_ID,
  APPLE_PAY_ID,
  PAYMENT_PROVIDERS
} from 'config/paymentMethods'

import { creditCard } from './creditCard'
import { sodexoBadge } from './sodexoBadge'
import { edenred } from './edenred'
import { trd } from './trd'
import { trdPass } from './trdPass'
import { cbtrd } from './cbtrd'
import { restoflash } from './restoflash'
import { wedoofood } from './wedoofood'
import { limonetik } from './limonetik'
import { monthlyBilling } from './monthlyBilling'
import { customerCredit } from './customerCredit'
import { mealVoucher } from './mealVoucher'
import { paypal } from './paypal'
import { googlepay } from './googlepay'
import { applePay } from './applePay'
import { coupon } from './coupon'

export const defaults = (state = [], action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return Array.isArray(action?.paymentMethods?.defaults) && action.paymentMethods.defaults.length > 0
        ? action.paymentMethods.defaults
        : state
    default:
      return state
  }
}

export const selected = (state = [], action) => {
  switch (action.type) {
    case SELECT_PAYMENT_METHODS_ACTION:
      return action.paymentMethods
    case INITIALIZE_CART_SUCCESS_ACTION:
      return []
    case APPEND_PAYMENT_METHODS_ACTION:
      return [...state, ...action.paymentMethods]
    default:
      return state
  }
}

export const paymentMethods = combineReducers({
  defaults,
  selected,
  [MEAL_VOUCHER_ID]: mealVoucher,
  [COUPON_ID]: coupon,
  [CREDIT_CARD_ID]: creditCard,
  [CUSTOMER_CREDIT_ID]: customerCredit,
  [BADGE_ID]: sodexoBadge,
  [LIMONETIK_ID]: limonetik,
  [PAYPAL_ID]: paypal,
  [EDENRED_ID]: edenred,
  [GOOGLE_PAY_ID]: googlepay,
  [APPLE_PAY_ID]: applePay,
  [MONTHLY_BILLING_ID]: monthlyBilling,
  [TRD_ID]: trd,
  [TRD_PASS_ID]: trdPass,
  [CBTRD_ID]: cbtrd,
  [RESTOFLASH_ID]: restoflash,
  [WEDOOFOOD_ID]: wedoofood
})

export const getSelectedPaymentProvider = (state) => {
  if (
    !Array.isArray(state.selected) || state.selected.length === 0
  ) {
    return null
  }
  const paymentMethodWithProvider = state.selected
    .find(paymentMethod => !!PAYMENT_PROVIDERS[paymentMethod])

  return paymentMethodWithProvider
    ? PAYMENT_PROVIDERS[paymentMethodWithProvider]
    : null
}
const getPaymentMethodDefaults = state => state.defaults
const getSelectedPaymentMethods = state => state.selected
const getPaymentMethod = (state, props) => props.paymentMethodId && state[props.paymentMethodId]
const getActiveCoupon = (state, props) => state[COUPON_ID]
const getActiveCouponCode = (state, props) => state[COUPON_ID]?.code
const getHasSodexo = (state, props) => state.selected.includes(BADGE_ID)
const getHasMonthlyBilling = (state, props) => state.selected.includes(MONTHLY_BILLING_ID)

const selectors = {
  getPaymentMethodDefaults,
  getSelectedPaymentProvider,
  getSelectedPaymentMethods,
  getPaymentMethod,
  getActiveCoupon,
  getActiveCouponCode,
  getHasSodexo,
  getHasMonthlyBilling
}

export default selectors
