import {
  RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_DELIVERY_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION
} from 'actions/home/cart.actionTypes'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

const initialState = {
  idShift: null,
  idShiftTimeslot: null,
  idArea: null,
  address: null,
  latitude: null,
  longitude: null,
  defaultDropOff: null,
  defaultDropOffComment: null
}

export const delivery = (state = initialState, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
      return action?.cart?.delivery || null
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return null
    default:
      return state
  }
}

const getCartDelivery = state => state
const getFormattedCartAddress = state => {
  const { address, addressName, latitude, longitude, isCorner } = state || {}
  return { address, name: addressName, latitude, longitude, isCorner }
}
const selectors = {
  getCartDelivery,
  getFormattedCartAddress
}
export default selectors
