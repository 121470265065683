import { combineReducers } from 'redux'
import {
  RELOAD_CART_ACTION,
  RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
  RELOAD_CART_BLOCKING_ERROR_ACTION,
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_ACTION,
  INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
  INITIALIZE_CART_BLOCKING_ERROR_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  UPDATE_CART_DELIVERY_ACTION,
  UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_DELIVERY_BLOCKING_ERROR_ACTION,
  UPDATE_CART_DELIVERY_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_ACTION,
  UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_BLOCKING_ERROR_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
  UPDATE_CART_DISCOUNTS_ACTION,
  UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_DISCOUNTS_BLOCKING_ERROR_ACTION,
  UPDATE_CART_DISCOUNTS_SUCCESS_ACTION
} from 'actions/home/cart.actionTypes'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

export const isValid = (state = false, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
      return action?.status?.valid ?? state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return false
    default:
      return state
  }
}

const defaultNonBlockingErrorsState = []
export const nonBlockingErrors = (state = defaultNonBlockingErrorsState, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
      return action?.status?.errors || defaultNonBlockingErrorsState
    case RELOAD_CART_ACTION:
    case INITIALIZE_CART_ACTION:
    case UPDATE_CART_DELIVERY_ACTION:
    case UPDATE_CART_PRODUCTS_ACTION:
      return defaultNonBlockingErrorsState
    default:
      return state
  }
}

export const blockingError = (state = null, action) => {
  switch (action.type) {
    case RELOAD_CART_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DELIVERY_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DISCOUNTS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
    case UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION:
      return null
    case RELOAD_CART_BLOCKING_ERROR_ACTION:
    case INITIALIZE_CART_BLOCKING_ERROR_ACTION:
    case UPDATE_CART_DELIVERY_BLOCKING_ERROR_ACTION:
    case UPDATE_CART_PRODUCTS_BLOCKING_ERROR_ACTION:
    case UPDATE_CART_DISCOUNTS_BLOCKING_ERROR_ACTION:
      return action.error
    default:
      return state
  }
}

export const status = combineReducers({
  isValid,
  nonBlockingErrors,
  blockingError
})

const isCartValid = state => state.isValid
const getNonBlockingCartErrors = state => state.nonBlockingErrors
const getBlockingCartError = state => state.blockingError
const selectors = {
  isCartValid,
  getNonBlockingCartErrors,
  getBlockingCartError
}

export default selectors
