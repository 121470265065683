import { combineReducers } from 'redux'

import fromPublications, { publications } from './publications'
import fromSections, { sections } from './sections'
import fromFilters, { filters } from './filters'

import { applyFilters } from 'utils/dataFilter'
import { bindSelectorsToState } from 'utils/data'

export const menu = combineReducers({
  publications,
  sections,
  filters
})

export const getPublicationsBySectionId = (state, props) => {
  const section = fromSections.getSectionById(state.sections, props)
  const publications = section?.publications?.map(publicationId => fromPublications.getPublicationById(state.publications, { publicationId })) || []
  const filters = fromFilters.getFilterExpressions(state.filters, props)

  if (filters.length === 0 || publications.length === 0) {
    return publications
  }

  return applyFilters(publications, filters)
}
export const getNonEmptySections = (state, props) => {
  const sections = fromSections.getAllSections(state.sections, props)
  const filters = fromFilters.getFilterExpressions(state.filters, props)

  if (filters.length === 0) {
    return sections
  }

  return sections.filter(section => {
    if (section.type === 'MARKETING') {
      return false
    }

    const publications = getPublicationsBySectionId(state, { sectionId: section.id })

    if (publications.length > 0) {
      return true
    }

    return false
  })
}

export default {
  ...bindSelectorsToState(fromPublications, 'publications'),
  ...bindSelectorsToState(fromSections, 'sections'),
  ...bindSelectorsToState(fromFilters, 'filters'),
  getPublicationsBySectionId,
  getNonEmptySections
}
