export const getParamsFromURL = (keys = []) => {
  if (typeof window === 'undefined' || !Array.isArray(keys) || keys.length === 0) {
    return {}
  }

  const urlParams = new URLSearchParams(document.location.search)

  return keys.reduce((result, key) => {
    result[key] = urlParams.has(key) ? urlParams.get(key) : null
    return result
  }, {})
}

export const CAMPAIGN_URL_PARAM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign'
]
export const getCampaignURLParams = () => {
  const params = getParamsFromURL(CAMPAIGN_URL_PARAM_KEYS)
  return Object
    .entries(params)
    .reduce((result, [key, value]) => {
      if (!value) {
        return result
      }
      result = result || {}
      result[key] = value
      return result
    }, null)
}

/**
 * Returns parse url
 * @param {String} url
 * @returns {Object} { baseUrl, params }
 */
export const parseUrl = (url) => {
  // don't use URLSearchParams here because it isn't supported by IE
  // cf. https://caniuse.com/?search=URLSearchParams
  if (!url || typeof url !== 'string') return {}

  const [baseUrl, search] = url.split('?')
  const variables = search.split('&')
  const params = {}
  for (const variable of variables) {
    const [parameter, value] = variable.split('=')
    if (parameter === '' || value === '') continue
    else {
      params[parameter] = value
    }
  }
  return {
    baseUrl,
    params
  }
}
