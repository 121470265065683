import * as api from 'api/account/order'
export const SET_ORDERS_ACTION = 'set-orders'
export const START_ORDERS_LOADING_ACTION = 'start-orders-loading'
export const END_ORDERS_LOADING_ACTION = 'end-orders-loading'

/**
 * Retrieve orders
 * @returns [Array]
 */
export const getOrders = async (userId, page) => {
  /*
    Result should be formatted like this
      {
        data: [...]
      }
  */
  try {
    const response = await api.getOrderHistory(userId, page)
    return response
  } catch (error) {
    return null
  }
}

/**
 *  Load data to show on page load
 */
export const loadOrders = (userId, page) => {
  return async dispatch => {
    dispatch({
      type: START_ORDERS_LOADING_ACTION
    })
    const orderHistory = await getOrders(userId, page)
    dispatch({
      type: SET_ORDERS_ACTION,
      orders: orderHistory.data,
      currentPage: orderHistory.currentPage,
      pageCount: orderHistory.pageCount,
      error: orderHistory.error
    })
    dispatch({
      type: END_ORDERS_LOADING_ACTION
    })
  }
}
