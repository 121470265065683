import { combineReducers } from 'redux'

import { normalizeArray } from 'utils/data'

import {
  SET_CREDITS_ACTION, START_CREDITS_LOADING_ACTION, END_CREDITS_LOADING_ACTION
} from 'actions/account/credits'

export const credits = (state = null, action) => {
  if (action.type === SET_CREDITS_ACTION) {
    return Array.isArray(action.credits)
      ? normalizeArray(action.credits, 'id')
      : null
  }
  return state
}

export const currentPage = (state = 0, action) => {
  if (action.type === SET_CREDITS_ACTION) {
    return action.currentPage || 0
  }
  return state
}

export const pageCount = (state = 0, action) => {
  if (action.type === SET_CREDITS_ACTION) {
    return action.pageCount || 0
  }
  return state
}

export const page = (state = {}, action) => {
  if (action.type === SET_CREDITS_ACTION) {
    return typeof action.currentPage === 'number' && Array.isArray(action.credits)
      ? {
        ...state,
        ['page' + action.currentPage]: action.credits.map(o => o.id)
      }
      : state
  }
  return state
}

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case START_CREDITS_LOADING_ACTION:
      return true
    case END_CREDITS_LOADING_ACTION:
      return false
    default:
      return state
  }
}

export const credit = combineReducers({
  credits,
  currentPage,
  pageCount,
  page,
  isLoading
})

export default {
  getCredits: state => {
    if (state.credits === null) {
      return null
    }

    return Object.values(state.credits)
  },
  getCurrentCreditPage: state => state.currentPage,
  getCreditPageCount: state => state.pageCount,
  getCreditsByPage: (state, props) => {
    let result = null
    if (state.credits === null) {
      result = null
    } else if (!state.page['page' + state.currentPage]) {
      result = []
    } else {
      result = state.page['page' + state.currentPage].map(id => state.credits[id]).filter(Boolean)
    }

    return result
  },
  getIsCreditsLoading: state => state.isLoading
}
