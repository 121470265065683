import { v4 as uuidv4 } from 'uuid'
import Cookie from 'js-cookie'
import moment from 'moment'

export const COOKIE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const COOKIE_LOC_RESET_DATE = moment(
  '2018-11-22 17:00:00',
  COOKIE_DATE_FORMAT
)

export const KEY = {
  security: '-O-O-',
  address: 'LOC',
  campaign: 'CC',
  session: 'NNFCSID',
  cart: 'NNFCCUUID',
  gpSession: 'GPADSS',
  soHappyData: 'so_happy_data',
  webApp: 'webapp'
}

export const cookieAttributes = {
  expires: 730,
  secure: process.env.REACT_APP_ENV !== 'dev'
}

export const campaignCookieAttributes = {
  ...cookieAttributes,
  expires: 7
}

export const sessionCookieAttributes = {
  secure: process.env.REACT_APP_ENV !== 'dev'
}

/**
   * @function get - Retrieve data from a cookie
   * @param { string } key - The key of the cookie
   * @param { boolean=false } burnAfterReading - Whether to remove the cookie after reading is value
   * @returns { object|null } - The parsed cookie data or null if no data
   */
export const get = (key, burnAfterReading = false) => {
  const cookieData = Cookie.get(key)
  burnAfterReading === true && remove(key)
  return cookieData ? JSON.parse(cookieData) : null
}

export const getString = (key, burnAfterReading = false) => {
  const cookieData = Cookie.get(key)
  if (burnAfterReading) Cookie.remove(key)
  return cookieData
}

/**
   * @function set - Store data inside a cookie
   * @param { string } key - The key of the cookie
   * @param { string|object } value - The content to store in the cookie
   * @param {object} options - js-cookie options ({ expires, path })
   * @returns { function } - A function to remove the cookie
   */
export const set = (key, value, options) => {
  value = JSON.stringify(value)
  const attributes = options ? { ...cookieAttributes, ...options } : cookieAttributes

  Cookie.set(key, value, attributes)

  return () => remove(key, attributes)
}

export const setSessionCookie = (key, value) => {
  Cookie.set(key, value, sessionCookieAttributes)
}

/**
   * @function remove - Remove a cookie
   * @param { string } key - The key of the cookie to be removed
   * @returns { string } key - The key of the removed cookie
   */
export const remove = (key) => {
  Cookie.remove(key, cookieAttributes)

  return key
}

export const getSessionId = () => {
  let sessionId = Cookie.get(KEY.session)

  if (!sessionId) {
    sessionId = uuidv4()
    Cookie.set(
      KEY.session,
      sessionId,
      { secure: process.env.REACT_APP_ENV !== 'dev' }
    )
  }

  return sessionId
}

export const getCampaignFromCookie = () => {
  return get(KEY.campaign)
}

export const getCampaignIdFromCookie = () => {
  const campaign = getCampaignFromCookie()
  return campaign?.id || null
}

export const setCampaignCookie = (campaign) => {
  if (!campaign?.id) {
    return
  }
  Cookie.remove(KEY.campaign, campaignCookieAttributes)
  const value = JSON.stringify(campaign)
  Cookie.set(KEY.campaign, value, campaignCookieAttributes)
  return () => remove(KEY.campaign, campaignCookieAttributes)
}

export const getCartUUID = () => {
  const { uuid } = get(KEY.cart) || {}
  return uuid
}
export const setCartUUID = uuid => {
  set(KEY.cart, { uuid })
}
export const clearCartUUID = () => {
  remove(KEY.cart)
}
