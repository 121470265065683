import * as api from 'api/account/credit'

export const SET_CREDITS_ACTION = 'set-credits'
export const START_CREDITS_LOADING_ACTION = 'start-credits-loading'
export const END_CREDITS_LOADING_ACTION = 'end-credits-loading'

/**
 * Retrieve credits
 * @returns [Array]
 */
export const getCredits = async (userId, page) => {
  try {
    const response = await api.getCreditHistory(userId, page)

    return response
  } catch (error) {
    return {
      data: []
    }
  }
}

/**
 *  Load data to show on page load
 */
export const loadCredits = (userId, page) => {
  return async (dispatch, getState) => {
    dispatch({ type: START_CREDITS_LOADING_ACTION })
    const creditHistory = await getCredits(userId, page)

    dispatch({
      type: SET_CREDITS_ACTION,
      credits: creditHistory.data,
      currentPage: creditHistory.currentPage,
      pageCount: creditHistory.pageCount,
      error: creditHistory.error
    })
    dispatch({ type: END_CREDITS_LOADING_ACTION })
  }
}
