import { LOAD_LOCAL_STORAGE_ACTION } from 'actions/page/localStorage'

export const localStorageReducer = (
  reducer,
  key,
  serialize = JSON.stringify,
  deserialize = JSON.parse,
  storage
) => {
  if (typeof window === 'undefined') {
    return reducer
  }

  storage = typeof storage === 'undefined' ? window.localStorage : storage

  if (typeof storage !== 'object' || storage === null) {
    return reducer
  }

  return (state, action) => {
    const newState = reducer(state, action)

    try {
      if (action.type === LOAD_LOCAL_STORAGE_ACTION) {
        const stored = storage.getItem(key)
        return typeof stored === 'string'
          ? deserialize(stored)
          : reducer(undefined, {})
      }

      key &&
        typeof state !== 'undefined' &&
        state !== newState &&
        storage.setItem(key, serialize(newState))

      return newState
    } catch (e) {
      return newState
    }
  }
}
