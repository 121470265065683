import {
  absoluteApiUrls,
  handleError,
  getRequestHeaders,
  getUrlFromTemplate
} from 'utils/api'
import {
  validateSignUp,
  validateSignIn,
  validateLostPassword,
  validateChangePassword,
  validateEditUser,
  validateNewsletterSubscription
} from 'utils/formValidation/user'
import { formatCommunicationPreferencesForApi } from 'utils/communications'

export const signUp = async (data) => {
  const {
    email,
    firstName,
    lastName,
    phone,
    password,
    subscribedNewsletter
  } = data

  const result = {
    input: data,
    data: null,
    error: null
  }

  // Validate user input before making the actual request
  const validationErrors = validateSignUp(data)
  if (validationErrors) {
    result.error = handleError({ validationErrors })
    return result
  }

  const method = 'POST'
  const url = absoluteApiUrls.user.signUp
  const headers = getRequestHeaders(false)
  const body = JSON.stringify({
    firstName,
    lastName,
    email,
    phone,
    password,
    subscription: {
      newsletter: subscribedNewsletter
    }
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const signIn = async (data) => {
  const {
    email,
    password
  } = data

  const result = {
    input: data,
    data: null,
    error: null
  }

  // Validate user input before making the actual request
  const validationErrors = validateSignIn(data)
  if (validationErrors) {
    result.error = handleError({ validationErrors })
    return result
  }

  const method = 'POST'
  const url = absoluteApiUrls.user.signIn
  const headers = getRequestHeaders(false)
  const body = JSON.stringify({
    email,
    password
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getMe = async () => {
  const result = {
    data: null,
    error: null
  }

  const method = 'GET'
  const url = absoluteApiUrls.user.getMe
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const signInWithAuthToken = async (userAuthToken) => {
  const result = {
    data: null,
    error: null,
    token: null
  }

  if (!userAuthToken) {
    return result
  }

  let method = 'POST'
  let url = absoluteApiUrls.user.signInWithAuthToken
  let headers = getRequestHeaders(true)
  const body = JSON.stringify({
    userAuthToken
  })

  try {
    // Sign in with auth token if one is present
    let response = await fetch(url, { method, headers, body })
    let responseData = await response.json()

    if (!response.ok || !responseData.token) {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
      return result
    }

    // If sign-in succeeds, try to retrieve the new user data
    const { token } = responseData
    method = 'GET'
    url = absoluteApiUrls.user.getMe
    headers = getRequestHeaders(false)
    headers.Authorization = 'Token ' + token

    response = await fetch(url, { method, headers })
    responseData = await response.json()

    if (!response.ok) {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
      return result
    }

    result.token = token
    result.data = responseData
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getConnectedUser = async (userAuthToken) => {
  const connectedUser = await getMe()
  if (!userAuthToken && connectedUser?.data) {
    return connectedUser
  }
  const authTokenUser = await signInWithAuthToken(userAuthToken)

  return authTokenUser?.data ? authTokenUser : connectedUser
}

export const lostPassword = async (email) => {
  const result = {
    input: { email },
    success: false,
    error: null
  }

  // Validate user input before making the actual request
  const validationErrors = validateLostPassword(result.input)
  if (validationErrors) {
    result.error = handleError({ validationErrors })
    return result
  }

  const method = 'POST'
  const url = absoluteApiUrls.user.lostPassword
  const headers = getRequestHeaders(false)
  const body = JSON.stringify(result.input)
  try {
    const response = await fetch(url, { method, headers, body })
    if (response.ok) {
      result.success = true
    } else {
      const responseData = await response.json()
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const changePassword = async (urlToken, newPassword, passwordRepeat, subscribeToNewsletter = false) => {
  const result = {
    data: null,
    error: null
  }

  // Validate user input before making the actual request
  const validationErrors = validateChangePassword({
    password: newPassword,
    confirmPassword: passwordRepeat
  })
  if (validationErrors) {
    result.error = handleError({ validationErrors })
    return result
  }

  if (newPassword !== passwordRepeat) {
    result.error = {
      global: 'mismatching_passwords'
    }
    return result
  }

  const [id, token] = urlToken.split('-')
  const method = 'POST'
  const url = absoluteApiUrls.user.changePassword
  const headers = getRequestHeaders(false)
  const body = JSON.stringify({
    id,
    token,
    newPassword
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const updateUser = (data) => new Promise((resolve, reject) => {
  const result = { data, errors: null }
  const error = { message: 'Veuillez remplir tous les champs' }

  const validationErrors = validateEditUser(data)
  if (validationErrors) {
    error.errors = handleError({ validationErrors })
  }

  if (!validationErrors) {
    setTimeout(
      () => resolve(
        result
      ),
      500
    )
  } else {
    setTimeout(
      () => reject(
        error
      ),
      500
    )
  }
})

export const subscribeToNewsletter = async (email, idCampaign) => {
  const result = {
    success: false,
    error: null
  }

  // Validate user input before making the actual request
  const validationErrors = validateNewsletterSubscription({ email, idCampaign })
  if (validationErrors) {
    result.error = handleError({ validationErrors })
    return result
  }

  const method = 'POST'
  const url = absoluteApiUrls.user.subscribeToNewsletter
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    email,
    idCampaign
  })

  try {
    const response = await fetch(url, { method, headers, body })
    if (response.ok) {
      result.success = true
    } else {
      const responseData = await response.json()
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getNextDeliveryOrder = async (customerId) => {
  const result = {
    data: null,
    error: null
  }

  if (!customerId) {
    return result
  }

  const method = 'GET'
  const url = getUrlFromTemplate(absoluteApiUrls.user.getNextDeliveryOrder, { customerId })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData || null
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const sodexoAuth = async (data) => {
  const result = {
    input: data,
    data: null,
    error: null
  }

  if (!data) {
    return result
  }

  const method = 'POST'
  const url = absoluteApiUrls.user.sodexoAuth
  const headers = getRequestHeaders(false)
  const body = JSON.stringify({
    data
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const setUserCompany = async (customerId, companyId) => {
  const result = {
    data: null,
    error: null
  }

  const method = 'PUT'
  const url = getUrlFromTemplate(absoluteApiUrls.user.setUserCompany, { customerId })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    idCompany: companyId
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || response.data,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const updateUserDetails = async (customerId, details) => {
  const result = {
    data: null,
    error: null
  }

  if (!details || typeof details !== 'object') {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.user.updateUserDetails, { customerId })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    firstName: details?.firstName,
    lastName: details?.lastName
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || response.data,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const updateCommunicationPreferences = async (customerId, preferences) => {
  const result = {
    data: null,
    error: null
  }

  if (!preferences || typeof preferences !== 'object') {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.user.updateCommunicationPreferences, { customerId })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify(formatCommunicationPreferencesForApi(preferences))

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || response.data,
        response.status
      )
    }
  } catch (e) {
    console.log(e.message)
    result.error = handleError(e)
  }

  return result
}
