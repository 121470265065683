import {
  absoluteApiUrls,
  handleError,
  getRequestHeaders,
  getUrlFromTemplate
} from 'utils/api'

export const getProductDetails = async (productId) => {
  const result = {
    data: null,
    error: null
  }

  if (!productId) {
    return result
  }

  const method = 'GET'
  const url = getUrlFromTemplate(absoluteApiUrls.products.getProduct, { productId })
  const headers = getRequestHeaders(false)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData || null
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
