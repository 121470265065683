import add from 'add'
import { combineReducers } from 'redux'

import {
  MAXIMUM_MEAL_VOUCHER_CREDIT
} from 'config/paymentMethods'

import {
  SET_MEAL_VOUCHER_AMOUNT_ACTION
} from 'actions/home/checkout'
import {
  RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
  UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_DISCOUNTS_SUCCESS_ACTION,
  CLEAR_CART_DISCOUNTS
} from 'actions/home/cart.actionTypes'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

import { roundCurrency } from 'utils/numeral'
import { getProductsOfferAmount } from 'utils/products'

export const deliveryFee = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      return typeof action?.cart?.deliveryFee === 'number'
        ? action?.cart?.deliveryFee
        : state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}

export const customerCredit = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      return typeof action?.cart?.customerCredit === 'number'
        ? action?.cart?.customerCredit
        : state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}

export const coupon = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      if (action?.discount) {
        return action.discount.discountAmount
      } else return 0
    case CLEAR_CART_DISCOUNTS:
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}

export const discount = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      if (action?.cart?.products) {
        return getProductsOfferAmount(action.cart.products)
      }
      return state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}

export const companyDiscount = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      return typeof action?.cart?.companyDiscount === 'number'
        ? action?.cart?.companyDiscount
        : state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}

export const mealVoucher = (state = 0, action) => {
  if (action.type === SET_MEAL_VOUCHER_AMOUNT_ACTION) {
    return typeof action.amount === 'number'
      ? action.amount
      : state
  }
  if (action.type === OUT_OF_BOUND_ADDRESS_ACTION) {
    return 0
  }
  return state
}

export const total = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      return typeof action?.cart?.totalAmount === 'number'
        ? action?.cart?.totalAmount
        : state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}
export const totalDue = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
      return typeof action?.cart?.totalDue === 'number'
        ? action?.cart?.totalDue
        : state
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}

export const amounts = combineReducers({
  deliveryFee,
  customerCredit,
  coupon,
  discount,
  companyDiscount,
  mealVoucher,
  total,
  totalDue
})

const getPayableAmount = (state, props, withoutCredit = true) => {
  let {
    totalDue = 0,
    coupon = 0,
    customerCredit = 0
  } = state

  totalDue = parseFloat(totalDue, 10)
  totalDue = !isNaN(totalDue) && totalDue >= 0 ? totalDue : 0

  coupon = parseFloat(coupon, 10)
  coupon = !isNaN(coupon)
    ? 0 - Math.abs(parseFloat(coupon, 10))
    : 0

  customerCredit = parseFloat(customerCredit)
  customerCredit = withoutCredit || isNaN(customerCredit)
    ? 0
    : Math.abs(customerCredit)

  const total = add([totalDue, coupon, customerCredit])

  return roundCurrency(total)
}

const getPayableAmountWithoutCompanyDiscount = (state, props, withoutCredit = true) => {
  const payableAmount = getPayableAmount(state, props, withoutCredit)

  let { companyDiscount = 0 } = state
  companyDiscount = parseFloat(companyDiscount)
  companyDiscount = !isNaN(companyDiscount) ? companyDiscount : 0

  const total = add([payableAmount, companyDiscount])

  return roundCurrency(total)
}

const getPayableAmountAfterMealVoucher = (state, props, withoutCredit = true) => {
  const payableAmount = getPayableAmount(state, props, withoutCredit)

  let { mealVoucher = 0 } = state
  mealVoucher = parseFloat(mealVoucher)
  mealVoucher = !isNaN(mealVoucher)
    ? 0 - Math.abs(mealVoucher)
    : 0

  const total = add([payableAmount, mealVoucher])

  return roundCurrency(total)
}

export default {
  getDeliveryFeeAmount: state => state.deliveryFee,
  getCustomerCreditAmount: state => state.customerCredit,
  getCouponAmount: state => state.coupon,
  getCompanyDiscountAmount: state => state.companyDiscount,
  getDiscountAmount: state => state.discount,
  getMealVoucherAmount: state => state.mealVoucher,
  getMealVoucherMaxAmount: state => getPayableAmount(state) + MAXIMUM_MEAL_VOUCHER_CREDIT,
  getMealVoucherHasError: state => state.mealVoucher > (getPayableAmount(state) + MAXIMUM_MEAL_VOUCHER_CREDIT),
  getTotalAmount: state => state.total,
  getTotalDueAmount: state => state.totalDue,
  getPayableAmount,
  getPayableAmountWithoutCompanyDiscount,
  getPayableAmountAfterMealVoucher
}
