import moment from 'moment'
import { combineReducers } from 'redux'

import {
  LOADING_SHIFTS_ACTION,
  SHIFT_ERROR_ACTION,
  SHIFTS_LOADED_ACTION,
  SELECT_DAY_ACTION,
  FORCE_MEAL_SETTING_ACTION,
  SELECT_TIMESLOT_ACTION,
  SHOW_UNAVAILABLE_TIMESLOT_MODAL,
  HIDE_UNAVAILABLE_TIMESLOT_MODAL,
  SHOW_NO_SHIFT_TODAY_MODAL,
  HIDE_NO_SHIFT_TODAY_MODAL
} from 'actions/home/delivery'

import {
  normalizeArray
} from 'utils/data'

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case LOADING_SHIFTS_ACTION:
      return true
    case SHIFT_ERROR_ACTION:
    case SHIFTS_LOADED_ACTION:
      return false
    default:
      return state
  }
}

export const forcedMealSetting = (state = null, action) => {
  if (action.type === FORCE_MEAL_SETTING_ACTION) {
    return action.meal || null
  }
  return state
}

export const availableDays = (state = [], action) => {
  const days = []
  switch (action.type) {
    case SHIFTS_LOADED_ACTION:
      if (!Array.isArray(action.shifts)) {
        return []
      }
      action.shifts.forEach(shift => {
        const day = moment(shift.date).format('YYYY-MM-DD')
        !days.includes(day) && days.push(day)
      })
      return days.sort()
    default:
      return state
  }
}

export const availableShifts = (state = [], action) => {
  switch (action.type) {
    case SHIFTS_LOADED_ACTION:
      return Array.isArray(action.shifts)
        ? normalizeArray(action.shifts, 'idShift')
        : []
    default:
      return state
  }
}

export const selectedDay = (state = null, action) => {
  switch (action.type) {
    case SELECT_DAY_ACTION:
      return action.date
        ? moment(action.date).format('YYYY-MM-DD')
        : null
    default:
      return state
  }
}

export const selectedShiftId = (state = null, action) => {
  switch (action.type) {
    case SELECT_TIMESLOT_ACTION:
      return action.shiftId
    default:
      return state
  }
}

export const selectedTimeslotId = (state = null, action) => {
  switch (action.type) {
    case SELECT_TIMESLOT_ACTION:
      return action.timeslotId
    default:
      return state
  }
}

export const isUnavailableTimeslotModalDisplayed = (state = false, action) => {
  switch (action.type) {
    case SHOW_UNAVAILABLE_TIMESLOT_MODAL:
      return true
    case HIDE_UNAVAILABLE_TIMESLOT_MODAL:
      return false
    default:
      return state
  }
}

export const isNoShiftTodayModalDisplayed = (state = false, action) => {
  switch (action.type) {
    case SHOW_NO_SHIFT_TODAY_MODAL:
      return true
    case HIDE_NO_SHIFT_TODAY_MODAL:
      return false
    default:
      return state
  }
}

export const slots = combineReducers({
  isLoading,
  forcedMealSetting,
  availableDays,
  availableShifts,
  selectedDay,
  selectedShiftId,
  selectedTimeslotId,
  isUnavailableTimeslotModalDisplayed,
  isNoShiftTodayModalDisplayed
})

export const getTimeslotIsLoading = (state, props) => (
  state.isLoading
)

export const getIsTimeslotTooltipDisplayed = (state, props) => false

export const getForcedMealSetting = (state, props) => (
  state.forcedMealSetting
)

export const getAvailableDays = (state, props) => (
  state.availableDays
)

export const getNextAvailableDay = (state, props) => (
  state.availableDays[0] || null
)

export const getAvailableShifts = (state, props) => (
  Object.values(state.availableShifts)
)

export const getSelectedDay = (state, props) => (
  state.selectedDay
)

export const getShiftById = (state, props) => (
  (props.shiftId && state.availableShifts[props.shiftId]) || null
)

export const getSelectedShiftId = (state, props) => (
  state.selectedShiftId
)

export const getSelectedTimeslotId = (state, props) => (
  state.selectedTimeslotId
)

export const getAvailableShiftsByActiveDay = (state, props) => (
  Object
    .values(state.availableShifts)
    .filter(shift => (
      moment(shift.date).format('YYYY-MM-DD') === state.selectedDay
    ))
)
export const getSelectedShift = state => (
  getShiftById(state, { shiftId: state.selectedShiftId })
)
export const getSelectedTimeslot = (state, props) => {
  const shift = getSelectedShift(state, props)

  if (!shift || !Array.isArray(shift.timeslots)) {
    return null
  }

  return shift.timeslots
    .find(timeslot => timeslot.idShiftTimeslot === state.selectedTimeslotId) || null
}

export const getIsUnavailableTimeslotModalDisplayed = (state, props) => (
  state.isUnavailableTimeslotModalDisplayed
)

export const getIsNoShiftTodayModalDisplayed = (state, props) => (
  state.isNoShiftTodayModalDisplayed
)

export default {
  getTimeslotIsLoading,
  getIsTimeslotTooltipDisplayed,
  getForcedMealSetting,
  getAvailableDays,
  getNextAvailableDay,
  getAvailableShifts,
  getAvailableShiftsByActiveDay,
  getSelectedDay,
  getShiftById,
  getSelectedShiftId,
  getSelectedTimeslotId,
  getSelectedShift,
  getSelectedTimeslot,
  getIsUnavailableTimeslotModalDisplayed,
  getIsNoShiftTodayModalDisplayed
}
