import { HYDRATE } from 'next-redux-wrapper'
import _sortBy from 'lodash/sortBy'

import { GET_PRODUCT_PUBLICATIONS_ACTION } from 'actions/home/publications'
import { slugify } from 'utils/strings'

export const sections = (state = [], action) => {
  switch (action.type) {
    case HYDRATE:
      return action.payload.home.menu.sections
    case GET_PRODUCT_PUBLICATIONS_ACTION: {
      if (!Array.isArray(action.sections)) {
        return state
      }
      action.sections = _sortBy(action.sections, ['sort'])
      return action.sections.map(section => ({
        ...section,
        anchor: typeof section?.title === 'string' ? slugify(section.title) : ''
      }))
    }
    default:
      return state
  }
}

export const getAllSections = (state, props) => state
export const getSectionById = (state, props) => (
  state.find(section => section.id === props.sectionId)
)

export default {
  getAllSections,
  getSectionById
}
