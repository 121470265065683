import { combineReducers } from 'redux'
import shortUUID from 'short-uuid'

import {
  SET_URL_HASH_ACTION,
  SET_QUERYSTRING_PARAMS_ACTION
} from 'actions/page/url'

import {
  PUSH_SNACKBAR_NOTIFICATION_ACTION,
  DISMISS_SNACKBAR_NOTIFICATION_ACTION,
  CLEAR_SNACKBAR_QUEUE_ACTION
} from 'actions/page/snackbar'

import {
  SET_GLOBAL_ERROR_ACTION,
  CLEAR_GLOBAL_ERROR_ACTION
} from 'actions/page/globalError'

import {
  DETECT_DEVICE_ACTION,
  CHECK_SCREEN_SIZE_ACTION
} from 'actions/page/device'

import {
  LOAD_CAMPAIGN_DATA_ACTION
} from 'actions/page/campaign.actionTypes'

import {
  START_PAGE_LOADING_ACTION,
  END_PAGE_LOADING_ACTION
} from 'actions/page/loading'

import { localStorageReducer } from 'utils/localStorage'

export const currentHash = (state = '', action) => {
  if (action.type === SET_URL_HASH_ACTION) {
    return action.hash
  }
  return state
}

export const hashHistory = (state = [], action) => {
  if (action.type === SET_URL_HASH_ACTION) {
    return [action.hash, ...state]
  }
  return state
}

export const snackbarQueue = (state = [], action) => {
  switch (action.type) {
    case PUSH_SNACKBAR_NOTIFICATION_ACTION:
      return action.notification
        ? [
          {
            id: shortUUID.generate(),
            ...action.notification
          },
          ...state
        ]
        : state
    case DISMISS_SNACKBAR_NOTIFICATION_ACTION:
      return action.notificationId
        ? state.filter(notification => notification.id !== action.notificationId)
        : state
    case CLEAR_SNACKBAR_QUEUE_ACTION:
      return []
    default:
      return state
  }
}

export const globalError = (state = null, action) => {
  switch (action.type) {
    case SET_GLOBAL_ERROR_ACTION:
      return action.error || state
    case CLEAR_GLOBAL_ERROR_ACTION:
      return null
    default:
      return state
  }
}

const device = (state = {}, action) => {
  switch (action.type) {
    case DETECT_DEVICE_ACTION:
      return action.device || {}
    case CHECK_SCREEN_SIZE_ACTION:
      return {
        ...state,
        ...(action.device || {})
      }
    default:
      return state
  }
}

const webapp = (state = null, action) => {
  if (action.type === SET_QUERYSTRING_PARAMS_ACTION) {
    return action?.params &&
      action.params?.webapp &&
      ['android', 'ios', 'so-happy']
        .includes(action.params.webapp.toLowerCase())
      ? action.params.webapp.toLowerCase()
      : null
  }
  return state
}

export const querystringParams = (state = {}, action) => {
  if (action.type === SET_QUERYSTRING_PARAMS_ACTION) {
    return action?.params
      ? { ...state, ...action.params }
      : {}
  }
  return state
}

export const campaign = (state = {}, action) => {
  if (action.type === LOAD_CAMPAIGN_DATA_ACTION) {
    return action.campaign || {}
  }
  return state
}

export const isLoading = (state = null, action) => {
  switch (action.type) {
    case START_PAGE_LOADING_ACTION:
      return true
    case END_PAGE_LOADING_ACTION:
      return false
    default:
      return state
  }
}

export const page = combineReducers({
  currentHash,
  hashHistory,
  snackbarQueue,
  globalError: localStorageReducer(globalError, 'globalError'),
  device,
  querystringParams,
  webapp,
  campaign,
  isLoading
})

export const getCurrentHash = state => state.currentHash
export const getHashHistory = state => state.getHashHistory
export const getSnackbarQueue = state => state.snackbarQueue
export const getGlobalError = state => state.globalError
export const getDevice = state => state.device
export const getQuerystringParams = state => state.querystringParams
export const isInsideWebapp = state => !!state.webapp
export const getWebappDevice = state => state.webapp
export const getCampaign = state => state.campaign
export const getCampaignId = state => state.campaign?.id || null
export const getIsPageLoading = state => state.isLoading

export default {
  getCurrentHash,
  getHashHistory,
  getSnackbarQueue,
  getGlobalError,
  getDevice,
  getQuerystringParams,
  isInsideWebapp,
  getWebappDevice,
  getCampaign,
  getCampaignId,
  getIsPageLoading
}
