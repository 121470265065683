import {
  absoluteApiUrls,
  handleError,
  getRequestHeaders,
  getUrlFromTemplate,
  getUrlWithQueryParams,
  getRequestHeadersFromServer
} from 'utils/api'

export const initializeCart = async ({
  idShiftTimeslot,
  idArea,
  address,
  latitude,
  longitude,
  dropOff,
  dropOffComment,
  addressName
}) => {
  const result = {
    data: null,
    error: null
  }

  const method = 'POST'
  const url = absoluteApiUrls.cart.initializeCart
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    idShiftTimeslot,
    idArea,
    address,
    latitude,
    longitude,
    dropOff,
    dropOffComment,
    addressName
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getCart = async (uuid, withPaymentMethods = false) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'GET'
  let url = getUrlFromTemplate(absoluteApiUrls.cart.getCart, { uuid })
  if (withPaymentMethods) {
    url = getUrlWithQueryParams(url, { withPaymentMethods: true })
  }
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getCartFromServer = async (uuid, cookies, withPaymentMethods = false) => {
  const result = {
    data: null,
    error: null
  }
  if (!uuid) {
    return result
  }

  const method = 'GET'
  let url = getUrlFromTemplate(absoluteApiUrls.cart.getCart, { uuid })
  if (withPaymentMethods) {
    url = getUrlWithQueryParams(url, { withPaymentMethods: true })
  }
  const headers = getRequestHeadersFromServer(cookies)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const updateCartDelivery = async (uuid, delivery) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid || !delivery) {
    return result
  }

  const {
    idShiftTimeslot,
    idArea,
    address,
    addressName,
    latitude,
    longitude,
    firstName,
    lastName,
    phone,
    dropOff,
    dropOffComment
  } = delivery

  if (
    !idShiftTimeslot ||
    !idArea ||
    !address ||
    !latitude ||
    !longitude
  ) {
    return result
  }

  const method = 'PUT'
  const url = getUrlFromTemplate(absoluteApiUrls.cart.updateCartDelivery, { uuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    idShiftTimeslot,
    idArea,
    address,
    addressName,
    latitude,
    longitude,
    firstName,
    lastName,
    phone,
    dropOff,
    dropOffComment
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const updateCartProducts = async (uuid, idProductPublication, quantity) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid || !idProductPublication || typeof quantity !== 'number') {
    return result
  }

  const method = 'PUT'
  const url = getUrlFromTemplate(absoluteApiUrls.cart.updateCartProducts, { uuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    idProductPublication,
    quantity
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const updateCartDiscounts = async (uuid, code) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'PUT'
  const url = getUrlFromTemplate(absoluteApiUrls.cart.updateCartDiscounts, { uuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    code
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const payCart = async (uuid, data) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }
  const method = 'PUT'
  const url = getUrlFromTemplate(absoluteApiUrls.cart.payCart, { uuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    provider: data?.provider,
    // used for stripe
    paymentMethodId: data?.stripePaymentMethodId,
    // used for stripe two-step validation
    paymentIntentId: data?.stripeIntentId,
    // "tokenizationMethod" for GooglePay
    tokenizationMethod: data?.tokenizationMethod,
    // save card or not
    shouldSave: data?.savePaymentMethod && data.savePaymentMethod === '1' ? 1 : 0,
    // only TRs
    withoutCard: data?.withoutCard && data.withoutCard === '1' ? 1 : 0,
    // if there are TRs
    mealVoucherAmount: data?.mealVoucherAmount,
    // paypal
    nonce: data?.nonce,
    deviceData: data?.deviceData,
    // paygreen
    paygreenPaymentMethod: data?.paygreenPaymentMethod,
    companyCode: data?.companyCode
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const confirmPayment = async (data) => {
  const result = {
    data: null,
    error: null
  }

  if (!data || !data.provider) {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.cart.confirmTransaction, { provider: data.provider, hash: data.hash || data.state }) // we can't pass hash inside the redirect_uri for edenred so we use the state field
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    // PAYGREEN
    pid: data.pid,
    orderId: data.orderId,
    result: data.result,
    // EDENRED
    code: data.code,
    scope: data.scope,
    state: data.state,
    session_state: data.session_state
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
