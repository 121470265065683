import * as events from './events'

export const pushToDataLayer = data => {
  if (typeof window === 'undefined' || !Array.isArray(window.dataLayer)) {
    return
  }

  window.dataLayer.push(data)
}

export const pushEvent = (eventCategory, eventId, ...args) => {
  if (!eventId || typeof events[eventId] !== 'function') {
    return
  }
  const data = events[eventId](...args)
  data.event = eventId
  data.event_action = eventId
  data.event_category = eventCategory
  pushToDataLayer(data)
}

export const pushWebVitals = ({ id, name, label, value }) => {
  pushToDataLayer({
    event: 'Metrics',
    eventCategory: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true // avoids affecting bounce rate.
  })
}

export const pushGenericEvent = (eventId, ...args) => {
  pushEvent('Unknown', eventId, ...args)
}
export const pushHomepageEvent = (eventId, ...args) => {
  pushEvent('Homepage', eventId, ...args)
}
export const pushUserModalEvent = (eventId, ...args) => {
  pushEvent('Modale d\'inscription', eventId, ...args)
}
export const pushFunnelEvent = (eventId, ...args) => {
  pushEvent('Funnel', eventId, ...args)
}
export const pushOrderTrackingEvent = (eventId, ...args) => {
  pushEvent('Suivi de commande', eventId, ...args)
}
export const pushProductPageEvent = (eventId, ...args) => {
  pushEvent('Product Page', eventId, ...args)
}
export const pushRatingEvent = (eventId, ...args) => {
  pushEvent('Rating', eventId, ...args)
}
export const pushAccountEvent = (eventId, ...args) => {
  pushEvent('Mon compte', eventId, ...args)
}

export const getPushEventByOrigin = origin => {
  switch (origin) {
    case 'Homepage':
      return pushHomepageEvent
    case 'UserModal':
      return pushUserModalEvent
    case 'Funnel':
      return pushFunnelEvent
    case 'OrderTracking':
      return pushOrderTrackingEvent
    case 'ProductPage':
      return pushProductPageEvent
    case 'Rating':
      return pushRatingEvent
    default:
      return pushGenericEvent
  }
}
