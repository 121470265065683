import { combineReducers } from 'redux'

import fromAddress, { address } from './address'
import fromSlots, { slots } from './slots'
import fromDropOff, { dropOff } from './dropOff'

import { bindSelectorsToState } from 'utils/data'

export const delivery = combineReducers({
  address,
  slots,
  dropOff
})

export default {
  ...bindSelectorsToState(fromAddress, 'address'),
  ...bindSelectorsToState(fromSlots, 'slots'),
  ...bindSelectorsToState(fromDropOff, 'dropOff')
}
