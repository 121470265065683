import { combineReducers } from 'redux'

import fromMenu, { menu } from './menu'
import fromDelivery, { delivery } from './delivery'
import fromCheckout, { checkout } from './checkout'
import fromLayout, { layout } from './layout'
import fromCart, { cart } from './cart'
import fromDemo, { demo } from './demo'

import { bindSelectorsToState } from 'utils/data'

export const home = combineReducers({
  menu,
  delivery,
  checkout,
  layout,
  cart,
  demo
})

export default {
  ...bindSelectorsToState(fromMenu, 'menu'),
  ...bindSelectorsToState(fromDelivery, 'delivery'),
  ...bindSelectorsToState(fromCheckout, 'checkout'),
  ...bindSelectorsToState(fromLayout, 'layout'),
  ...bindSelectorsToState(fromCart, 'cart'),
  ...bindSelectorsToState(fromDemo, 'demo')
}
