import globalErrors from 'config/globalErrors'

export const SET_GLOBAL_ERROR_ACTION = 'set-global-error'
export const CLEAR_GLOBAL_ERROR_ACTION = 'clear-global-error'

export const setGlobalError = error => dispatch => {
  dispatch({
    type: SET_GLOBAL_ERROR_ACTION,
    error
  })
}

export const setGlobalErrorFromCode = (errorCode = null, params = null) => dispatch => {
  let error = errorCode
    ? globalErrors[errorCode] || globalErrors.default
    : null

  if (!error) {
    return
  }

  if (params && error.body) {
    error = {
      ...error,
      body: Object
        .entries(params)
        .reduce((string, [key, value]) => {
          value = Array.isArray(value) && value.length > 0
            ? value.join(',')
            : value
          return string.replace(':' + key, value)
        }, error.body)
    }
  }

  dispatch(setGlobalError(error))
}

export const clearGlobalError = () => dispatch => {
  dispatch({
    type: CLEAR_GLOBAL_ERROR_ACTION
  })
}
