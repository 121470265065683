import {
  absoluteApiUrls,
  handleError,
  getRequestHeaders,
  getUrlFromTemplate
} from 'utils/api'

export const deletePaymentMethod = async (customerId, provider, paymentMethodId = '') => {
  const result = {
    input: {
      customerId,
      provider,
      paymentMethodId
    },
    success: false,
    error: null
  }

  if (!customerId || !provider) {
    return result
  }

  const method = 'DELETE'
  const url = getUrlFromTemplate(absoluteApiUrls.account.deletePaymentMethod, { customerId, provider, paymentMethodId })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    if (response.ok) {
      result.success = true
    } else {
      const responseData = await response.json()
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getPaymentMethods = async (customerId) => {
  const result = {
    input: { customerId },
    data: null,
    error: null
  }

  if (!customerId) {
    return result
  }

  const method = 'GET'
  const url = getUrlFromTemplate(absoluteApiUrls.account.getPaymentMethods, { customerId })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const saveCreditCard = async (customerId, card) => {
  const result = {
    input: { customerId, card },
    success: false,
    error: null
  }
  if (!customerId || !card || !card?.paymentMethodId) {
    return result
  }

  const { paymentMethodId } = card

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.account.saveCreditCard, { customerId })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    paymentMethodToken: paymentMethodId
  })

  try {
    const response = await fetch(url, { method, headers, body })
    if (response.ok) {
      result.success = true
    } else {
      const responseData = await response.json()
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
