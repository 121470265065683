import { combineReducers } from 'redux'

import {
  GET_ACTIVE_EVENTS_ACTION,
  GET_ACTIVE_EVENTS_ACTION_RESULT_ACTION
} from 'actions/event'

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case GET_ACTIVE_EVENTS_ACTION:
      return true
    case GET_ACTIVE_EVENTS_ACTION_RESULT_ACTION:
      return false
    default:
      return state
  }
}

export const active = (state = [], action) => {
  switch (action.type) {
    case GET_ACTIVE_EVENTS_ACTION_RESULT_ACTION:
      return action?.activeEvents || []
    default:
      return state
  }
}

export const error = (state = null, action) => {
  switch (action.type) {
    case GET_ACTIVE_EVENTS_ACTION_RESULT_ACTION:
      return action?.error || null
    default:
      return state
  }
}

export const saleIsBlocked = (state = false, action) => {
  switch (action.type) {
    case GET_ACTIVE_EVENTS_ACTION_RESULT_ACTION:
      return Array.isArray(action.activeEvents) && action.activeEvents.some(event => event.saleIsBlocked)
    default:
      return state
  }
}

export const event = combineReducers({
  isLoading,
  active,
  saleIsBlocked,
  error
})

export default {
  getSaleIsBlocked: state => state.saleIsBlocked,
  getEventsAreLoading: state => state.isLoading,
  getActiveEvents: state => state.active,
  getEventError: state => state.error
}
