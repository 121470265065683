import b2bOffers from 'mockData/data/b2bOffers'
import { selectB2bOffer } from 'utils/b2bOffers'

export const B2B_OFFER_RESULT_ACTION = 'b2b-offer-result'
export const SET_SELECTED_B2B_OFFER_ACTION = 'set-selected-b2b-offer'

export const setSelectedOffer = (offer = null) => {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_B2B_OFFER_ACTION,
      offer: {
        ...offer,
        offers: selectB2bOffer(offer.offers)
      }
    })
  }
}

export const fetchOffers = () => {
  return dispatch => {
    const data = b2bOffers
    dispatch({
      type: B2B_OFFER_RESULT_ACTION,
      data
    })
  }
}
