import { combineReducers } from 'redux'

import { LOAD_LOYALTY_PROGRAM_ACTION } from 'actions/account/loyalty.actionTypes'

export const coupons = (state = [], action) => {
  if (action.type === LOAD_LOYALTY_PROGRAM_ACTION) {
    return action.coupons || []
  }
  return state
}

export const level = (state = 0, action) => {
  if (action.type === LOAD_LOYALTY_PROGRAM_ACTION) {
    return action.level || 0
  }
  return state
}

export const loyalty = combineReducers({
  level,
  coupons
})

export default {
  getLoyaltyCoupons: state => state.coupons,
  getLoyaltyLevel: state => state.level
}
