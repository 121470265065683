import * as api from 'api/home/cart'

import { pushFunnelEvent } from 'utils/googletagmanager'

import {
  getCartUUID,
  getCheckoutAmounts,
  getCheckoutOrderHash,
  getCartProducts,
  getReferralCode,
  getActiveCouponCode,
  getSelectedShiftId,
  getShiftById,
  getPublicationById,
  getIsUnavailableTimeslotModalDisplayed
} from 'reducers'

import { clearCart, reloadCart, updateCartProducts } from 'actions/home/cart'
import { getProductPublications } from 'actions/home/publications'

import { setPaymentRedirectUrl } from 'utils/paymentMethods'
import { isEmpty } from 'lodash'
import Router from 'next/router'
import { SHOW_UNAVAILABLE_TIMESLOT_MODAL } from './delivery'
export const SET_MEAL_VOUCHER_AMOUNT_ACTION = 'set-meal-voucher-amount'
export const SELECT_PAYMENT_METHODS_ACTION = 'select-payment-methods'
export const APPEND_PAYMENT_METHODS_ACTION = 'append-payment-methods'
export const SET_CHEKOUT_LOADING_ACTION = 'set-checkout-loading'
export const PAYMENT_PROCESSING_ACTION = 'payment-processing'
export const PAYMENT_WAITING_ACTION = 'payment-waiting'
export const SET_UNAVAILABLE_PRODUCTS = 'set-unavailable-products'
export const SET_CHECKOUT_ERROR_ACTION = 'set-checkout-error'
export const CLEAR_CHECKOUT_ORDERHASH_ACTION = 'clear-orderHash'
export const ADD_CREDIT_CARD_ACTION = 'add-credit-card'
export const CLEAR_PAYMENT_AUTHORIZATION_URL_ACTION = 'clear-payment-authorization-url'
export const POPIN_ERROR_MESSAGE = 'popin.order.error.product_unavailable_in_payment.message'
export const SHIFT_TIMESLOT_UNAVAILABLE = 'shift_timeslot_unavailable'
export const INVALID_SHIFT_TIMESLOT = 'invalid_shift_timeslot'

const CONFIRMED_STATUS = ['CONFIRMED', 'PICKED', 'READY_DISPATCH']

export const setMealVoucherAmount = (amount = 0) => dispatch => dispatch({
  type: SET_MEAL_VOUCHER_AMOUNT_ACTION,
  amount
})

export const selectPaymentMethods = (paymentMethods) => dispatch => {
  dispatch({
    type: SELECT_PAYMENT_METHODS_ACTION,
    paymentMethods
  })
}

export const clearPaymentMethods = () => dispatch => {
  dispatch({
    type: SELECT_PAYMENT_METHODS_ACTION,
    paymentMethods: []
  })
}

export const appendPaymentMethods = (paymentMethods) => dispatch => {
  dispatch({
    type: APPEND_PAYMENT_METHODS_ACTION,
    paymentMethods
  })
}

export const setCheckoutError = (checkoutError) => dispatch => {
  dispatch({
    type: SET_CHECKOUT_ERROR_ACTION,
    checkoutError
  })
}

export const addCreditCard = (creditCard) => dispatch => dispatch({
  type: ADD_CREDIT_CARD_ACTION,
  creditCard
})

export const clearPaymentAuthorizationUrl = () => dispatch => dispatch({
  type: CLEAR_PAYMENT_AUTHORIZATION_URL_ACTION
})

export const confirmPayment = (data, onSuccess = null) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_CHEKOUT_LOADING_ACTION,
      isLoading: true
    })

    const paymentResponse = await api.confirmPayment(data)
    const { data: paymentData, error: paymentError } = paymentResponse

    if (CONFIRMED_STATUS.includes(paymentData?.status)) {
      dispatch({
        type: PAYMENT_PROCESSING_ACTION,
        ...paymentData
      })

      dispatch(pushPurchaseEvent())
      onSuccess && onSuccess()
      const shiftId = getSelectedShiftId(getState())
      const shift = getShiftById(getState(), { shiftId })
      dispatch(getProductPublications(shift))
      dispatch(clearCart(true))
    } else if (paymentError) {
      throw new Error(paymentError.data?.message || 'Une erreur est survenue lors du paiement.')
    }
  } catch (error) {
    dispatch(clearPaymentAuthorizationUrl())
    dispatch(setCheckoutError(error.message))
  } finally {
    dispatch({
      type: SET_CHEKOUT_LOADING_ACTION,
      isLoading: false
    })
  }
}

const handleProductsUnavailableError = (products) => async (dispatch, getState) => {
  const shiftId = getSelectedShiftId(getState())
  const shift = getShiftById(getState(), { shiftId })

  dispatch(getProductPublications(shift))
  await dispatch(reloadCart({ loadCartPayment: true, withPaymentMethods: true }))

  await Router.push('/panier')

  const cartAfterError = getCartProducts(getState())
  const productsAfterError = cartAfterError[0].items.reduce((previous, current) => {
    // handle the case where product is not avalaible (no stock) and basket, always contain this product
    const publication = getPublicationById(getState(), { publicationId: current.idPublication })
    if (isEmpty(publication)) {
      dispatch(updateCartProducts(current.idPublication, 0))
      previous.push({ id: current.idProduct, quantity: 0, title: current.title })
    } else previous.push({ id: current.idProduct, quantity: current.quantity, title: current.title })

    return previous
  }, [])

  const unavailableProducts = []
  products.forEach(product => {
    const result = productsAfterError.find(elem => elem.id === product.id)
    if (result === undefined) unavailableProducts.push({ id: product.id, title: product.title, quantityRemoved: product.quantity })
    else if (result.quantity === product.quantity) return null
    else unavailableProducts.push({ id: product.id, title: product.title, quantityRemoved: product.quantity - result.quantity })
  })

  dispatch({ type: SET_UNAVAILABLE_PRODUCTS, products: unavailableProducts })
}

export const pay = (data, onSuccess = null) => async (dispatch, getState) => {
  dispatch({
    type: SET_CHEKOUT_LOADING_ACTION,
    isLoading: true
  })

  const uuid = getCartUUID(getState())

  try {
    const paymentResponse = await api.payCart(uuid, data)

    const { data: paymentData, error: paymentError } = paymentResponse

    if (paymentData) {
      const provider = data?.provider
      const redirectUrl = setPaymentRedirectUrl(provider, paymentData?.redirectUrl)
      paymentData.redirectUrl = redirectUrl

      dispatch({
        type: PAYMENT_PROCESSING_ACTION,
        ...paymentData
      })

      if (CONFIRMED_STATUS.includes(paymentData?.status)) {
        dispatch(pushPurchaseEvent())
        onSuccess && onSuccess()
        dispatch(clearCart(true))
        const shiftId = getSelectedShiftId(getState())
        const shift = getShiftById(getState(), { shiftId })
        dispatch(getProductPublications(shift))
        dispatch(setCheckoutError(null))
      } else if (paymentData?.status === 'PENDING_PAYMENT_VALIDATION') {
        dispatch(setPaymentWaitingAuthorization(true))
      }
    } else if (paymentError) {
      let message = 'Une erreur est survenue lors du paiement. Veuillez réessayer en utilisant un nouveau moyen de paiement.'
      if (paymentError.data && paymentError.data.message) {
        // ---- handle edenred error ---- //
        switch (paymentError.data.message) {
          case 'LIMIT_EXCEEDED':
            message = "Votre plafond d'utilisation quotidien est dépassé."
            break
          case 'INSUFFICIENT_FUNDS':
            message = 'Le solde de votre compte Edenred est insuffisant.'
            break
          default:
            message = paymentError.data.message
        }
      }
      // ---- handle shift-timeslot-unaivailable ----- //
      if (paymentError.data.message === 'conflict' && (paymentError.data.type === SHIFT_TIMESLOT_UNAVAILABLE || paymentError.data.code === INVALID_SHIFT_TIMESLOT)) {
        const isModalAlreadyDisplayed = getIsUnavailableTimeslotModalDisplayed(getState())
        if (!isModalAlreadyDisplayed) await dispatch({ type: SHOW_UNAVAILABLE_TIMESLOT_MODAL })
        message = SHIFT_TIMESLOT_UNAVAILABLE
      }
      throw new Error(message)
    }
  } catch (error) {
    // handle the case where a user ordered products currently in the basket of another
    if (error.message === 'conflict' || error.message === POPIN_ERROR_MESSAGE) {
      const cart = getCartProducts(getState())
      const products = cart[0].items.reduce((previous, current) => {
        previous.push({ id: current.idProduct, quantity: current.quantity, title: current.title })
        return previous
      }, [])
      await handleProductsUnavailableError(products)(dispatch, getState)
    }
    dispatch(setCheckoutError(error.message))
  } finally {
    dispatch({
      type: SET_CHEKOUT_LOADING_ACTION,
      isLoading: false
    })
  }
}

export const setPaymentWaitingAuthorization = (data = false) => (dispatch) => {
  dispatch({
    type: PAYMENT_WAITING_ACTION,
    data
  })
}

export const clearCheckoutOrderHash = () => (dispatch, getState) => {
  dispatch({
    type: CLEAR_CHECKOUT_ORDERHASH_ACTION
  })
}

export const clearCheckoutLoading = () => (dispatch, getState) => {
  dispatch({
    type: SET_CHEKOUT_LOADING_ACTION,
    isLoading: false
  })
}

const pushPurchaseEvent = () => (dispatch, getState) => {
  const state = getState()
  const { deliveryFee, totalDue } = getCheckoutAmounts(state)
  const orderHash = getCheckoutOrderHash(state)
  const cartProducts = getCartProducts(state)
  const referralCode = getReferralCode(state)
  const couponCode = getActiveCouponCode(state)

  const products = []

  if (Array.isArray(cartProducts)) {
    for (const { items } of cartProducts) {
      if (Array.isArray(items)) {
        const newItems = items.map(item => ({
          productID: item.idProduct,
          productName: item.title,
          productType: item.type,
          price: item.price,
          quantity: item.quantity
        }))

        products.push(...newItems)
      }
    }
  }

  const purchaseData = {
    id: orderHash,
    affiliation: '',
    revenue: totalDue,
    tax: '',
    shipping: deliveryFee,
    coupon: couponCode || '',
    IsFirstOrder: referralCode ? '0' : '1',
    products
  }

  pushFunnelEvent('Transaction', purchaseData)
}
