import { combineReducers } from 'redux'

import {
  LOADING_PRODUCT_DETAILS_ACTION,
  GET_PRODUCT_DETAILS_ACTION
} from 'actions/home/product'

export const byId = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAILS_ACTION: {
      if (action.data && action.data.id) {
        return {
          ...state,
          [action.data.id]: action.data
        }
      }
      return state
    }
    default:
      return state
  }
}

export const hasError = (state = false, action) => {
  switch (action.type) {
    case LOADING_PRODUCT_DETAILS_ACTION:
      return false
    case GET_PRODUCT_DETAILS_ACTION:
      return !action?.data?.id
    default:
      return state
  }
}

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAILS_ACTION:
      return false
    case LOADING_PRODUCT_DETAILS_ACTION:
      return true
    default:
      return state
  }
}

export const productDetails = combineReducers({
  byId,
  isLoading,
  hasError
})

export const getAll = (state, props) => Object.values(state.byId)
export const getById = (state, props) => state?.byId[props.productId]
export const getIsLoading = (state, props) => state.isLoading
export const getProductHasError = (state, props) => state.hasError

export default {
  getAll,
  getById,
  getProductHasError,
  getIsLoading
}
