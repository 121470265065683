import { getLabelFromAddress } from 'utils/address'

export const SignUp = data => ({
  event_label: 'Inscription',
  data
})

export const LogIn = data => ({
  event_label: 'Connexion',
  data
})

export const SubscribeNewsletter = data => ({
  event_label: 'Souscription newsletter',
  data
})

export const SelectedAddress = data => ({
  event_label: getLabelFromAddress(data),
  data
})

export const SelectedDate = data => ({
  event_label: data.date,
  data
})

export const SelectedTimeslot = data => ({
  event_label: data.label,
  timeslotSetting: data
})

export const Filters = data => ({
  event_label: Array.isArray(data.filters) &&
    data.filters.map(f => f.label).join(','),
  data
})

export const OpenProductPage = data => ({
  event_label: data.productId,
  data
})

export const AddToCart = data => ({
  event_label: data?.product?.title,
  event_value: data?.quantity,
  data
})

export const RemoveFromCart = data => ({
  event_label: data?.product?.title,
  event_value: data?.quantity,
  data
})

export const OpenCart = data => ({
  event_label: 'Panier',
  data
})

export const ConfirmedCart = data => ({
  event_label: 'Panier validé',
  event_value: data?.quantity,
  data
})

export const ExpiredCart = data => ({
  event_label: 'Panier Expiré',
  event_value: data.quantity,
  data
})

export const DeliveryInfo = data => ({
  event_label: 'Info livraison',
  event_value: data?.dropOff?.type,
  data
})

export const AddCouponCode = data => ({
  event_label: 'Code promo',
  event_value: data?.discountValue,
  data
})

export const AddCreditCard = data => ({
  event_label: 'Ajout de carte',
  event_value: data?.brand,
  data
})

export const PaymentMethodSelected = data => ({
  event_label: data.provider,
  event_value: data.paymentMethod,
  data
})

export const PaymentInfo = data => ({
  event_label: 'Info paiement',
  event_value: data?.couponCode,
  data
})

export const Transaction = data => ({
  event_label: 'Transaction',
  data
})

export const CancelOrder = data => ({
  event_label: 'Annulation commande',
  event_value: data.price,
  data
})

export const Rating = data => ({
  event_label: 'Notation commande',
  event_value: data.orderId,
  data
})

export const CustomerService = data => ({
  event_label: 'Service client',
  data
})
