import { OPTION_BUILDING, OPTION_RECEPTION_DESK } from 'utils/delivery'

export const CUSTOM_ADDRESS_TYPE = 'foodcheri'

export const addressTypes = {
  FOODCHERI: 'foodcheri',
  SO_HAPPY: 'so-happy',
  B2B: 'b2b',
  MARKETING: 'marketing',
  RECENT: 'recent',
  GOOGLE: 'google',
  LEGAL: 'legal'
}
export const addressTypesOrder = [
  addressTypes.SO_HAPPY,
  addressTypes.B2B,
  addressTypes.RECENT,
  addressTypes.GOOGLE
]

export const getLabelFromAddress = address => {
  return address?.address
    ? address.name || address.address
    : ''
}

export const isSameAddress = (addressA, addressB) => {
  if (!addressA || !addressB) {
    return false
  }

  if (addressA === addressB) {
    return true
  }

  if (
    Number(addressA?.latitude) === Number(addressB?.latitude) &&
    Number(addressA?.longitude) === Number(addressB?.longitude)
  ) {
    return true
  }

  return false
}

export const getDropOffLabel = (dropOff) => {
  if (!dropOff) {
    return null
  }
  const { type, comment } = dropOff?.type

  switch (type) {
    case OPTION_BUILDING:
      return ['Au pied de l\'immeuble', comment || ''].join(', ')
    case OPTION_RECEPTION_DESK:
      return ['À l\'accueil ou à ma porte', comment || ''].join(', ')
    default:
      return 'Au pied de l\'immeuble'
  }
}
