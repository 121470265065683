import { combineReducers } from 'redux'
import { normalizeArray } from 'utils/data'

import { SET_ORDERS_ACTION, START_ORDERS_LOADING_ACTION, END_ORDERS_LOADING_ACTION } from 'actions/account/orders'

export const orders = (state = null, action) => {
  if (action.type === SET_ORDERS_ACTION) {
    return Array.isArray(action.orders)
      ? normalizeArray(action.orders, 'hash')
      : null
  }
  return state
}

export const currentPage = (state = 0, action) => {
  if (action.type === SET_ORDERS_ACTION) {
    return action.currentPage || 0
  }
  return state
}

export const pageCount = (state = 0, action) => {
  if (action.type === SET_ORDERS_ACTION) {
    return action.pageCount || 0
  }
  return state
}

export const page = (state = {}, action) => {
  if (action.type === SET_ORDERS_ACTION) {
    return typeof action.currentPage === 'number' && Array.isArray(action.orders)
      ? {
        ...state,
        ['page' + action.currentPage]: action.orders.map(o => o.hash)
      }
      : state
  }
  return state
}

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case START_ORDERS_LOADING_ACTION:
      return true
    case END_ORDERS_LOADING_ACTION:
      return false
    default:
      return state
  }
}

export const order = combineReducers({
  orders,
  currentPage,
  pageCount,
  page,
  isLoading
})

export default {
  getOrders: state => {
    if (state.orders === null) {
      return null
    }

    return Object.values(state.orders)
  },
  getCurrentOrderPage: state => state.currentPage,
  getOrderPageCount: state => state.pageCount,
  getOrdersByPage: (state, props) => {
    if (state.orders === null) {
      return null
    }
    if (!state.page['page' + state.currentPage]) {
      return []
    }
    return state.page['page' + state.currentPage].map(hash => state.orders[hash]).filter(Boolean)
  },
  getIsOrdersLoading: state => state.isLoading
}
