import { roundCurrency } from 'utils/numeral'

export const getProductsOfferAmount = (products) => {
  if (!products || !Array.isArray(products) || !products.length) {
    return 0
  }

  const offerAmount = products.reduce((sum, product) => {
    const { items } = product
    if (items) {
      const productDiscount = sumProducItemsOffer(items)
      return sum + productDiscount
    }
    return sum
  }, 0)

  return roundCurrency(offerAmount)
}

const sumProducItemsOffer = (items) => {
  if (!items || !Array.isArray(items) || !items.length) {
    return 0
  }
  const offerAmount = items.reduce((sum, item) => {
    const { discountAmount } = item
    if (!discountAmount) {
      return sum
    }
    return sum + discountAmount
  }, 0)
  return offerAmount
}
