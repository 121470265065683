const doNothingReducer = data => data

export const normalizeArray = (data, prop = 'id', reducer = doNothingReducer) => {
  if (!Array.isArray(data)) {
    return {}
  }

  return data
    .reduce((items, item) => (
      typeof item === 'object' && !Array.isArray(item) && item[prop]
        ? { ...items, [item[prop]]: reducer(item) }
        : items
    ), {})
}

export const extractContent = (data, property) => {
  if (!property || typeof property !== 'string') {
    return null
  }
  if (!data || typeof data !== 'object' || Array.isArray(data)) {
    return null
  }
  if (typeof data[property] !== 'undefined') {
    return data[property]
  }
  if (property.indexOf('.') === -1) {
    return undefined
  }
  property = property.split('.')
  const subproperty = property.shift()
  const nextProperty = property.join('.')

  return extractContent(data[subproperty], nextProperty)
}

export const bindSelectorsToState = (selectors, stateProp) => (
  Object.entries(selectors)
    .reduce(
      (boundSelectors, [methodName, method]) => {
        boundSelectors[methodName] = (state, ...args) => (
          method(state[stateProp], ...args)
        )
        return boundSelectors
      },
      {}
    )
)

export const formDataToObject = (formElement) => {
  if (formElement instanceof Element === false) {
    return
  }

  var fields = formElement.querySelectorAll('input, select, textarea')

  var data = {}

  for (var i = 0, imax = fields.length; i < imax; ++i) {
    var field = fields[i]
    var fieldName = field.name || field.id
    if (
      !fieldName ||
      ['button', 'image', 'submit'].includes(field.type)
    ) {
      continue
    }
    switch (field.type) {
      case 'checkbox':
        data[fieldName] = field.checked
        break
      case 'radio':
        if (data[fieldName] === undefined) {
          data[fieldName] = ''
        }
        if (field.checked) {
          data[fieldName] = field.value
        }
        break
      case 'select-multiple':
        var a = []
        for (var j = 0, jmax = field.options.length; j < jmax; ++j) {
          if (field.options[j].selected) {
            a.push(field.options[j].value)
          }
        }
        data[fieldName] = a
        break
      default:
        data[fieldName] = field.value
    }
  }

  return data
}
