import { combineReducers } from 'redux'
import {
  RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
  DISSMISS_CUTLERY_WARNING_ACTION
} from 'actions/home/cart.actionTypes'

import {
  CUTLERY_PRODUCT_TYPE
} from 'config/cart'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

const defaultCartProductsState = []
export const cartProducts = (state = defaultCartProductsState, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION: {
      return Array.isArray(action?.cart?.products) &&
        action.cart.products.length > 0
        ? action.cart.products
        : defaultCartProductsState
    }
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return defaultCartProductsState
    default:
      return state
  }
}

const defaultItemState = {}
export const items = (state = defaultItemState, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION: {
      if (!Array.isArray(action?.cart?.products) || action.cart.products.length === 0) {
        return defaultItemState
      }
      const newState = {}
      action.cart.products
        .filter(({ items }) => Array.isArray(items) && items.length > 0)
        .forEach(({ items }) => {
          items
            .filter(item => item.type !== CUTLERY_PRODUCT_TYPE)
            .forEach(item => {
              newState[item.idPublication] = {
                publicationId: item.idPublication,
                isLast: item.isLastProduct,
                quantity: newState[item.idPublication]
                  ? newState[item.idPublication].quantity + item.quantity
                  : item.quantity
              }
            })
        })
      return newState
    }
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return defaultItemState
    default:
      return state
  }
}
export const cutleryQuantity = (state = 0, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION: {
      if (!Array.isArray(action?.cart?.products) || action.cart.products.length === 0) {
        return 0
      }
      let newState = 0
      action.cart.products
        .forEach(list => {
          list.items.forEach(item => {
            if (item.type === CUTLERY_PRODUCT_TYPE) {
              newState += item.quantity
            }
          })
        })
      return newState
    }
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return 0
    default:
      return state
  }
}
export const shouldHideCutleryWarning = (state = false, action) => {
  switch (action.type) {
    case DISSMISS_CUTLERY_WARNING_ACTION:
      return true
    default:
      return state
  }
}

export const products = combineReducers({
  cartProducts,
  items,
  cutlery: combineReducers({
    quantity: cutleryQuantity,
    dismissed: shouldHideCutleryWarning
  })
})

const getCartProducts = (state, props) => state.cartProducts
const getCartProductsWithoutCutlery = (state, props) => {
  return state.cartProducts.map(({ items, offer }) => ({
    items: items.filter(({ type }) => type !== CUTLERY_PRODUCT_TYPE),
    offer
  }))
}
const getCartProduct = (state, props) => (
  props?.publicationId && state.items[props.publicationId]
    ? state.items[props.publicationId]
    : null
)
const getCartProductQuantity = (state, props) => (
  props?.publicationId && state.items[props.publicationId]
    ? state.items[props.publicationId].quantity
    : 0
)
const getCartCutlery = (state, props) => state.cutlery

const selectors = {
  getCartProducts,
  getCartProductsWithoutCutlery,
  getCartProduct,
  getCartProductQuantity,
  getCartCutlery
}

export default selectors
