import { combineReducers } from 'redux'

import {
  SELECT_ADDRESS_ACTION,
  GET_ADDRESS_SUGGESTIONS_ACTION,
  GET_ADDRESS_SUGGESTIONS_RESULT_ACTION,
  DISPLAY_ADDRESS_TOOLTIP_ACTION,
  HIDE_ADDRESS_TOOLTIP_ACTION,
  CLEAR_ADDRESS_SUGGESTIONS_ACTION
} from 'actions/home/address'

import {
  LOADING_SHIFTS_ACTION,
  SHIFT_ERROR_ACTION,
  SHIFTS_LOADED_ACTION,
  OUT_OF_BOUND_ADDRESS_ACTION
} from 'actions/home/delivery'

// Status
export const isLoading = (state = false, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUGGESTIONS_ACTION:
    case LOADING_SHIFTS_ACTION:
      return true
    case GET_ADDRESS_SUGGESTIONS_RESULT_ACTION:
    case SELECT_ADDRESS_ACTION:
    case SHIFT_ERROR_ACTION:
    case OUT_OF_BOUND_ADDRESS_ACTION:
    case SHIFTS_LOADED_ACTION:
      return false
    default:
      return state
  }
}
export const isTooltipDisplayed = (state = false, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUGGESTIONS_ACTION:
    case SELECT_ADDRESS_ACTION:
    case HIDE_ADDRESS_TOOLTIP_ACTION:
      return false
    case DISPLAY_ADDRESS_TOOLTIP_ACTION:
      return true
    default:
      return state
  }
}

// Autocomplete
export const suggestions = (state = [], action) => {
  switch (action.type) {
    case GET_ADDRESS_SUGGESTIONS_RESULT_ACTION:
      return action.suggestions || []
    case SELECT_ADDRESS_ACTION:
    case DISPLAY_ADDRESS_TOOLTIP_ACTION:
    case OUT_OF_BOUND_ADDRESS_ACTION:
    case CLEAR_ADDRESS_SUGGESTIONS_ACTION:
      return []
    default:
      return state
  }
}

export const isOutOfBound = (state = false, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUGGESTIONS_ACTION:
      return false
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return true
    default:
      return state
  }
}

export const address = combineReducers({
  isLoading,
  isTooltipDisplayed,
  suggestions,
  isOutOfBound
})

export default {
  getAddressIsLoading: (state, props) => (
    state.isLoading
  ),
  getIsAddressTooltipDisplayed: (state, props) => (
    state.isTooltipDisplayed
  ),
  getAddressSuggestions: (state, props) => (
    state.suggestions
  ),
  getAddressIsOutOfBound: (state, props) => (
    state.isOutOfBound
  )
}
