import {
  absoluteApiUrls,
  handleError,
  getRequestHeaders,
  getUrlFromTemplate
} from 'utils/api'

import { referral as mockReferral } from 'mockData/data/referral'

export const getReferralCode = async () => new Promise((resolve, reject) => {
  setTimeout(
    () => resolve({ code: mockReferral.code }),
    100
  )
})

export const sendReferralEmail = async (customerId, email) => {
  const result = {
    input: { customerId, email },
    success: false,
    error: null
  }

  if (!customerId || !email) {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.account.sendReferralEmail, { customerId })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({ email })
  try {
    const response = await fetch(url, { method, headers, body })
    if (response.ok) {
      result.success = true
    } else {
      const responseData = await response.json()
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
