import device from 'utils/device'

export const DETECT_DEVICE_ACTION = 'detect-device'
export const CHECK_SCREEN_SIZE_ACTION = 'check-screen-size'

export const detectDevice = () => dispatch => {
  dispatch({
    type: DETECT_DEVICE_ACTION,
    device: {
      isIOS: device.isIOS(),
      isAndroid: device.isAndroid(),
      isIE: device.isIE(),
      isMobile: device.isMobile(true),
      isTablet: device.isTablet(true),
      isDesktop: device.isDesktop(true),
      width: device.getScreenWidth(true),
      height: device.getScreenHeight(true)
    }
  })
}

export const checkScreenSize = () => dispatch => {
  dispatch({
    type: CHECK_SCREEN_SIZE_ACTION,
    device: {
      isMobile: device.isMobile(true),
      isTablet: device.isTablet(true),
      isDesktop: device.isDesktop(true),
      width: device.getScreenWidth(true),
      height: device.getScreenHeight(true)
    }
  })
}
