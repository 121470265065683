import * as api from 'api/events'

import {
  getEventsAreLoading
} from 'reducers'

export const GET_ACTIVE_EVENTS_ACTION = 'get-active-events'
export const GET_ACTIVE_EVENTS_ACTION_RESULT_ACTION = 'get-active-events-result'

export const loadActiveEvents = (areaId) => {
  return async (dispatch, getState) => {
    const isLoading = getEventsAreLoading(getState())

    if (isLoading || !areaId) {
      return
    }

    dispatch({ type: GET_ACTIVE_EVENTS_ACTION })

    try {
      const response = await api.getActiveEvents(areaId)

      dispatch({
        type: GET_ACTIVE_EVENTS_ACTION_RESULT_ACTION,
        activeEvents: response.data,
        error: response.error
      })
    } catch (error) {
      dispatch({
        type: GET_ACTIVE_EVENTS_ACTION_RESULT_ACTION,
        activeEvents: [],
        error
      })
    }
  }
}
