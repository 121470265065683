import { combineReducers } from 'redux'

import {
  SET_REFERRAL_CODE_ACTION,
  SEND_REFERRAL_EMAIL_ACTION,
  REFERRAL_EMAIL_SENT_ACTION
} from 'actions/account/referral'

export const referralCode = (state = '', action) => {
  if (action.type === SET_REFERRAL_CODE_ACTION) {
    return action.referralCode || ''
  }
  return state
}

export const invitation = (state = { isLoading: false }, action) => {
  if (action.type === SEND_REFERRAL_EMAIL_ACTION) {
    return {
      isLoading: true
    }
  }
  if (action.type === REFERRAL_EMAIL_SENT_ACTION) {
    return {
      isLoading: false,
      success: action.success || false,
      error: action.error || null
    }
  }

  return state
}

export const referral = combineReducers({
  referralCode,
  invitation
})

export default {
  getReferralCode: state => state.referralCode,
  getReferralInvitation: state => state.invitation
}
