import moment from 'moment'

export const FIELD_DROPOFF = 'dropOff'
export const FIELD_DROPOFF_COMMENT = 'comment'

export const OPTION_BUILDING = 'BUILDING'
export const OPTION_RECEPTION_DESK = 'RECEPTION'

export const DEFAULT_DROPOFF_DETAILS = {
  [FIELD_DROPOFF]: OPTION_BUILDING,
  [FIELD_DROPOFF_COMMENT]: ''
}

export const hasShiftToday = shifts => {
  if (!Array.isArray(shifts) || shifts.length === 0) {
    return false
  }

  const today = moment().format('DD-MM-YYYY')

  return shifts.some(
    shift => shift.isOpen && moment(shift.date).format('DD-MM-YYYY') === today
  )
}

export const parseShifts = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return []
  }

  const shifts = data
    .filter(shift => {
      if (!shift.isOpen || !Array.isArray(shift.timeslots)) {
        return false
      }
      if (!shift.timeslots.some(timeslot => timeslot.isOpen)) {
        return false
      }

      return true
    })

  return shifts
}

export const getShiftFromMenuOrMeal = (shifts, { menu, meal }) => {
  if (Array.isArray(shifts) && shifts.length > 0) {
    const selectedShift = shifts.find(shift => {
      const shiftDate = moment(shift.date).format('YYYY-MM-DD')
      return (
        (menu && meal && shiftDate === menu && shift.meal === meal) ||
        (menu && shiftDate === menu) ||
        (meal && shift.meal.toUpperCase() === meal.toUpperCase())
      )
    })
    return selectedShift
  }
  return null
}

export const getShiftFromTimeslotId = (shifts, timeslotId) => {
  if (Array.isArray(shifts) && shifts?.length > 0 && timeslotId && Number.isFinite(timeslotId)) {
    const shiftFromTimeslotId = shifts?.find(shift => {
      return shift?.isOpen &&
        Array.isArray(shift?.timeslots) &&
        shift?.timeslots.some(timeslot => (
          timeslot?.isOpen && timeslot?.idShiftTimeslot === timeslotId
        ))
    })
    return shiftFromTimeslotId
  }
  return null
}

export const getFirstAvailableShift = (shifts) => {
  if (Array.isArray(shifts) && shifts?.length > 0) {
    const firstAvailableShift = shifts?.find(
      shift => shift?.isOpen &&
        Array.isArray(shift?.timeslots) &&
        shift?.timeslots.some(timeslot => (timeslot?.isOpen))
    )
    return firstAvailableShift
  }
  return null
}
