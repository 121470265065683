export const OPERATOR_TEST = {
  '==': {
    test: (expected) => (actual) => (expected === String(actual)),
    arrayTest: 'some'
  },
  '!=': {
    test: (expected) => (actual) => (typeof actual === 'undefined' || expected !== String(actual)),
    arrayTest: 'every'
  },
  IN: {
    test: (expected) => (actual) => new RegExp(`^${expected}$`, 'gi').test(actual),
    arrayTest: 'some'
  },
  '!IN': {
    test: (expected) => (actual) => !(new RegExp(`${expected}`, 'gi').test(actual)),
    arrayTest: 'every'
  },
  '>=': {
    test: (lower) => (higher) => !isNaN(parseFloat(lower)) &&
      !isNaN(parseFloat(higher)) &&
      parseFloat(lower) <= parseFloat(higher),
    arrayTest: 'some'
  },
  '<=': {
    test: (higher) => (lower) => !isNaN(parseFloat(lower)) &&
      !isNaN(parseFloat(higher)) &&
      parseFloat(lower) <= parseFloat(higher),
    arrayTest: 'some'
  },
  ' >< ': {
    test: (expected) => {
      expected = expected.split('|')
      expected[0] = parseFloat(expected[0])
      expected[0] = isNaN(expected[0]) ? 0 : expected[0]
      expected[1] = parseFloat(expected[1])
      expected[1] = isNaN(expected[1]) ? Infinity : expected[1]
      return (actual) => (
        !isNaN(parseFloat(actual)) &&
          parseFloat(actual) >= expected[0] &&
          parseFloat(actual) <= expected[1]
      )
    }
  }
}

export const INVALID_FILTER_EXPRESSION_ERROR = 'Invalid Filter Expression'
export const INVALID_DATA_ERROR = 'Invalid Data'

/**
* @function parseFilterExpression
* Reads a valid filter string expression
* @param { string } expression The string expression to parse
* @throws { TypeError } if the expression is not supported
* @returns { object } An object representation of the filter expression parts
* @prop { string } targetKey - the name of the property to test
* @prop { func } filterFunction - the function corresponding to the given test operator
* @prop { string } arrayTest - the name of the Array.prototype method to use if the test target is an array
* @example
* // returns { targetKey: 'a', filterFunction: (x) => x === 'b', arrayTest: 'some' }
* parseFilterExpression( 'a == b' )
*/
export const parseFilterExpression = (expression) => {
  if (typeof expression !== 'string') { throw new TypeError(INVALID_FILTER_EXPRESSION_ERROR) }

  expression = expression.trim()

  const operators = Object.keys(OPERATOR_TEST)
    .sort((op1, op2) => op2.length - op1.length)
    .filter(operator => expression.indexOf(operator) > -1)

  if (operators.length === 0) { throw new TypeError(INVALID_FILTER_EXPRESSION_ERROR) }

  const operator = operators[0]

  expression = expression.split(operator).map(chunk => chunk.trim())

  if (expression.length !== 2 || !expression[1]) { throw new TypeError(INVALID_FILTER_EXPRESSION_ERROR) }

  return {
    targetKey: expression[0],
    filterFunction: OPERATOR_TEST[operator].test(expression[1]),
    arrayTest: OPERATOR_TEST[operator].arrayTest || 'some'
  }
}

/**
* @function applyFilter
* Apply some filter on an array of data
* Accepted filter expression are in the format: [target] [operator] [value] where :
* [ target ] can be omitted
* [ operator ] is required only once and must be one of the operators specified in the OPERATOR_TEST object
* [ value ] is required and will be evaluated has a string
* Examples :
* "== x" => search the data array for items equal to the string "x"
* "a === x" => search the data array for objects whose property "a" is equal to the string "x"
* "a.b === x" => search the data array for objects whose property "a" is an object whose property b is defined and equal to the string "x"
* "a[].b === x" => search the data array for objects whose property "a" is an array of objects of which at least one has a property b equal to the string "x"
* @param { array } data - The data to be filtered
* @param { string } expression - The string expression to use for data filtering
* @throws { TypeError } if data is invalid or the expression is not supported
* @returns { array } The filtered array of data
*/
export const applyFilter = (data, expression) => {
  if (!Array.isArray(data)) { throw new TypeError(INVALID_DATA_ERROR) }

  if (data.length === 0) { return data }

  const { targetKey, filterFunction, arrayTest } = parseFilterExpression(expression)

  if (targetKey === '') { return data.filter(filterFunction) }

  if (targetKey.indexOf('.') === -1) {
    return data.filter(item => item[targetKey] && filterFunction(item[targetKey]))
  }

  const targetKeys = targetKey.split('.')

  if (targetKeys.length !== 2) { throw new TypeError(INVALID_FILTER_EXPRESSION_ERROR) }

  const firstKeyIsNotAnArray = targetKeys[0].indexOf('[]') === -1
  targetKeys[0] = targetKeys[0].replace('[]', '')

  const deepFilter = firstKeyIsNotAnArray
    ? (item) => item[targetKeys[0]] && filterFunction(item[targetKeys[0]][targetKeys[1]])
    : (item) => item[targetKeys[0]] &&
      Array.isArray(item[targetKeys[0]]) &&
        item[targetKeys[0]][arrayTest](subItem => {
          return subItem[targetKeys[1]] && filterFunction(subItem[targetKeys[1]])
        })

  return data.filter(deepFilter)
}

/**
* @function applyFilters
* Apply an array of filters successively on an array of data
* @param { array } data - The data to be filtered
* @param { string[] } expressions - The string expressions to use for data filtering
* @throws { TypeError } if data is invalid or the expression is not supported
* @returns { array } The filtered array of data
*/
export const applyFilters = (data, expressions) => {
  if (!Array.isArray(data)) { throw new TypeError(INVALID_DATA_ERROR) }
  if (!Array.isArray(expressions)) { throw new TypeError(INVALID_FILTER_EXPRESSION_ERROR) }
  return expressions.reduce(applyFilter, data)
}
