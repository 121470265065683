import moment from 'moment'
import { HYDRATE } from 'next-redux-wrapper'
import {
  LOAD_HEADER_CONFIG_ACTION,
  HEADER_CONFIG_LOADED_ACTION
} from 'actions/home/layout.actionTypes'

import { combineReducers } from 'redux'

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case HYDRATE:
      return action.payload.home.layout.isLoading
    case LOAD_HEADER_CONFIG_ACTION:
      return true
    case HEADER_CONFIG_LOADED_ACTION:
      return false
    default:
      return state
  }
}

const isHeaderActive = (headerConfig = null) => {
  if (!headerConfig) {
    return false
  }
  let isActive = moment().isAfter(headerConfig.startDate)

  if (headerConfig.endDate) {
    isActive = isActive && moment().isBefore(headerConfig.endDate)
  }

  return isActive
}

export const headers = (state = [], action) => {
  switch (action.type) {
    case HYDRATE:
      return action.payload.home.layout.headers
    case LOAD_HEADER_CONFIG_ACTION:
      return []
    case HEADER_CONFIG_LOADED_ACTION:
      return Array.isArray(action.headers)
        ? action.headers.filter(isHeaderActive)
        : []
    default:
      return state
  }
}

export const layout = combineReducers({
  isLoading,
  headers
})

const isLayoutLoading = state => state.isLoading
const getLayoutHeaders = state => state.headers

const selectors = {
  isLayoutLoading,
  getLayoutHeaders
}

export default selectors
