import { combineReducers } from 'redux'

import {
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  UPDATE_CART_DELIVERY_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION
} from 'actions/home/cart.actionTypes'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

export const isActive = (state = false, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.paygreen?.isActive === true
    case OUT_OF_BOUND_ADDRESS_ACTION: return false
    default:
      return state
  }
}
export const availableFunds = (state = null, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.paygreen?.availableFunds || null
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return null
    default:
      return state
  }
}

export const cbtrd = combineReducers({
  isActive,
  availableFunds
})
