import * as api from 'api/user'
import {
  getAuthTokenUser,
  getCampaignId,
  getMe as getMeReducer
} from 'reducers'
import * as b2bActions from 'actions/b2b'
import { sendSnackbarNotification } from 'actions/page/snackbar'
import actionTypes from './user.actionTypes'
import { initializeCart } from 'actions/home/cart'

import * as cookie from 'utils/cookies'
import { getPushEventByOrigin } from 'utils/googletagmanager'
import { getErrorMessageFromConfig } from 'utils/formValidation/user'
import { communicationPreferencesLabels } from 'utils/communications'
import lostPasswordConfig from 'config/form/user/lostPassword'
import newsletterSubscriptionFormConfig from 'config/form/user/newsletterSubscription'

const SECURITY_COOKIE_KEY = cookie.KEY.security

export const signOut = (shouldNotify = true) => (dispatch) => {
  cookie.remove(SECURITY_COOKIE_KEY)
  dispatch({ type: actionTypes.SIGN_OUT_ACTION })
  dispatch(initializeCart())

  shouldNotify && dispatch(
    sendSnackbarNotification({
      message: 'Vous êtes déconnecté·e',
      type: 'success'
    })
  )
}

export const signIn = (data, origin) => (dispatch) => {
  dispatch({ type: actionTypes.SIGNING_IN_ACTION })
  const pushEvent = getPushEventByOrigin(origin)

  return api.signIn(data)
    .then(result => {
      if (result && result.data && result.data.token) {
        cookie.set(SECURITY_COOKIE_KEY, result.data.token)
        pushEvent && pushEvent('LogIn', { email: data.email })
        dispatch(getMe(result.data.token, true, 'Vous êtes connecté·e'))
      } else {
        dispatch({ type: actionTypes.SIGN_IN_ERROR_ACTION, ...result })
      }
    })
    .catch(error => {
      dispatch({
        type: actionTypes.SIGN_IN_ERROR_ACTION,
        error: { globalSignIn: error.message }
      })
    })
}

export const signUp = (data, origin) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SIGNING_UP_ACTION })

  const pushEvent = getPushEventByOrigin(origin)
  try {
    const response = await api.signUp(data)
    if (response.error) {
      dispatch({
        type: actionTypes.SIGN_UP_ERROR_ACTION,
        error: response.error
      })
    } else if (response && response.data && response.data.token) {
      dispatch({
        type: actionTypes.SIGN_UP_SUCCESS_ACTION,
        ...response
      })
      pushEvent('SignUp', {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        subscribedNewsletter: data.subscribedNewsletter
      })
      await cookie.set(SECURITY_COOKIE_KEY, response.data.token)
      dispatch(getMe(response.data.token, true, 'Votre compte a été créé'))

      // handle communications preferences
      const me = await api.getMe()
      if (me.error) {
        throw new Error('Unable to get current user')
      }
      const { id: customerId, lastName, firstName, email } = me.data
      const result = await api.updateCommunicationPreferences(customerId, {
        [communicationPreferencesLabels.NEWS_MAIL]: data.subscribedNewsletterMail,
        [communicationPreferencesLabels.NEWS_SMS]: data.subscribedNewsletterSms,
        [communicationPreferencesLabels.NEWS_PUSH]: data.subscribedNewsletterPush,
        [communicationPreferencesLabels.TERMS_OF_SALE]: data.legalAgreement
      })
      if (result.data) {
        pushEvent && pushEvent('SubscribeNewsletter', {
          email,
          firstName,
          lastName
        })
      } else {
        throw new Error('Unable to update communication preferences')
      }
    }
  } catch (error) {
    dispatch({
      type: actionTypes.SIGN_UP_ERROR_ACTION,
      error: { global: error.message }
    })
  }
}

export const getMe = (token, isManual = false, snackbarMessage) => (dispatch) => {
  dispatch({ type: actionTypes.GETTING_CURRENT_USER })

  return api.getMe()
    .then(result => {
      if (result.error) {
        dispatch(signOut())
        dispatch({ type: actionTypes.GET_CURRENT_USER_ERROR_ACTION, ...result })
      } else {
        dispatch({ type: actionTypes.GET_CURRENT_USER_SUCCESS_ACTION, ...result })
        dispatch({
          type: actionTypes.SIGN_IN_SUCCESS_ACTION,
          data: { token }
        })
        isManual && snackbarMessage && dispatch(
          sendSnackbarNotification({
            message: snackbarMessage,
            type: 'success'
          })
        )
        dispatch(b2bActions.getB2BOffers())
      }
    })
    .catch(error => {
      dispatch(signOut())
      dispatch({
        type: actionTypes.GET_CURRENT_USER_ERROR_ACTION,
        error: { global: error.message }
      })
    })
}

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.LOADING_USER_ACTION })
  const authTokenUser = getAuthTokenUser(getState())

  return api.getConnectedUser(authTokenUser)
    .then(result => {
      if (result.error) {
        dispatch(signOut(false))
        dispatch({ type: actionTypes.NO_USER_LOADED_ACTION })
      } else {
        if (result.token) {
          cookie.set(SECURITY_COOKIE_KEY, result.token)
          dispatch(
            sendSnackbarNotification({
              message: `Connexion en tant que ${result.data.firstName} ${result.data.lastName} réussie`,
              type: 'default'
            })
          )
        }
        dispatch({
          type: actionTypes.USER_LOADED_ACTION,
          ...result
        })
        dispatch(b2bActions.getB2BOffers())
      }
    })
    .catch(() => {
      dispatch(signOut(false))
      dispatch({ type: actionTypes.NO_USER_LOADED_ACTION })
    })
}

const getLostPasswordMessage = getErrorMessageFromConfig(lostPasswordConfig)

export const lostPassword = (email, withSnackbar = false) => (dispatch) => {
  dispatch({ type: actionTypes.RESETTING_PASSWORD_ACTION })
  return api.lostPassword(email)
    .then(result => {
      if (result.error || !result.success) {
        const errorMessage = getLostPasswordMessage('email', result.error)
        dispatch({
          type: actionTypes.RESET_PASSWORD_ERROR_ACTION,
          message: errorMessage,
          error: result.error
        })
        withSnackbar &&
          dispatch(
            sendSnackbarNotification({
              message: errorMessage,
              type: 'error'
            })
          )
        return
      }
      const successMessage = lostPasswordConfig.success
      dispatch({
        type: actionTypes.RESET_PASSWORD_SUCCESS_ACTION,
        message: successMessage
      })
      withSnackbar &&
        dispatch(
          sendSnackbarNotification({
            message: 'Vous allez recevoir un email',
            type: 'success'
          })
        )
    })
    .catch(error => {
      const errorMessage = getLostPasswordMessage('global', error)
      dispatch({
        type: actionTypes.RESET_PASSWORD_ERROR_ACTION,
        error
      })
      withSnackbar &&
        dispatch(
          sendSnackbarNotification({
            message: errorMessage,
            type: 'error'
          })
        )
    })
}

export const changePassword = (token, data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATTING_PASSWORD_ACTION })
  const { password, confirmPassword, subscribedNewsletterMail = false, subscribedNewsletterSms = false, subscribedNewsletterPush = false } = data
  try {
    const result = await api.changePassword(token, password, confirmPassword)
    if (result.data && result.data.token) {
      dispatch({
        type: actionTypes.UPDATE_PASSWORD_SUCCESS_ACTION,
        ...result
      })
      cookie.set(SECURITY_COOKIE_KEY, result.data.token)
      dispatch(getMe(result.data.token, true))

      // handle communications preferences
      const me = await api.getMe()
      if (me.error) {
        throw new Error('Unable to get current user')
      }
      const { id: customerId } = me.data
      const response = await api.updateCommunicationPreferences(customerId, {
        [communicationPreferencesLabels.NEWS_MAIL]: subscribedNewsletterMail,
        [communicationPreferencesLabels.NEWS_SMS]: subscribedNewsletterSms,
        [communicationPreferencesLabels.NEWS_PUSH]: subscribedNewsletterPush
      })
      if (response.data) {
        dispatch(
          sendSnackbarNotification({
            message: 'Votre mot de passe à bien été modifié',
            type: 'success'
          })
        )
      } else {
        throw new Error('Unable to update communication preferences')
      }
    } else {
      if (result.error.id) throw new Error('Failed to change password')
      dispatch({
        type: actionTypes.UPDATE_PASSWORD_ERROR_ACTION,
        error: result.error
      })
    }
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_PASSWORD_ERROR_ACTION,
      error: { global: error.message }
    })
  }
}

export const updateUser = (data) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATTING_USER_ACTION })

  return api.updateUser(data)
    .then(result => {
      if (result && result.data) {
        dispatch({
          type: actionTypes.UPDATE_USER_SUCCESS_ACTION, ...result
        })
      } else {
        dispatch({ type: actionTypes.UPDATE_USER_ERROR_ACTION, ...result })
      }
    })
    .catch(error => {
      dispatch({
        type: actionTypes.UPDATE_USER_ERROR_ACTION,
        error: { globalUpdateUser: error.message, errors: error?.errors }
      })
    })
}

const getSubscribeToNewsletterErrorMessage = getErrorMessageFromConfig(newsletterSubscriptionFormConfig)
export const subscribeToNewsletter = email => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.SUBSCRIBING_TO_NEWSLETTER_ACTION
  })
  const campaignId = getCampaignId(getState())
  const response = await api.subscribeToNewsletter(email, campaignId)

  let message = 'Merci ! Votre inscription est prise en compte !'
  if (response.success === false) {
    message = getSubscribeToNewsletterErrorMessage('email', response.error) ||
      getSubscribeToNewsletterErrorMessage('global', response.error)
  }

  dispatch({
    type: actionTypes.SUBSCRIBED_TO_NEWSLETTER_ACTION,
    message
  })
}

export const getPendingOrder = (customerId) => async (dispatch, getState) => {
  try {
    const response = await api.getNextDeliveryOrder(customerId)
    if (response?.data?.hash) {
      dispatch({
        type: actionTypes.GET_PENDING_ORDER_ACTION,
        orderHash: response.data.hash,
        etaLive: response.data?.eta
      })
    } else {
      dispatch({
        type: actionTypes.GET_PENDING_ORDER_ACTION,
        orderHash: null,
        etaLive: null
      })
    }
  } catch (e) {
    dispatch({
      type: actionTypes.GET_PENDING_ORDER_ACTION,
      orderHash: null,
      etaLive: null
    })
  }
}

export const clearUserErrors = () => dispatch => dispatch({
  type: actionTypes.CLEAR_USER_ERRORS_ACTION
})

export const clearLostPasswordMessages = () => dispatch => dispatch({
  type: actionTypes.CLEAR_LOST_PASSWORD_MESSAGE_ACTION
})

export const sodexoAuth = (data) => async (dispatch) => {
  await dispatch({ type: actionTypes.SODEXO_AUTH_LOADING_ACTION })
  await dispatch(signOut(false))
  try {
    const result = await api.sodexoAuth(data)
    if (result && result.data && result.data.userToken) {
      const { addresses, idCampaign, idRie, isNew, userToken } = result.data
      cookie.set(SECURITY_COOKIE_KEY, userToken)
      if (idCampaign) {
        cookie.setCampaignCookie({ id: idCampaign })
      }
      dispatch({
        type: actionTypes.SODEXO_AUTH_SUCCESS_ACTION,
        payload: {
          addresses,
          idCampaign,
          idRie,
          isNew
        }
      })
    } else {
      dispatch({
        type: actionTypes.SODEXO_AUTH_ERROR_ACTION,
        error: { ...result }
      })
    }
  } catch (error) {
    await dispatch({
      type: actionTypes.SODEXO_AUTH_ERROR_ACTION,
      error: { globalSignIn: error.message }
    })
  }
}

export const setUserCompany = (customerId, company) => async dispatch => {
  dispatch({ type: actionTypes.UPDATTING_USER_ACTION })

  const response = await api.setUserCompany(customerId, company.id)
  if (response.error) {
    dispatch({
      type: actionTypes.UPDATE_USER_ERROR_ACTION, ...response
    })
  } else {
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS_ACTION, ...response
    })
  }
  dispatch(b2bActions.getB2BOffers())
}
export const updateUserDetails = (details) => async (dispatch, getState) => {
  const { id: customerId } = getMeReducer(getState())
  try {
    const response = await api.updateUserDetails(customerId, details)

    if (response?.data) {
      dispatch(
        sendSnackbarNotification({
          message: 'Vos modifications ont bien été enregistrées',
          type: 'success'
        }))
      dispatch(loadUser())
    } else {
      throw new Error('invalid response')
    }
  } catch (error) {
    dispatch(
      sendSnackbarNotification({
        message: 'Une erreur s\'est produite, n\'hésitez pas à contacter notre service client.',
        type: 'error'
      }))
  }
}

export const updateCommunicationPreferences = (preferences, shouldNotify = true) => async (dispatch, getState) => {
  const { id: customerId } = getMeReducer(getState())
  try {
    const response = await api.updateCommunicationPreferences(customerId, preferences)
    if (response?.data) {
      await dispatch(loadUser())
      if (shouldNotify) {
        await dispatch(
          sendSnackbarNotification({
            message: 'Vos modifications ont bien été enregistrées',
            type: 'success'
          }))
      }
    } else {
      throw new Error('invalid response')
    }
  } catch (error) {
    await dispatch(
      sendSnackbarNotification({
        message: 'Une erreur s\'est produite, n\'hésitez pas à contacter notre service client.',
        type: 'error'
      }))
  }
}
