import * as api from 'api/home/product'

export const LOADING_PRODUCT_DETAILS_ACTION = 'loading-product-details'
export const GET_PRODUCT_DETAILS_ACTION = 'get-product-details'

export const getProductDetails = (productId) => {
  return dispatch => {
    dispatch({ type: LOADING_PRODUCT_DETAILS_ACTION })

    return api.getProductDetails(productId)
      .then(({ data = null, error = null }) => {
        dispatch({ type: GET_PRODUCT_DETAILS_ACTION, data, error })
      })
  }
}
