import {
  absoluteApiUrls,
  handleError,
  getRequestHeaders,
  getUrlWithQueryParams
} from 'utils/api'

export const searchAddress = async (searchTerm, sessionToken) => {
  const result = {
    data: null,
    error: null
  }

  const method = 'GET'
  // always use 0 or 1 instead of boolean becouse booleans are not handled by the api
  const url = getUrlWithQueryParams(absoluteApiUrls.delivery.addressSearch, { searchTerm, 'options[google][shouldGeocode]': 0, 'options[sessionToken]': sessionToken })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = Array.isArray(responseData) ? responseData : Object.values(responseData)
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getAddressLocation = async (address, sessionToken) => {
  const result = {
    data: null,
    error: null
  }

  const method = 'GET'
  const url = getUrlWithQueryParams(absoluteApiUrls.delivery.addressLocation, { placeId: address.place_id, sessionToken })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
