import moment from 'moment'

import * as api from 'api/home/delivery'

import * as cartActions from 'actions/home/cart'

import {
  getAvailableShifts,
  getShiftById,
  getSelectedTimeslotId,
  getCartDelivery,
  getSelectedShift
} from 'reducers'

import { hasShiftToday, parseShifts, getShiftFromMenuOrMeal, getFirstAvailableShift, getShiftFromTimeslotId } from 'utils/delivery'
import { isSameAddress } from 'utils/address'
import * as cookie from 'utils/cookies'
import * as deliveryActions from 'actions/home/delivery'
import { DEFAULT_ADDRESS } from 'config/delivery'
import { getParamsFromURL } from 'utils/url'
import { getProductPublications } from './publications'

export const LOADING_SHIFTS_ACTION = 'loading-shifts'
export const SHIFT_ERROR_ACTION = 'shift-error'
export const SHIFTS_LOADED_ACTION = 'shifts-loaded'
export const OUT_OF_BOUND_ADDRESS_ACTION = 'out-of-bound-address'
export const NO_SHIFT_TODAY_ACTION = 'no-shift-today'

export const SELECT_DAY_ACTION = 'select-day'
export const FORCE_MEAL_SETTING_ACTION = 'force-meal-setting'

export const SELECT_TIMESLOT_ACTION = 'select-timeslot'

export const SHOW_UNAVAILABLE_TIMESLOT_MODAL = 'show-unavailable-timeslot-modal'
export const HIDE_UNAVAILABLE_TIMESLOT_MODAL = 'hide-unavailable-timeslot-modal'
export const SHOW_NO_SHIFT_TODAY_MODAL = 'show-no-shift-today-modal'
export const HIDE_NO_SHIFT_TODAY_MODAL = 'hide-no-shift-today-modal'

export const getSlots = (address, nbDays = null) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_SHIFTS_ACTION })

    const { data, error } = await api.getSlots(address, nbDays)

    if (error) {
      dispatch({
        type: SHIFT_ERROR_ACTION,
        error
      })

      if (error.global === 'area_not_found') {
        dispatch({
          type: OUT_OF_BOUND_ADDRESS_ACTION
        })
        dispatch(cartActions.clearCart(true))
        dispatch(setDeliveryOutOfBound())
      }
    }

    const shifts = parseShifts(data)

    dispatch({ type: SHIFTS_LOADED_ACTION, shifts })

    if (!Array.isArray(shifts) || shifts.length === 0) {
      return
    }

    !hasShiftToday(shifts) && dispatch({ type: NO_SHIFT_TODAY_ACTION })
  }
}

export const selectDay = (
  date,
  forcedMealSetting = null
) => {
  return async (dispatch, getState) => {
    if (!date) {
      return
    }

    dispatch({
      type: SELECT_DAY_ACTION,
      date
    })

    dispatch({
      type: FORCE_MEAL_SETTING_ACTION,
      meal: forcedMealSetting
    })
  }
}

export const selectTimeslot = (shiftId, timeslotId) => {
  return async (dispatch, getState) => {
    if (shiftId) {
      const shift = getShiftById(getState(), { shiftId })
      if (!timeslotId) {
        const availableTimeslots = Array.isArray(shift.timeslots) ? shift.timeslots : []
        const firstAvailableTimeslot = availableTimeslots.find(timeslot => timeslot.isOpen)
        timeslotId = firstAvailableTimeslot?.idShiftTimeslot || null
      }
      if (timeslotId) {
        await dispatch({
          type: SELECT_TIMESLOT_ACTION,
          shiftId,
          timeslotId
        })
      }
    }
  }
}

export const showUnavailableTimeslotModal = () => dispatch => dispatch({ type: SHOW_UNAVAILABLE_TIMESLOT_MODAL })

export const hideUnavailableTimeslotModal = () => dispatch => dispatch({ type: HIDE_UNAVAILABLE_TIMESLOT_MODAL })

export const updateDeliveryFromDay = (date, address) => async (dispatch, getState) => {
  await dispatch(selectDay(date))
  const availableShifts = getAvailableShifts(getState())
  const selectedShift = availableShifts.find(shift => (
    moment(shift?.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
  ))
  if (selectedShift?.idShift) await dispatch(updateDeliveryFromShift(selectedShift.idShift, null, address))
}

export const updateDeliveryFromShift = (shiftId, timeslotId, address) => async (dispatch, getState) => {
  const oldSelectedShift = getSelectedShift(getState())
  const cartDelivery = getCartDelivery(getState())
  await dispatch(selectTimeslot(shiftId, timeslotId))
  const newSelectedShift = getSelectedShift(getState())
  const newSelectedTimeslotId = getSelectedTimeslotId(getState())
  if (!cookie.getCartUUID() || (address && !isSameAddress(cartDelivery, address)) || oldSelectedShift?.idShift !== newSelectedShift?.idShift) {
    await dispatch(cartActions.initializeCart(newSelectedTimeslotId, newSelectedShift?.idArea, address))
  } else if (cartDelivery?.idShiftTimeslot !== newSelectedTimeslotId) {
    await dispatch(
      cartActions.updateCartDelivery({
        address: address?.address,
        addressName: address?.name,
        latitude: address?.latitude,
        longitude: address?.longitude,
        idShiftTimeslot: newSelectedTimeslotId,
        idArea: newSelectedShift?.idArea
      })
    )
  }
}

export const setDeliveryOutOfBound = () => async (dispatch, getState) => {
  const cartDelivery = getCartDelivery(getState())
  const address = DEFAULT_ADDRESS
  await dispatch(deliveryActions.getSlots(address))
  const availableShifts = getAvailableShifts(getState())
  const cartTimeslotId = cartDelivery?.idShiftTimeslot || null
  const { menu, meal } = getParamsFromURL(['menu', 'meal'])
  const selectedShift = menu || meal
    ? getShiftFromMenuOrMeal(availableShifts, { menu, meal })
    : getShiftFromTimeslotId(availableShifts, cartTimeslotId) || getFirstAvailableShift(availableShifts)
  if (selectedShift) {
    await dispatch(getProductPublications(selectedShift))
  }
}
