import NUTRITIONAL_FACTS_CONFIG from 'config/nutritional-facts'

export const getTagIdKey = id => 'tag' + String(id)

export const PREP_TAGS = [
  92, // 'à réchauffer',
  95 // 'se mange chaud ou froid'
]
export const TAG_ALIASES = {
  [getTagIdKey(95)]: 'chaud ou froid'
}
export const VEGAN_TAG_ID = 78
export const VEGGIE_TAG_ID = 63
export const TAGS_ICONS = {
  [getTagIdKey(VEGGIE_TAG_ID)]: 'icon-veggie',
  [getTagIdKey(VEGAN_TAG_ID)]: 'icon-vegan',
  [getTagIdKey(60)]: 'icon-lactose',
  [getTagIdKey(57)]: 'icon-gluten',
  [getTagIdKey(81)]: 'icon-pork',
  [getTagIdKey(84)]: 'icon-alcohol'
}

export const MARKETING_SECTION_TYPE = 'MARKETING'
export const MARKETING_TAG_PARENT = 'MARKETING'
export const COMPANY_DISCOUNTED_PRODUCT_TYPES = [
  'MAIN_COURSE',
  'MAIN_COURSE_SALAD',
  'KIDS',
  'MENU'
]

export const filterSection = section => {
  if (!section) {
    return false
  }

  if (
    section.type === MARKETING_SECTION_TYPE &&
      Array.isArray(section.adCards) &&
      section.adCards.length > 0
  ) {
    return true
  }

  if (
    Array.isArray(section.publications) &&
      section.publications.length > 0
  ) {
    return true
  }

  return false
}

export const normalizeSections = data => {
  if (!Array.isArray(data)) {
    return {}
  }

  const publications = []
  const sections = data
    .filter(filterSection)
    .map(section => ({
      ...section,
      publications: Array.isArray(section.publications)
        ? section.publications
          .map(pp => {
            publications.push({ ...pp, sectionId: section.id })
            return pp.idProductPublication
          })
        : []
    }))

  return { sections, publications }
}

export const getMarketingTag = tags => {
  if (!Array.isArray(tags)) {
    return null
  }
  const marketingTag = tags.find(
    t => t?.parentName && new RegExp(`^${MARKETING_TAG_PARENT}$`, 'i').test(t.parentName)
  )

  return marketingTag || null
}

export const getPrepTag = tags => {
  if (!Array.isArray(tags)) {
    return null
  }

  let prepTag = null
  // Find out if a prep tag is present by comparing its id with the config list of ids
  tags.forEach(tag => {
    if (PREP_TAGS.indexOf(tag.id) >= 0) {
      // Replace the tag name if an alias is specified, use its name property otherwise
      prepTag = {
        ...tag,
        name: TAG_ALIASES[getTagIdKey(tag.id)] || tag.name
      }
    }
  })

  return prepTag
}

export const parseTags = (tags = []) => {
  if (!Array.isArray(tags)) {
    return []
  }

  const hasVeganTag = tags.some(tag => tag.id === VEGAN_TAG_ID)
  const parsedTags = []

  tags.forEach(tag => {
    // Keep only tag with icons and remove veggie tag if vegan tag is present
    if (
      !TAGS_ICONS[getTagIdKey(tag.id)] ||
      (hasVeganTag && tag.id === VEGGIE_TAG_ID)
    ) {
      return
    }
    // Put veggie and vegan tag at the beginning of the array
    tag.id === VEGAN_TAG_ID || tag.id === VEGGIE_TAG_ID
      ? parsedTags.unshift(tag)
      : parsedTags.push(tag)
  })

  return parsedTags
}

export const formatNutritionalFacts = (nutriFacts, netWeight, type = 'macro') => {
  if (type === 'micro') {
    nutriFacts = nutriFacts?.per100
  }

  if (!nutriFacts || !NUTRITIONAL_FACTS_CONFIG[type]) {
    return null
  }

  const NUTRITIONAL_FACTS = NUTRITIONAL_FACTS_CONFIG[type]

  const netWeightNumber = parseFloat(netWeight)
  let hasFacts = false
  const facts = {}
  Object
    .entries(nutriFacts)
    .forEach(([key, perServing]) => {
      const config = NUTRITIONAL_FACTS[key]
      // If the fibers are at 0, don't display the row in the nutritional macro values.
      if (key === 'fibers' && perServing === '0 g') return

      if (!config) {
        return
      }
      perServing = parseFloat(perServing)
      perServing = perServing && !isNaN(perServing) ? perServing : null
      hasFacts = hasFacts || !!perServing
      facts[key] = {
        ...config,
        id: key,
        perServing: perServing ? perServing + config.unit : null
      }
      if (!isNaN(netWeightNumber) && perServing) {
        facts[key].perCentGrams = (Math.round((perServing * 1000) / netWeightNumber)) / 10 + config.unit
      }
    })
  return hasFacts ? facts : null
}

export const parseGlycemicLoad = load => {
  if (!load) {
    return null
  }

  const {
    label,
    values
  } = NUTRITIONAL_FACTS_CONFIG.glycemic.glycemicLoad

  let value = values.find(({ max }) => max && load <= max)
  value = value || values.find(({ max }) => !max)

  if (!value?.label) {
    return null
  }

  return {
    id: 'glycemicLoad',
    label,
    value: value.label.replace(':value', load)
  }
}

export const parseGlycemicIndex = index => {
  if (!index) {
    return null
  }

  const {
    label,
    values
  } = NUTRITIONAL_FACTS_CONFIG.glycemic.glycemicIndex

  let value = values.find(({ max }) => max && index <= max)
  value = value || values.find(({ max }) => !max)

  if (!value?.label) {
    return null
  }

  return {
    id: 'glycemicIndex',
    label,
    value: value.label.replace(':value', index)
  }
}

export const formatGlycemicFacts = (facts) => {
  if (!facts) {
    return null
  }

  let { glycemicLoad, glycemicIndex } = facts

  glycemicLoad = parseFloat(glycemicLoad)
  glycemicLoad = glycemicLoad && !isNaN(glycemicLoad) ? glycemicLoad : null
  glycemicLoad = parseGlycemicLoad(glycemicLoad)

  glycemicIndex = parseFloat(glycemicIndex)
  glycemicIndex = glycemicIndex && !isNaN(glycemicIndex) ? glycemicIndex : null
  glycemicIndex = parseGlycemicIndex(glycemicIndex)

  if (!glycemicLoad && !glycemicIndex) {
    return null
  }

  return {
    glycemicLoad,
    glycemicIndex
  }
}

export const parseProductPrice = (price, originalPrice, companyDiscount, itemType) => {
  if (price === null || isNaN(parseFloat(price))) {
    return null
  }

  price = parseFloat(price)
  originalPrice = parseFloat(originalPrice)
  companyDiscount = parseFloat(companyDiscount)

  originalPrice = !originalPrice || isNaN(originalPrice)
    ? price
    : originalPrice

  if (
    companyDiscount &&
      !isNaN(companyDiscount) &&
      COMPANY_DISCOUNTED_PRODUCT_TYPES.includes(itemType)
  ) {
    price = Math.max(price - companyDiscount, 0)
  }

  const hasDiscount = Boolean(
    originalPrice &&
      !isNaN(originalPrice) &&
      originalPrice > 0 &&
      originalPrice !== price
  )

  return {
    price: String(price.toFixed(2)).split('.'),
    originalPrice: String(originalPrice.toFixed(2)).split('.'),
    hasDiscount,
    isFree: price <= 0
  }
}
