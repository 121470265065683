import { combineReducers } from 'redux'
import { addressTypes } from 'utils/address'

import {
  SIGN_OUT_ACTION,
  SIGNING_IN_ACTION,
  SIGN_IN_SUCCESS_ACTION,
  SIGN_IN_ERROR_ACTION,
  GETTING_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS_ACTION,
  GET_CURRENT_USER_ERROR_ACTION,
  SIGN_UP_ACTION,
  SIGNING_UP_ACTION,
  SIGN_UP_SUCCESS_ACTION,
  SIGN_UP_ERROR_ACTION,
  RESETTING_PASSWORD_ACTION,
  RESET_PASSWORD_SUCCESS_ACTION,
  RESET_PASSWORD_ERROR_ACTION,
  UPDATTING_PASSWORD_ACTION,
  UPDATE_PASSWORD_SUCCESS_ACTION,
  UPDATE_PASSWORD_ERROR_ACTION,
  UPDATTING_USER_ACTION,
  UPDATE_USER_SUCCESS_ACTION,
  UPDATE_USER_ERROR_ACTION,
  SUBSCRIBING_TO_NEWSLETTER_ACTION,
  SUBSCRIBED_TO_NEWSLETTER_ACTION,
  GET_PENDING_ORDER_ACTION,
  LOADING_USER_ACTION,
  USER_LOADED_ACTION,
  NO_USER_LOADED_ACTION,
  CLEAR_USER_ERRORS_ACTION,
  SODEXO_AUTH_LOADING_ACTION,
  SODEXO_AUTH_ERROR_ACTION,
  SODEXO_AUTH_SUCCESS_ACTION,
  CLEAR_LOST_PASSWORD_MESSAGE_ACTION
} from 'actions/user.actionTypes'

import {
  SET_B2B_OFFERS_ACTION
} from 'actions/b2b'

import {
  SET_QUERYSTRING_PARAMS_ACTION
} from 'actions/page/url'
import { formatCommunicationPrefs } from 'utils/communications'

export const authTokenUser = (state = null, action) => {
  switch (action.type) {
    case SET_QUERYSTRING_PARAMS_ACTION:
      return action?.params && action.params?.authToken
        ? action.params?.authToken
        : null
    case USER_LOADED_ACTION:
      return null
    default:
      return state
  }
}

export const isSignedIn = (state = false, action) => {
  switch (action.type) {
    case SIGN_OUT_ACTION:
    case SIGNING_IN_ACTION:
    case SIGN_IN_ERROR_ACTION:
    case SIGNING_UP_ACTION:
    case LOADING_USER_ACTION:
    case NO_USER_LOADED_ACTION:
      return false
    case SIGN_IN_SUCCESS_ACTION:
    case SIGN_UP_SUCCESS_ACTION:
    case USER_LOADED_ACTION:
      return true
    default:
      return state
  }
}

export const errors = (state = null, action) => {
  switch (action.type) {
    case SIGNING_IN_ACTION:
    case SIGNING_UP_ACTION:
    case SIGN_IN_SUCCESS_ACTION:
    case GETTING_CURRENT_USER:
    case GET_CURRENT_USER_SUCCESS_ACTION:
    case SIGN_UP_SUCCESS_ACTION:
    case RESETTING_PASSWORD_ACTION:
    case UPDATE_PASSWORD_SUCCESS_ACTION:
    case UPDATTING_PASSWORD_ACTION:
    case UPDATTING_USER_ACTION:
    case CLEAR_USER_ERRORS_ACTION:
      return null
    case SIGN_IN_ERROR_ACTION:
    case GET_CURRENT_USER_ERROR_ACTION:
    case SIGN_UP_ERROR_ACTION:
    case RESET_PASSWORD_ERROR_ACTION:
    case UPDATE_PASSWORD_ERROR_ACTION:
    case UPDATE_USER_ERROR_ACTION:
      return action.error
    default:
      return state
  }
}

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case SIGNING_IN_ACTION:
    case GETTING_CURRENT_USER:
    case SIGNING_UP_ACTION:
    case RESETTING_PASSWORD_ACTION:
    case UPDATTING_PASSWORD_ACTION:
    case UPDATTING_USER_ACTION:
      return true
    case SIGN_IN_SUCCESS_ACTION:
    case SIGN_IN_ERROR_ACTION:
    case GET_CURRENT_USER_SUCCESS_ACTION:
    case GET_CURRENT_USER_ERROR_ACTION:
    case SIGN_UP_ERROR_ACTION:
    case SIGN_UP_SUCCESS_ACTION:
    case RESET_PASSWORD_SUCCESS_ACTION:
    case RESET_PASSWORD_ERROR_ACTION:
    case UPDATE_PASSWORD_SUCCESS_ACTION:
    case UPDATE_PASSWORD_ERROR_ACTION:
    case UPDATE_USER_SUCCESS_ACTION:
    case UPDATE_USER_ERROR_ACTION:
      return false
    default:
      return state
  }
}

export const me = (state = {}, action) => {
  switch (action.type) {
    case SIGNING_IN_ACTION:
    case SIGN_IN_ERROR_ACTION:
    case SIGN_OUT_ACTION:
    case GETTING_CURRENT_USER:
    case GET_CURRENT_USER_ERROR_ACTION:
    case SIGNING_UP_ACTION:
    case SIGN_UP_ERROR_ACTION:
    case NO_USER_LOADED_ACTION:
      return {}
    case GET_CURRENT_USER_SUCCESS_ACTION:
    case UPDATE_USER_SUCCESS_ACTION:
    case USER_LOADED_ACTION: {
      const data = action.data
      if (data) {
        const { communicationPreferences } = data
        if (communicationPreferences) {
          const formattedCommunicationPreferences = formatCommunicationPrefs(communicationPreferences)
          data.communicationPreferences = formattedCommunicationPreferences
        }
      }
      return data
    }
    default:
      return state
  }
}

export const amILoading = (state = false, action) => {
  switch (action.type) {
    case SIGNING_IN_ACTION:
    case SIGNING_UP_ACTION:
    case GETTING_CURRENT_USER:
    case LOADING_USER_ACTION:
      return true
    case SIGN_IN_SUCCESS_ACTION:
    case SIGN_IN_ERROR_ACTION:
    case GET_CURRENT_USER_SUCCESS_ACTION:
    case GET_CURRENT_USER_ERROR_ACTION:
    case SIGN_UP_ACTION:
    case SIGN_UP_ERROR_ACTION:
    case USER_LOADED_ACTION:
    case NO_USER_LOADED_ACTION:
      return false
    default:
      return state
  }
}

export const lostPasswordMessage = (state = null, action) => {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS_ACTION:
    case RESET_PASSWORD_ERROR_ACTION:
      return action.message || null
    case RESETTING_PASSWORD_ACTION:
      return null
    case CLEAR_LOST_PASSWORD_MESSAGE_ACTION:
      return null
    default:
      return state
  }
}

export const newsletterSubscription = (state = { isLoading: false, message: '' }, action) => {
  if (action.type === SUBSCRIBING_TO_NEWSLETTER_ACTION) {
    return {
      isLoading: true,
      message: ''
    }
  }
  if (action.type === SUBSCRIBED_TO_NEWSLETTER_ACTION) {
    return {
      isLoading: false,
      message: action?.message
    }
  }
  return state
}

export const pendingOrder = (state = { orderHash: null, etaLive: null }, action) => {
  if (action.type === GET_PENDING_ORDER_ACTION) {
    return {
      orderHash: action?.orderHash || null,
      etaLive: action?.etaLive || null
    }
  }
  return state
}

export const company = (state = null, action) => {
  switch (action.type) {
    case SIGNING_IN_ACTION:
    case SIGN_IN_ERROR_ACTION:
    case SIGN_OUT_ACTION:
    case GETTING_CURRENT_USER:
    case GET_CURRENT_USER_ERROR_ACTION:
    case SIGNING_UP_ACTION:
    case SIGN_UP_ERROR_ACTION:
    case NO_USER_LOADED_ACTION:
      return null
    case SET_B2B_OFFERS_ACTION:
      return (action.data && action.data.company) || null
    default:
      return state
  }
}

export const companyOffices = (state = [], action) => {
  switch (action.type) {
    case SIGNING_IN_ACTION:
    case SIGN_IN_ERROR_ACTION:
    case SIGN_OUT_ACTION:
    case GETTING_CURRENT_USER:
    case GET_CURRENT_USER_ERROR_ACTION:
    case SIGNING_UP_ACTION:
    case SIGN_UP_ERROR_ACTION:
    case NO_USER_LOADED_ACTION:
      return null
    case SET_B2B_OFFERS_ACTION:
      return (action.data && action.data.office) || null
    default:
      return state
  }
}

export const soHappyUser = (state = null, action) => {
  switch (action.type) {
    case SODEXO_AUTH_LOADING_ACTION:
      return null
    case SODEXO_AUTH_ERROR_ACTION:
      return { error: action.error }
    case SODEXO_AUTH_SUCCESS_ACTION: {
      const { addresses, idCampaign, idRie, isNew, userToken } = action.payload
      return { addresses, idCampaign, idRie, isNew, userToken }
    }
    default:
      return state
  }
}

export const user = combineReducers({
  authTokenUser,
  isSignedIn,
  errors,
  isLoading,
  me,
  amILoading,
  lostPasswordMessage,
  newsletterSubscription,
  pendingOrder,
  company,
  companyOffices,
  soHappyUser
})

export const getAuthTokenUser = state => state.authTokenUser
export const getIsLoggedIn = state => state.isSignedIn
export const getErrors = state => state.errors
export const getIsLoading = state => state.isLoading
export const getAmILoading = state => state.amILoading
export const getMe = state => state.me
export const getLostPasswordMessage = state => state.lostPasswordMessage
export const getNewsletterSubscription = state => state.newsletterSubscription
export const getPendingOrder = state => state.pendingOrder
export const getCurrentCredit = state => state?.me?.credit?.amount || 0
export const getReferralProgram = state => state?.me?.referralProgram
export const getReferralCode = state => state?.me?.referralProgram?.code || ''
export const getUserLastAddress = state => state?.me?.delivery || null
export const getUserCompany = state => state?.company
export const getUserCompanyOffices = state => state?.companyOffices
export const getSoHappyUser = state => state?.soHappyUser
export const getSoHappyAddresses = state => {
  return state?.soHappyUser?.addresses?.map(({ latitude, longitude, address, name }) => ({ latitude, longitude, address, name, type: addressTypes.SO_HAPPY }))
}
export const isSoHappyNewUser = state => state?.soHappyUser?.isNew

export default {
  getAuthTokenUser,
  getIsLoggedIn,
  getErrors,
  getIsLoading,
  getAmILoading,
  getMe,
  getLostPasswordMessage,
  getNewsletterSubscription,
  getPendingOrder,
  getCurrentCredit,
  getReferralProgram,
  getReferralCode,
  getUserLastAddress,
  getUserCompany,
  getUserCompanyOffices,
  getSoHappyUser,
  getSoHappyAddresses,
  isSoHappyNewUser
}
