import { batch } from 'react-redux'

import {
  sendSnackbarNotification
} from 'actions/page/snackbar'

import * as api from 'api/account/paymentMethods'
import { hasPaymentMethod } from 'utils/paymentMethods'

export const SET_PAYMENT_METHODS_ACTION = 'set-payment-methods'
export const SET_PAYMENT_LOADING_ACTION = 'set-payment-loading'

/**
 * Retrieve payment methods
 * @returns [Object]
 */
export const getPaymentMethods = async (customerId) => {
  try {
    const response = await api.getPaymentMethods(customerId)
    return response
  } catch (error) {
    return { error, data: { stripe: [], paypal: null, edenred: null } }
  }
}

/**
 *  Load data to show on page load
 */
export const loadPaymentMethods = (customerId) => {
  return async dispatch => {
    dispatch({ type: SET_PAYMENT_LOADING_ACTION, loading: true })
    const paymentMethods = await getPaymentMethods(customerId)
    const withPaymentMethod = hasPaymentMethod(paymentMethods.data)
    batch(() => {
      dispatch({
        type: SET_PAYMENT_METHODS_ACTION,
        ...(paymentMethods.data || {}),
        withPaymentMethod,
        error: paymentMethods?.error
      })
      dispatch({ type: SET_PAYMENT_LOADING_ACTION, loading: false })
    })
  }
}

export const saveCreditCard = (customerId, card, refreshOnSuccess) => {
  return async (dispatch, getState) => {
    const { success, error } = await api.saveCreditCard(customerId, card)

    if (success) {
      dispatch(
        sendSnackbarNotification({
          message: 'Nouveau moyen de paiement confirmé',
          type: 'success'
        }))

      dispatch(loadPaymentMethods(customerId))
    } else if (error) {
      dispatch(
        sendSnackbarNotification({
          message: error,
          type: 'error'
        }))
    }
  }
}

export const deletePaymentMethod = (customerId, provider, paymentMethodId) => {
  return async (dispatch, getState) => {
    // delete using the api
    const { success } = await api.deletePaymentMethod(customerId, provider, paymentMethodId)

    if (success) {
      // show deletion message
      dispatch(
        sendSnackbarNotification({
          message: 'Moyen de paiement supprimé',
          type: 'success'
        }))

      dispatch(
        loadPaymentMethods(customerId)
      )
    }
  }
}
