import * as api from 'api/b2b'
import { getRouteById } from 'config/router'

import Router from 'next/router'

export const SET_B2B_OFFERS_ACTION = 'set-b2b-offers'
export const SELECTING_B2B_OFFERS_ACTION = 'select-b2b-offers'

export const getB2BOffers = (mealSetting, deliveryDate, address, cookies = null) => async dispatch => {
  const response = await api.getB2BOffers(mealSetting, deliveryDate, address, cookies)
  if (response?.data?.companies?.length > 1) {
    dispatch({
      type: SELECTING_B2B_OFFERS_ACTION,
      data: response.data || null,
      error: response.error || null
    })
    Router.push(getRouteById('b2b-panel')?.path)
  } else {
    dispatch({
      type: SET_B2B_OFFERS_ACTION,
      data: response.data || null,
      error: response.error || null
    })
  }
}
