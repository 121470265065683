import { combineReducers } from 'redux'

import {
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  UPDATE_CART_DELIVERY_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION
} from 'actions/home/cart.actionTypes'
import { OUT_OF_BOUND_ADDRESS_ACTION } from 'actions/home/delivery'

export const isActive = (state = false, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.paypal?.isActive === true
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return false
    default:
      return state
  }
}
export const vault = (state = '', action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.paypal?.vault || ''
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return ''
    default:
      return state
  }
}
export const accessToken = (state = '', action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.paypal?.accessToken || ''
    case OUT_OF_BOUND_ADDRESS_ACTION:
      return ''
    default:
      return state
  }
}

export const paypal = combineReducers({
  isActive,
  vault,
  accessToken
})
