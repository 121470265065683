import * as api from 'api/account/referral'
export const SET_REFERRAL_CODE_ACTION = 'set-referral-code'

export const SEND_REFERRAL_EMAIL_ACTION = 'send-referral-email'
export const REFERRAL_EMAIL_SENT_ACTION = 'referral-email-sent'

/**
 * Retrieve referral code
 * @returns [Array]
 */
export const getReferralCode = async () => {
  try {
    const { code } = await api.getReferralCode()
    return code
  } catch (error) {
    return ''
  }
}

/**
 *  Load data to show on page load
 */
export const loadReferralCode = () => {
  return async dispatch => {
    const referralCode = await getReferralCode()

    dispatch({ type: SET_REFERRAL_CODE_ACTION, referralCode })
  }
}

export const sendReferralEmail = (userId, email) => {
  return async dispatch => {
    dispatch({
      type: SEND_REFERRAL_EMAIL_ACTION
    })
    const result = await api.sendReferralEmail(userId, email)
    dispatch({
      type: REFERRAL_EMAIL_SENT_ACTION,
      error: result.error,
      success: result.success
    })
  }
}
