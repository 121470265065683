import {
  absoluteApiUrls,
  getRequestHeaders,
  getUrlWithQueryParams,
  handleError
} from 'utils/api'

export const getProductPublications = async (idArea, idShift) => {
  const result = {
    data: null,
    error: null
  }

  if (!idArea || !idShift) {
    result.error = {
      global: 'missing_parameters'
    }
    return result
  }

  const method = 'GET'
  const url = getUrlWithQueryParams(
    absoluteApiUrls.products.getProductPublications,
    { idArea, idShift }
  )
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = Array.isArray(responseData) ? responseData : []
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
