import { formattedAmount } from './numeral'
import { parseUrl } from './url'

export const hasPaymentMethod = (paymentMethods) => {
  if (!paymentMethods || typeof paymentMethods !== 'object' || !Object.keys(paymentMethods).length) {
    return false
  }
  return !!(paymentMethods.stripe?.length || (paymentMethods.paypal && Array.isArray(paymentMethods.paypal) && paymentMethods.paypal.length) || paymentMethods.edenred)
}

export const getPaymentMethodDetails = (method) => {
  if (!method || typeof method !== 'object') {
    return null
  }
  const { paymentMethod, amount, details } = method

  switch (paymentMethod) {
    case 'badge':
      return {
        label: 'Badge entreprise',
        subtitle: details?.subvention
          ? `Vous avez bénéficié d'une participation employeur de ${formattedAmount({ value: details.subvention, hasDecimalZeros: true })}`
          : '',
        logos: ['/images/payment-methods/company-badge.svg'],
        amount
      }
    case 'limonetik':
      return {
        label: 'Pass Restaurant Sodexo',
        subtitle: '',
        logos: ['/images/payment-methods/limonetik.svg'],
        amount
      }
    case 'paypal':
      return {
        label: 'Paypal',
        subtitle: '',
        logos: ['/images/payment-methods/paypal.svg'],
        amount
      }
    case 'edenred':
      return {
        label: 'Edenred',
        subtitle: '',
        logos: ['/images/payment-methods/edenred.svg'],
        amount
      }
    case 'monthlybilling':
      return {
        label: 'Paiement en fin de mois',
        subtitle: '',
        logos: ['/images/payment-methods/monthly-billing.svg'],
        amount
      }
    case 'yavin':
      return {
        label: 'Carte bancaire ou titres-restaurant',
        subtitle: '',
        logos: [],
        amount
      }
    case 'stripe': // mastercard | visa | amex | google_pay
    case 'user': // TRP | customer_credit
    case 'paygreen': // TRD | CBTRD | RESTOFLASH
      return getPaymentDescription(details, amount)
    default:
      return null
  }
}

function getPaymentDescription (details, amount) {
  const type = details?.brand || details?.paymentType
  switch (type) {
    // ---- STRIPE ---- //
    case 'mastercard':
      return {
        label: details?.last4 ? `•••• ${details.last4}` : '',
        subtitle: '',
        logos: ['/images/payment-methods/card-mastercard.svg'],
        amount
      }
    case 'visa':
      return {
        label: details?.last4 ? `•••• ${details.last4}` : '',
        subtitle: '',
        logos: ['/images/payment-methods/card-visa.svg'],
        amount
      }
    case 'amex':
      return {
        label: details?.last4 ? `•••• ${details.last4}` : '',
        subtitle: '',
        logos: ['/images/payment-methods/card-amex.svg'],
        amount
      }
    case 'google_pay':
      return {
        label: 'Google Pay',
        subtitle: '',
        logos: ['/images/payment-methods/googlepay.svg'],
        amount
      }
    // ---- PAYGREEN ---- //
    case 'CBTRD':
    case 'TRD':
      return {
        label: 'Apetiz / UpDéjeuner / Pass Restaurant Sodexo',
        subtitle: '',
        logos: [
          '/images/payment-methods/apetiz.svg',
          '/images/payment-methods/up.svg',
          '/images/payment-methods/pass-resto.svg'
        ],
        amount
      }
    case 'RESTOFLASH':
      return {
        label: 'Resto Flash',
        subtitle: '',
        logos: ['/images/payment-methods/restoflash.svg'],
        amount
      }
    // ---- USER ---- //
    case 'TRP':
      return {
        label: 'Titre restaurant papier',
        subtitle: 'A remettre lors de la livraison',
        logos: ['/images/payment-methods/tr.svg'],
        amount
      }
    case 'customer_credit':
      return {
        label: 'Cagnotte',
        subtitle: '',
        logos: [],
        amount
      }
    default:
      return null
  }
}

export const hasSodexo = (paymentMethods) => {
  if (!paymentMethods || !Array.isArray(paymentMethods) || !paymentMethods.length) {
    return false
  }
  return paymentMethods.some(item => item.paymentMethod === 'limonetik')
}

export const hasEndOfMonthBilling = (paymentMethods) => {
  if (!paymentMethods || !Array.isArray(paymentMethods) || !paymentMethods.length) {
    return false
  }
  return paymentMethods.some(item => item.paymentMethod === 'monthlybilling')
}

// ---- used in stories ---- //
export const PAYMENT_OPTIONS = {
  'Stripe - visa': 'visa',
  'Stripe - mastercard': 'mastercard',
  'Stripe - amex': 'amex',
  'Stripe - google pay': 'google_pay',
  'Paygreen - TRD': 'TRD',
  'Paygreen - retoflash': 'RESTOFLASH',
  'Paiement fin de mois': 'monthlybilling',
  Edenred: 'edenred',
  Paypal: 'paypal',
  'Pass Restaurant Sodexo': 'limonetik',
  Cagnotte: 'customer_credit',
  'Titre restaurant papier': 'meal_voucher',
  'Badge entreprise': 'badge'
}

export function getSelectedPaymentMethod (type, paymentMethods) {
  switch (type) {
    case 'visa':
    case 'mastercard':
    case 'amex':
    case 'google_pay':
      return paymentMethods.find(item => item.paymentMethod === 'stripe' && item.details.brand === type)
    case 'TRD':
    case 'RESTOFLASH':
      return paymentMethods.find(item => item.paymentMethod === 'paygreen' && item.details.paymentType === type)
    default:
      return paymentMethods.find(item => item.paymentMethod === type)
  }
}

// This is a temporary fix especially for edenred link
// When NNFC is fully deployed for all customers and modifications are made in the payment endpoint,
// this function can be deleted
export function setPaymentRedirectUrl (provider, url) {
  if (!provider || !url || typeof url !== 'string') return ''
  if (provider !== 'edenred') return url

  const { baseUrl, params } = parseUrl(url)

  // preprod and the prod act differently,
  // in preprod, there is a hash after the provider's name
  if (params && params.redirect_uri) {
    const previousUri = params.redirect_uri
    params.redirect_uri = previousUri.replace(/edenred\/.*/g, 'edenred')
  }

  const queryString = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return [baseUrl, queryString].join('?')
}
