import { combineReducers } from 'redux'

import { normalizeArray } from 'utils/data'
import { publication } from './publication'

import {
  GET_PRODUCT_PUBLICATIONS_ACTION
} from 'actions/home/publications'
import { HYDRATE } from 'next-redux-wrapper'

export const byId = (state = {}, action) => {
  switch (action.type) {
    case HYDRATE:
      return action.payload.home.menu.publications.byId
    case GET_PRODUCT_PUBLICATIONS_ACTION: {
      if (!Array.isArray(action.publications)) {
        return state
      }
      const reducer = item => publication(
        state[item.idProductPublication],
        { ...action, data: item }
      )
      return normalizeArray(
        action.publications,
        'idProductPublication',
        reducer
      )
    }
    default:
      return state
  }
}

export const byProductId = (state = {}, action) => {
  switch (action.type) {
    case HYDRATE :
      return action.payload.home.menu.publications.byProductId
    case GET_PRODUCT_PUBLICATIONS_ACTION: {
      if (!Array.isArray(action.publications)) {
        return state
      }
      return action.publications.reduce((result, item) => {
        if (item.idProduct) {
          result[item.idProduct] = item.idProductPublication
        }
        return result
      }, {})
    }
    default:
      return state
  }
}

export const publications = combineReducers({
  byId,
  byProductId
})

export const getAllPublications = (state, props) => Object.values(state.byId)
export const getPublicationById = (state, props) => state.byId[props.publicationId] || null
export const getPublicationByProductId = (state, props) => {
  if (!props.productId || !state.byProductId[props.productId]) {
    return null
  }
  return getPublicationById(state, { publicationId: state.byProductId[props.productId] })
}

export default {
  getAllPublications,
  getPublicationById,
  getPublicationByProductId
}
