import { combineReducers } from 'redux'

import {
  ADD_CREDIT_CARD_ACTION,
  PAYMENT_PROCESSING_ACTION,
  SET_CHECKOUT_ERROR_ACTION
} from 'actions/home/checkout'

import {
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  UPDATE_CART_DELIVERY_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
  CLEAR_CART_ACTION
} from 'actions/home/cart.actionTypes'

export const isActive = (state = false, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.stripe?.isActive === true
    default:
      return state
  }
}

export const cards = (state = [], action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.stripe?.cards || state
    case ADD_CREDIT_CARD_ACTION:
      return [
        action.creditCard,
        ...state
      ]
    case CLEAR_CART_ACTION:
      return []
    default:
      return state
  }
}

export const stripeClientSecret = (state = '', action) => {
  if (action.type === PAYMENT_PROCESSING_ACTION) {
    return action.clientSecret || ''
  }
  if (action.type === CLEAR_CART_ACTION) {
    return ''
  }
  return state
}

export const stripePaymentIntentId = (state = '', action) => {
  if (action.type === PAYMENT_PROCESSING_ACTION) {
    return action.intentId || ''
  }
  if (action.type === CLEAR_CART_ACTION) {
    return ''
  }
  if (action.type === SET_CHECKOUT_ERROR_ACTION) {
    return ''
  }
  return state
}

export const stripePublishableKey = (state = '', action) => {
  return state
}

export const creditCard = combineReducers({
  isActive,
  cards,
  stripeClientSecret,
  stripePaymentIntentId,
  stripePublishableKey
})
