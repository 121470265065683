import { combineReducers } from 'redux'

import { SET_PAYMENT_METHODS_ACTION, SET_PAYMENT_LOADING_ACTION } from 'actions/account/paymentMethods'

export const stripe = (state = [], action) => {
  if (action.type === SET_PAYMENT_METHODS_ACTION) {
    return action.stripe || []
  }
  return state
}

export const paypal = (state = null, action) => {
  if (action.type === SET_PAYMENT_METHODS_ACTION) {
    if (!action.paypal || !Array.isArray(action.paypal) || !action.paypal.length) {
      return null
    }
    if (action.paypal.length === 1) {
      return action.paypal[0]
    }
    return action.paypal.find(pp => pp.default) || action.paypal[0]
  }
  return state
}

export const edenred = (state = null, action) => {
  if (action.type === SET_PAYMENT_METHODS_ACTION) {
    return action.edenred || null
  }
  return state
}

export const withPaymentMethod = (state = false, action) => {
  if (action.type === SET_PAYMENT_METHODS_ACTION) {
    return action.withPaymentMethod
  }
  return state
}

export const loading = (state = false, action) => {
  if (action.type === SET_PAYMENT_LOADING_ACTION) {
    return action.loading || false
  }
  return state
}

export const error = (state = null, action) => {
  if (action.type === SET_PAYMENT_METHODS_ACTION && action.error) {
    return action.error
  }
  return state
}

export const paymentMethods = combineReducers({
  list: combineReducers({
    stripe,
    edenred,
    paypal
  }),
  withPaymentMethod,
  error,
  loading
})

export default {
  getProviderMethods: (state, provider) => {
    if (provider && state.list[provider]) {
      return state.list[provider]
    }
    return null
  },
  hasPaymentMethod: state => state.withPaymentMethod,
  getPaymentLoading: state => state.loading,
  getPaymentMethodError: state => state.error
}
