import shortUUID from 'short-uuid'

import {
  absoluteApiUrls,
  handleError,
  getRequestHeaders,
  getUrlFromTemplate,
  getUrlWithQueryParams
} from 'utils/api'

export const getCreditHistory = async (customerId, page = 0) => {
  const result = {
    data: null,
    error: null
  }

  if (!customerId) {
    return result
  }

  const limit = 20
  const offset = page * limit
  const method = 'GET'
  let url = getUrlFromTemplate(absoluteApiUrls.account.getCreditHistory, { customerId })
  url = getUrlWithQueryParams(url, { limit, offset })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = (responseData?.data || []).map((item) => ({
        id: shortUUID.generate(),
        ...item
      }))
      result.currentPage = page
      result.pageCount = !isNaN(parseInt(responseData?.countTotal))
        ? Math.ceil(parseInt(responseData?.countTotal) / limit)
        : 1
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
