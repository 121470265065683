import { combineReducers } from 'redux'

import fromCredit, { credit } from './credit'
import fromOrder, { order } from './order'
import fromLoyalty, { loyalty } from './loyalty'
import fromReferral, { referral } from './referral'
import fromPaymentMethods, { paymentMethods } from './paymentMethods'

import { bindSelectorsToState } from 'utils/data'

export const account = combineReducers({
  credit,
  order,
  loyalty,
  referral,
  paymentMethods
})

export default {
  ...bindSelectorsToState(fromCredit, 'credit'),
  ...bindSelectorsToState(fromOrder, 'order'),
  ...bindSelectorsToState(fromLoyalty, 'loyalty'),
  ...bindSelectorsToState(fromReferral, 'referral'),
  ...bindSelectorsToState(fromPaymentMethods, 'paymentMethods')
}
