import {
  GET_PRODUCT_PUBLICATIONS_ACTION
} from 'actions/home/publications'
import { HYDRATE } from 'next-redux-wrapper'

export const publication = (state = {}, action) => {
  switch (action.type) {
    case HYDRATE: return action.payload.home.menu.publication
    case GET_PRODUCT_PUBLICATIONS_ACTION:
      return action.data || state
    default:
      return state
  }
}
