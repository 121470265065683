import _unionWith from 'lodash/unionWith'
import _differenceWith from 'lodash/differenceWith'

import {
  ADD_PUBLICATION_FILTERS,
  REMOVE_PUBLICATION_FILTERS,
  RESET_PUBLICATION_FILTERS
} from 'actions/home/filter'

export const filters = (state = [], action) => {
  switch (action.type) {
    case ADD_PUBLICATION_FILTERS:
      action.filters = Array.isArray(action.filters) ? action.filters : [action.filters]
      return _unionWith(state, action.filters, (nS, aF) => nS.id === aF.id)
    case REMOVE_PUBLICATION_FILTERS:
      action.filters = Array.isArray(action.filters) ? action.filters : [action.filters]
      return _differenceWith(state, action.filters, (nS, aF) => nS.id === aF.id)
    case RESET_PUBLICATION_FILTERS:
      return []
    default:
      return state
  }
}

export default {
  getAllFilters: state => state,
  getFilterExpressions: state => state.map(filter => filter.expression),
  hasAppliedFilters: state => state.length > 0
}
