import {
  SET_DROPOFF_ACTION
} from 'actions/home/dropOff'

import {
  INITIALIZE_CART_ACTION
} from 'actions/home/cart.actionTypes'

export const dropOff = (state = null, action) => {
  switch (action.type) {
    case SET_DROPOFF_ACTION:
      return action.data
    case INITIALIZE_CART_ACTION:
      return null
    default:
      return state
  }
}

export default {
  getDropOff: (state, props) => (
    state
  )
}
