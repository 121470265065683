import qs from 'querystring'

export const SET_URL_HASH_ACTION = 'set-url-hash'
export const SET_QUERYSTRING_PARAMS_ACTION = 'set-querystring-params'

export const getLocaleFromHash = url => {
  if (typeof url === 'string') {
    const match = url.match(/#(.+)/)
    return match && match[1] ? match[1] : ''
  }
}

export const setUrlHash = (newURL, oldURL) => {
  const hash = getLocaleFromHash(newURL)

  return dispatch => {
    dispatch({
      type: SET_URL_HASH_ACTION,
      hash
    })
  }
}

export const setQueryStringParams = params => dispatch => {
  if (!window.location || !window.location.search) {
    return
  }

  const params = qs.parse(window.location.search.substr(1))

  dispatch({
    type: SET_QUERYSTRING_PARAMS_ACTION,
    params
  })
}
