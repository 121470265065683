export const communicationPreferencesLabels = {
  NEWS_MAIL: 'newsMail',
  NEWS_SMS: 'newsSms',
  NEWS_PUSH: 'newsPush',
  DATA_SHARING: 'dataSharing',
  IMPROVEMENT: 'improvement',
  ORDER_RATING: 'orderRating',
  TERMS_OF_SALE: 'termsOfSale'
}

export const formatCommunicationPrefs = prefs => {
  const prefsObj = {}
  if (!prefs || !Array.isArray(prefs)) return prefsObj
  for (const pref of prefs) {
    Object.keys(pref).forEach(item => {
      prefsObj[communicationPreferencesLabels[item]] = pref[item] === 1 // the backend return a value of 1 or 0
    })
  }
  return prefsObj
}

export const formatCommunicationPreferencesForApi = preferences => {
  const formattedCommunicationPreferences = {}
  if (typeof preferences === 'object') {
    for (const [labelKey, labelValue] of Object.entries(communicationPreferencesLabels)) {
      if (Object.keys(preferences).includes(labelValue)) {
        formattedCommunicationPreferences[labelKey] = preferences[labelValue] ? 1 : 0
      }
    }
  }
  return formattedCommunicationPreferences
}
