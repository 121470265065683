export const ADD_PUBLICATION_FILTERS = 'add-publication-filters'
export const REMOVE_PUBLICATION_FILTERS = 'remove-publication-filters'
export const RESET_PUBLICATION_FILTERS = 'reset-publication-filters'

export const addFilters = (filters = []) => {
  return {
    type: ADD_PUBLICATION_FILTERS,
    filters
  }
}

export const removeFilters = (filters = []) => {
  return {
    type: REMOVE_PUBLICATION_FILTERS,
    filters
  }
}

export const resetFilters = () => {
  return {
    type: RESET_PUBLICATION_FILTERS
  }
}
