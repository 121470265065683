import { combineReducers } from 'redux'

import {
  RELOAD_CART_SUCCESS_ACTION,
  INITIALIZE_CART_SUCCESS_ACTION,
  UPDATE_CART_DELIVERY_SUCCESS_ACTION,
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION
} from 'actions/home/cart.actionTypes'

export const isActive = (state = false, action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.sodexo?.isActive === true
    default:
      return state
  }
}
export const companyName = (state = '', action) => {
  switch (action.type) {
    case RELOAD_CART_SUCCESS_ACTION:
    case INITIALIZE_CART_SUCCESS_ACTION:
    case UPDATE_CART_DELIVERY_SUCCESS_ACTION:
    case UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
      return action?.paymentMethods?.sodexo?.companyName || ''
    default:
      return state
  }
}

export const sodexoBadge = combineReducers({
  isActive,
  companyName
})
