export const PUSH_SNACKBAR_NOTIFICATION_ACTION = 'push-snackbar-notification'
export const DISMISS_SNACKBAR_NOTIFICATION_ACTION = 'dismiss-snackbar-notification'
export const CLEAR_SNACKBAR_QUEUE_ACTION = 'clear-snackbar-queue'

export const sendSnackbarNotification = notification => dispatch => {
  notification &&
    notification.message &&
    dispatch({
      type: PUSH_SNACKBAR_NOTIFICATION_ACTION,
      notification
    })
}

export const dismissiSnackbarNotification = (notificationId) => dispatch => {
  dispatch({
    type: DISMISS_SNACKBAR_NOTIFICATION_ACTION,
    notificationId
  })
}

export const clearSnackbarQueue = () => dispatch => {
  dispatch({
    type: CLEAR_SNACKBAR_QUEUE_ACTION
  })
}
